/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IconStyle, defaultInputSmStyle } from "../../commen/defaultStyles";
import NumberFormat from "../../commen/NumberFormat";
import StockUpdateModel from "../../commen/StockUpdateModel";
import DeleteIcon from "../../Icons/DeleteIcon";
import ServiceModel from "./ServiceModel";


function ServiceDetails({
    addRow,
    formData,
    setFormData,
    updateServiceErrors,
    serviceErrors,
    serviceRows,
    setServiceRows,
    serviceError,
    setServiceErrors,
    editValue,
    ServiceTaxAllList
}: any) {
    // *******************************Product Sction*********************************

    const template = useSelector((state: any) => state.userColorTemplate);
    const { userdata } = template;
    const [newExistProduct, setNewexistProduct] = useState([{ exist: "", originalValue: '' }] as any);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [discount, setDiscount] = useState(null as any);
    const [tax, setTax] = useState(null as any);
    const [discountValue, setDiscountValue] = useState(0 as any);
    const [discountTotal, setDiscountTotal] = useState(0 as any);
    const [gstValue, setGstValue] = useState<string>("GST");




    const [taxValue, setTaxValue] = useState(0 as any);

    const handleCancel = () => {
        setShowConfirmation(false);
    };

    const getCalculateTotal = (subAmount: any) => {

        let percentageOfdis = discountValue;
        let total = subAmount;
        let disAmount = percentageOfdis
            ? ((percentageOfdis / 100) * total)
            : 0;

        setDiscount(disAmount)
        let TotalAmount = total - disAmount;
        let percentageOfTax = taxValue;
        let taxAmount = (TotalAmount * (percentageOfTax / 100));
        let Total = taxAmount + TotalAmount
        setTax(taxAmount);
        return Total;

    }

    const handleInputChange = (index: any, fieldName: any, value: any) => {

        const newArray: any = [...serviceRows];
        newArray[index][fieldName] = value;
        updateServiceErrors(index, fieldName, value);

        if (fieldName === "qty" || fieldName === "rate") {
            newArray[index]["amount"] =
                newArray[index].rate && Number(newArray[index].qty)
                    ? parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)
                    : !newArray[index].rate
                        ? 0
                        : 0;
            newArray[index]["amount"] = (newArray[index]["amount"]).toFixed(2);
            setServiceRows(newArray);
            let subAmount: any = 0;
            for (let i = 0; i < newArray.length; i++) {
                subAmount += Number(newArray[i].amount);
            }

            subAmount = Number(subAmount);
            subAmount = !isNaN(subAmount) ? subAmount.toFixed(2) : 0;
            let Total = taxValue || discountValue ? getCalculateTotal(subAmount) : subAmount;
            setFormData({
                ...formData,
                subtotal: subAmount,
                product: newArray,
                toatalAmount: Number(Total).toFixed(2),
            });
            // let Totals: any = parseFloat(subAmount);
            // let tax: any = ServiceTaxAllList[0]?.servicetaxStatus
            //     ? (parseFloat(ServiceTaxAllList[0]?.servicetaxPercentage) / 100) * subAmount
            //     : 0;
            // let dicount: any = ServiceTaxAllList[0]?.servicediscountStatus
            //     ? (parseFloat(ServiceTaxAllList[0]?.servicetaxPercentage) / 100) * (Totals + parseFloat(tax))
            //     : 0;
            // let taxArray: any = [];
            // for (let i = 0; i < ServiceTaxAllList.length; i++) {
            //     if (ServiceTaxAllList[0]?.servicetaxStatus) {
            //         let taxobject = {
            //             taxName: "ServiceTax",
            //             serviceStatus: ServiceTaxAllList[0]?.servicetaxStatus,
            //             taxPersent: ServiceTaxAllList[i]?.servicetaxPercentage,
            //             taxAmount: tax.toFixed(2),
            //         };
            //         taxArray.push(taxobject);
            //     }
            //     if (ServiceTaxAllList[0]?.servicediscountStatus) {
            //         let disobject = {
            //             taxName: "ServiceDiscount",
            //             discountStatus: ServiceTaxAllList[0]?.servicediscountStatus,
            //             taxPersent: ServiceTaxAllList[i]?.servicetaxPercentage,
            //             taxAmount: dicount.toFixed(2),
            //         };
            //         taxArray.push(disobject);
            //     }
            //     let TotalAmount: any = (Totals + parseFloat(tax)) - parseFloat(dicount)
            //     setFormData({
            //         ...formData,
            //         product: newArray,
            //         subtotal: subAmount,
            //         tax: taxArray,
            //         toatalAmount: TotalAmount.toFixed(2),
            //     });
            // }
        } else {
            setServiceRows(newArray);
        }
    };

    // const handleDiscountAndtaxChange = (e: any, name: any) => {
    //     // if (name == "tax") {
    //     //     let percentage = e.target.value;
    //     //     let total = formData.subtotal 
    //     //     let disAmount = percentage
    //     //         ? ((percentage / 100) * total)
    //     //         : 0;

    //     //     let TotalAmountFortax = total + disAmount;
    //     //     setTaxValue(percentage)
    //     //     let taxAmount = disAmount
    //     //     setTax(taxAmount)
    //     //     let Total = TotalAmountFortax
    //     //     let taxArray: any = [];
    //     //     let disobject = {
    //     //         taxName: "GST",
    //     //         discountStatus: "",
    //     //         taxPersent: percentage,
    //     //         taxAmount: taxAmount.toFixed(2),
    //     //     };
    //     //     taxArray.push(disobject);
    //     //     setFormData({
    //     //         ...formData,
    //     //         tax: taxArray,
    //     //         toatalAmount: Number(Total).toFixed(2),

    //     //     });
    //     // } else {
    //     //     let percentage = e.target.value;
    //     //     setDiscountValue(percentage);
    //     //     let total = formData.subtotal 
    //     //     let disAmount = percentage
    //     //         ? ((percentage / 100) * total)
    //     //         : 0;
    //     //     let TotalAmount = total - disAmount
    //     //     setDiscount(disAmount)
    //     //     let taxArray: any = [];
    //     //     let disobject = {
    //     //         taxName: "ServiceDiscount",
    //     //         discountStatus: "",
    //     //         taxPersent: percentage,
    //     //         taxAmount: disAmount.toFixed(2),
    //     //     };
    //     //     taxArray.push(disobject);
    //     //     setFormData({
    //     //         ...formData,
    //     //         tax: taxArray,
    //     //         toatalAmount: TotalAmount.toFixed(2),
    //     //         discount: percentage
    //     //     });
    //     // }
    //     if (name == "discount") {
    //         let percentage = e.target.value;
    //         let total = formData.subtotal
    //         let disAmount = percentage
    //             ? ((percentage / 100) * total)
    //             : 0;
    //         console.log("disAmount--------------->", disAmount)

    //         setDiscount(disAmount)
    //         let TotalAmount = total - disAmount
    //         let taxArray: any = [];
    //         let disobject = {
    //             taxName: "ServiceDiscount",
    //             discountStatus: "",
    //             taxPersent: percentage,
    //             taxAmount: disAmount.toFixed(2),
    //         };
    //         taxArray.push(disobject);
    //         setFormData({
    //             ...formData,
    //             tax: taxArray,
    //             toatalAmount: TotalAmount.toFixed(2),
    //             discount: percentage
    //         });
    //         setDiscountTotal(TotalAmount ? TotalAmount : formData.subtotal)

    //     } else if (name == "tax") {
    //         let percentage = e.target.value;
    //         setTaxValue(percentage)
    //         let taxAmount = percentage
    //             ? ((percentage / 100) * Number(discountTotal))
    //             : 0;
    //         console.log("taxAmount--------------->", taxAmount)

    //         let TotalAmountFortax = Number(discountTotal) + taxAmount;
    //         setTax(taxAmount)
    //         console.log("TotalAmountFortax--------------------->", TotalAmountFortax)
    //         let taxArray: any = [];
    //         let disobject = {
    //             taxName: "GST",
    //             discountStatus: "",
    //             taxPersent: percentage,
    //             taxAmount: taxAmount.toFixed(2),
    //         };
    //         taxArray.push(disobject);
    //         setFormData({
    //             ...formData,
    //             tax: taxArray,
    //             toatalAmount: Number(TotalAmountFortax).toFixed(2),
    //         })
    //     }

    // }

    const handleGstTypeChange = (e: any) => {
        const value = e.target.value;
        setGstValue(value)
    };
    const handleDiscountAndtaxChange = (e: any, name: string) => {
        const value = parseFloat(e.target.value) || 0;
        let total = formData.subtotal;

        const calculateDiscount = (discountPercentage: number) => {
            return discountPercentage ? (discountPercentage / 100) * total : 0;
        };

        const calculateTax = (taxPercentage: number, amountAfterDiscount: number) => {
            return taxPercentage ? (taxPercentage / 100) * amountAfterDiscount : 0;
        };

        let discountPercentage = formData.discount;
        let taxPercentage = formData.tax.length > 0 ? formData.tax[0].taxPersent : 0;
        let cgst = 0;
        let sgst = 0;


        if (name === "discount") {
            discountPercentage = value;
            setDiscountValue(discountPercentage)
        } else if (name === "tax") {
            taxPercentage = value;
            cgst = taxPercentage / 2;
            sgst = taxPercentage / 2;
        }


        const discountAmount = calculateDiscount(discountPercentage);
        const amountAfterDiscount = total - discountAmount;
        const taxAmount = calculateTax(taxPercentage, amountAfterDiscount);
        let cgstValue = 0;
        let sgstValue = 0;
        if (name === "tax") {
            cgstValue = taxAmount / 2;
            sgstValue = taxAmount / 2;
        }
        let cgstTaxValue = cgstValue
        let sgstTaxValue = sgstValue

        const finalAmount = amountAfterDiscount + taxAmount;
        const GSTTaxArray = [

            {
                taxName: "Discount",
                discountStatus: "",
                taxPersent: discountPercentage,
                taxAmount: discountAmount.toFixed(2),
            },
            {
                taxName: gstValue,
                discountStatus: "",
                taxPersent: taxPercentage,
                taxAmount: taxAmount.toFixed(2),
            },
            {
                taxName: "CGST",
                discountStatus: "",
                taxPersent: cgst,
                taxAmount: Number(cgstTaxValue).toFixed(2),
            },
            {
                taxName: "SGST",
                discountStatus: "",
                taxPersent: sgst,
                taxAmount: Number(sgstTaxValue).toFixed(2),
            },
        
        ];

        // const IGSTTaxArray = [
        //     {
        //         taxName: "IGST",
        //         discountStatus: "",
        //         taxPersent: taxPercentage,
        //         taxAmount: taxAmount.toFixed(2),
        //     },
        //     {
        //         taxName: "Discount",
        //         discountStatus: "",
        //         taxPersent: discountPercentage,
        //         taxAmount: discountAmount.toFixed(2),
        //     },

        // ];
        setFormData({
            ...formData,
            tax: GSTTaxArray,
            toatalAmount: finalAmount.toFixed(2),
            discount: discountPercentage,
        });

        setDiscount(discountAmount);
        setTax(taxAmount);
        setDiscountTotal(amountAfterDiscount);
        setTaxValue(taxPercentage);
    };
   


    const handleExistingCalulation = (arr: any) => {
        if (arr.length === 0) arr.push({ amount: "", service_name: "", qty: "", rate: "", units: "" });
        const newArray: any = [...arr];
        for (let i = 0; i < newArray.length; i++) {
            if (newArray[i].qty || newArray[i].rate) {
                newArray[i]["amount"] =
                    newArray[i].rate && Number(newArray[i].qty)
                        ? parseFloat(newArray[i].rate) * parseFloat(newArray[i].qty)
                        : !newArray[i].rate
                            ? 0
                            : 0;

                setServiceRows(newArray);

                let subAmount: any = 0;
                for (let i = 0; i < newArray.length; i++) {
                    subAmount += newArray[i].amount;
                }
                subAmount = Number(subAmount);
                subAmount = !isNaN(subAmount) ? subAmount.toFixed(2) : 0;
                let Total = getCalculateTotal(subAmount);
                setFormData({
                    ...formData,
                    product: newArray,
                    subtotal: subAmount,
                    toatalAmount: Total.toFixed(2),
                });


            }
        }
        // else {
        setServiceRows(newArray);
        // }
        let finalErrors: any = [];
        // let object: any = {};
        let values = newArray?.map((item: any, index: any) => {
            let object: any = {};
            object.service_name = item.service_name ? true : false;
            object.rate = item.rate ? true : false;
            object.qty = item.qty ? true : false;
            object.units = item.units ? true : false;
            finalErrors.push(object);


        });
        setServiceErrors(finalErrors);
    };


    const removeRow = (index: any, row: any) => {

        const newArray = [...serviceRows];
        const newErrors = [...serviceErrors];
        const existArray = [...newExistProduct];
        existArray.splice(index, 1)
        setNewexistProduct(() => {
            return existArray.length === 0 ? [{ exist: '', originalValue: '' }] : existArray
        });

        newArray.splice(index, 1);
        handleExistingCalulation(newArray);
        setServiceRows(newArray);
        if (newErrors.length > 1)
            newErrors.splice(index, 1);
        setServiceErrors(newErrors);

        if (existArray.length === 0) {
            setFormData((prevFormData: any) => ({
                ...prevFormData,
                tax: [],
                subtotal: 0,
                toatalAmount: 0,
                discount: ""

            }));
            setTax(0)
            setDiscount(0)
        }

    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleConfirm = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (editValue) {
            setServiceRows(formData.product);
            let obj: any = []
            formData.product.map((item: any) => {
                obj.push({ exist: item.qty, originalValue: item.qty })
            })
            setNewexistProduct(obj)
        } else {
            setServiceRows([
                { service_name: "", rate: "", qty: "", amount: 0 },
            ]);
        }
    }, [editValue]);

    const handleSelectService = (dataArray: any[]) => {
        const containsEmptyRows = serviceRows.some((row: any) =>
            Object.values(row).some((value) => !value)
        );
        const containsEmptyRowsinExistProduct = newExistProduct.some((row: any) =>
            Object.values(row).some((value) => !value)
        );

        if (containsEmptyRowsinExistProduct) {
            const nonEmptyRows = newExistProduct.filter((row: any) =>
                Object.values(row).some(Boolean)
            );
            setNewexistProduct(nonEmptyRows);
        }
        if (containsEmptyRows) {
            const nonEmptyRows = serviceRows.filter((row: any) =>
                Object.values(row).some(Boolean)
            );
            setServiceRows(nonEmptyRows);
        }

        const newRows = dataArray.map((data) => ({
            service_name: data.service_name,
            rate: data.rate,
            taxPercentage: data.taxPercentage,
            qty: 1,
            amount: 0,
        }));


        setServiceRows((prevRows: any[]) => {
            const updatedRows = [...prevRows, ...newRows];
            handleExistingCalulation(updatedRows);
            return updatedRows;
        });

        setNewexistProduct((prev: any) => [
            ...prev,
            ...dataArray.map((row: any) => ({
                exist: row.service_name,
                originalValue: row.service_name,
            }))
        ]);

        setIsModalOpen(false);
    };

    const addRowValue = () => {
        addRow();
        setNewexistProduct((prev: any) => [...prev, { exist: "", originalValue: "" }])
    };

    return (
        <div className="mb-4">
            <div
                className={
                    "hidden sm:flex rounded-lg invisible sm:visible w-full flex-col sm:flex-row px-4 py-2 mb-2 text-white"
                }
                style={{ backgroundColor: "#008065" }}
            >
                <div
                    style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                    }}
                    className={" w-full md:w-[17%] lg:w-[17%] xl:w-[10%] 2xl:w-[5%] text-left"}

                >
                    <span className="inline-block whitespace-nowrap">S No</span>
                </div>
                <div
                    style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                    }}
                    className={" w-full sm:w-[192%] md:w-[90%] lg:w-[80%] xl:w-[81%] 2xl:w-[81%] text-center"}
                >
                    <span className="inline-block">Service</span>
                </div>
                {userdata?.hsnNo ? (
                    <>
                        <div
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 700,
                            }}
                            className={" w-full md:w-[55%] lg:w-[15%] 2xl:w-[15%] text-left sm:pr-10 text-right"}
                        >
                            <span className="inline-block">HSN/SAC</span>
                        </div>
                    </>
                ) : (<></>)}
                <div
                    style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                    }}
                    className={" w-full md:w-[14%] xl:w-[14%] 2xl:w-[14%] text-left sm:pr-5 text-right"}
                >
                    Rate
                </div>
                <div
                    style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                    }}
                    className={" w-full md:w-[16%] xl:w-[16%] 2xl:w-[16%] text-left sm:pr-5 text-right"}
                >
                    Qty
                </div>
                <div
                    style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                    }}
                    className={"  w-full md:w-[17%] xl:w-[17%] 2xl:w-[17%] text-right sm:pr-10"}
                >
                    Total
                </div>
            </div>

            {serviceRows?.map((servicerow: any, index: any) => (
                <div
                    key={index}
                    className={
                        "flex flex-col sm:flex-row rounded-lg  w-full    items-center relative " +
                        (index % 2 !== 0 ? "  " : "")
                    }
                >
                    <div
                        style={{
                            fontFamily: "poppins",
                            textAlign: "left",
                            fontSize: "14px",
                            fontWeight: 700,
                        }}
                        className={"w-full md:w-[25%] lg:w-[25%]  xl:w-[25%] 2xl:w-[7%]  text-center  pr-3  flex flex-row sm:block "}
                    >
                        <span className="sm:hidden w-1/2 flex flex-row items-center whitespace-nowrap">
                            S No
                        </span>

                        <div className="font-title flex-1 text-right sm:text-center 2xl:text-left 2xl:ml-8 pr-2 sm:pr-0">
                            <div
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                }}

                            >
                                {index + 1}
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 700,
                        }}
                        className={" w-full md:w-[135%] xl:w-[141%] 2xl:w-[155%] text-right pr-3 flex flex-row sm:block mb-1"}
                    >
                        <span className="sm:hidden w-1/2 flex flex-row items-center">
                            Service
                        </span>

                        <div className="font-title flex-1 text-right sm-text-left ">
                            <input
                                autoComplete="nope"
                                value={servicerow.service_name ? servicerow.service_name : ""}
                                placeholder="Service Name"
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    borderColor:
                                        !serviceErrors[index]?.service_name && serviceError
                                            ? "red"
                                            : "#00A787",
                                }}
                                className={
                                    defaultInputSmStyle +
                                    " text-right sm:text-left border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full"
                                }
                                onChange={(event) => {
                                    const value = event.target.value.trimStart();
                                    handleInputChange(index, "service_name", value);
                                }}

                            />

                        </div>
                    </div>
                    {userdata?.hsnNo ? (
                        <>
                            <div
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 700,
                                }}
                                className={" w-full md:w-[50%] xl:w-[33%] 2xl:w-[33%]  text-right  pr-3  flex flex-row sm:block mb-3"}
                            >
                                <span className="sm:hidden w-1/2 flex flex-row items-center">
                                    HSN/SAC
                                </span>

                                <div className="font-title flex-1 text-left">
                                    <input
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            borderColor: "#00A787",
                                        }}
                                        autoComplete="nope"
                                        value={servicerow.hsn}
                                        onChange={(event) => {
                                            const value = event.target.value.replace(/[^0-9]/g, '');
                                            handleInputChange(index, "hsn", value)
                                        }}
                                        placeholder="HSN/SAC"
                                        type=""
                                        className={
                                            defaultInputSmStyle +
                                            " text-right  border-2 focus:outline-none rounded-[7px] px-2 h-9  w-full "
                                        }
                                    />
                                </div>
                            </div>
                        </>
                    ) : (<></>)}
                    <div
                        style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 700,
                        }}
                        className={" w-full md:w-[40%] lg:w-[35%] 2xl:w-[35%]  text-right  pr-3  flex flex-row sm:block mb-1"}
                    >
                        <span className="sm:hidden w-1/2 flex flex-row items-center">
                            Rate
                        </span>

                        <div className="font-title flex-1 text-left">
                            <input
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    borderColor:
                                        !serviceErrors[index]?.rate && serviceError
                                            ? "red"
                                            : "#00A787",
                                }}
                                autoComplete="nope"
                                value={servicerow.rate}
                                onChange={(event) => {
                                    const value = event.target.value.replace(/[^0-9.]/g, '');
                                    handleInputChange(index, "rate", value);
                                }}
                                placeholder="Rate"
                                type=""
                                className={
                                    defaultInputSmStyle +
                                    " text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full   "
                                }
                            />

                        </div>
                    </div>
                    <div
                        style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 700,
                        }}
                        className={"w-full md:w-[25%] xl:w-[25%] 2xl:w-[25%] text-right  pr-3 lg:pr-0 flex flex-row sm:block mb-1"}
                    >
                        <span className="sm:hidden w-1/2 flex flex-row items-center">
                            Quantity
                        </span>

                        <div className="font-title flex-1 text-left">
                            <input
                                autoComplete="nope"
                                type=""
                                value={servicerow.qty}
                                onChange={(event) => {
                                    const value = event.target.value.replace(/[^0-9]/g, '');
                                    handleInputChange(index, "qty", value);
                                }}
                                placeholder="Qty"
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    borderColor:
                                        !serviceErrors[index]?.qty && serviceError
                                            ? "red"
                                            : "#00A787",
                                }}
                                className={
                                    defaultInputSmStyle +
                                    " text-right remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full  "
                                }
                            />

                        </div>
                    </div>

                    {/* <div
                        style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 700,
                            marginBottom: "1.25rem",
                        }}
                        className={"w-full md:w-[40%] lg:w-[40%] 2xl:w-[40%]  text-right  sm:pl-3 sm:pr-0 pr-3 flex flex-row sm:block mb-3"}
                    >
                        <span className="sm:hidden w-1/2 flex flex-row items-center">
                            TAX
                        </span>

                        <div className="font-title flex-1 text-left">
                            <select
                                name="taxPercentage"
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    borderColor: "#00A787",
                                }}
                                className={
                                    defaultInputSmStyle +
                                    " text-left remove-spinner border-2 focus:outline-none rounded-[7px]  h-9 w-full  "
                                }>
                                <option disabled selected hidden value=""></option>
                                <option value="GST@5%">GST@5%</option>
                                <option value="GST@5%">GST@12%</option>
                                <option value="GST@5%">GST@18%</option>
                                <option value="GST@5%">GST@28%</option>
                                <option value="GST@5%">IGST@5%</option>
                                <option value="GST@5%">IGST@12%</option>
                                <option value="GST@5%">IGST@18%</option>
                                <option value="GST@5%">IGST@28%</option>



                            </select>

                        </div>
                    </div>
                    <div
                        style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 700,
                            marginBottom: "1.25rem",
                        }}
                        className={"w-full md:w-[25%] xl:w-[25%] 2xl:w-[25%] text-right  sm:pl-3 sm:pr-0 pr-3 flex flex-row sm:block mb-3"}
                    >
                        <span className="sm:hidden w-1/2 flex flex-row items-center">
                            Discount
                        </span>

                        <div className="font-title flex-1 text-left">
                            <input
                                autoComplete="nope"
                                type=""
                                name="discount"
                                value={servicerow.units == 'None' ? '-' : servicerow.units}
                                onChange={(event) => {
                                    const value = event.target.value.replace(/[^0-9]/g, '');
                                    handleInputChange(index, "units", value);
                                }}
                                placeholder="Disc %"
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    borderColor: "#00A787",
                                }}
                                className={
                                    defaultInputSmStyle +
                                    " text-right remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full  "
                                }
                            />

                        </div>
                    </div> */}
                    <div
                        style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 700,
                        }}
                        className={" w-full md:w-[36%] lg:w-[36%] xl:w-[36%] 2xl:w-[36%]  text-right pr-2 sm:pr-12 flex flex-row sm:block whitespace-nowrap"}
                    >
                        <span className="sm:hidden w-1/2 flex flex-row items-center">
                            Total
                        </span>
                        <span className={"inline-block w-1/2 sm:w-full px-1 h-9 overflow-hidden"}>
                            <NumberFormat value={servicerow?.amount} />
                        </span>
                    </div>

                    <div
                        onClick={() => removeRow(index, servicerow)}
                        className="w-full sm:w-10 sm:absolute sm:right-0 pl-2 h-9 mt-3 sm:mt-[-17px]"
                    >
                        <div className="w-full text-[#9C0000] font-semibold font-title sm:h-8 sm:w-8 cursor-pointer rounded-2xl bg-[#FFE0E0] flex justify-center items-center text-center p-[5px]">
                            <DeleteIcon className="h-4 w-4" style={IconStyle} />
                            <span className="block sm:hidden">Delete Product</span>
                        </div>
                    </div>
                </div>
            ))}



            {/* <div
                style={{ fontFamily: "poppins", fontSize: "14px" }}
                className="flex flex-col sm:flex-row rounded-lg sm:visible w-full px-4 py-2 items-center sm:justify-end"
            >


                <div
                    style={{ fontFamily: "poppins", fontSize: "14px" }}
                    className="flex flex-col sm:flex-row rounded-lg sm:visible w-full px-4 py-2 items-center sm:justify-end"
                >

                    <div className="w-full  text-right md:mr-5 2xl:mr-0 xl:mr-0 lg:mr-0 md:pr-0 2xl:pr-8 xl:pr-8 lg:pr-8 sm:pr-8 flex flex-row sm:block mb-1">
                        <button
                            type="button"
                            className="rounded-xl font-bold text-white flex flex-row items-center justify-center  text-sm h-8 px-2  block w-full whitespace-nowrap "
                            style={{ backgroundColor: "#008065" }}
                            onClick={addRowValue}
                        >
                            Add New Service
                        </button>
                    </div>
                    <div
                        style={{ fontFamily: "poppins", fontSize: "14px" }}
                        className="font-title w-full text-right md:pr-0 2xl:pr-8 xl:pr-8 lg:pr-8 sm:pr-8 flex flex-row sm:block mb-1"
                    >
                    </div>
                    <div
                        style={{ fontFamily: "poppins", fontSize: "14px" }}
                        className="font-title w-full text-right md:pr-0 2xl:pr-8 xl:pr-8 lg:pr-8 sm:pr-8 flex flex-row sm:block mb-1"
                    >
                    </div>
                </div>
            </div> */}

            <div className={"flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-4 py-1 items-center"}>
                <div style={{ fontFamily: "poppins", fontSize: "14px", fontWeight: 700 }} className={"font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"}>
                    <button
                        type="button"
                        className="rounded-xl font-bold text-white flex flex-row items-center justify-center  text-sm h-8 px-2  block w-full whitespace-nowrap "
                        style={{ backgroundColor: "#008065" }}
                        onClick={addRowValue}
                    >
                        Add New Service
                    </button>
                </div>
                <div style={{ fontFamily: "poppins", fontSize: "14px", fontWeight: 700 }} className={"font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"}>
                    SUBTOTAL
                </div>
                <div style={{ fontFamily: "poppins", fontSize: "14px", fontWeight: 700 }} className={"font-title w-1/2 sm:w-1/4 text-right sm:pr-9 flex flex-row justify-end sm:block mb-1 whitespace-nowrap"}>
                    <NumberFormat value={formData.subtotal ? formData.subtotal : 0} />
                </div>
            </div>
            <div className={"flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-4 py-1 items-center"}>
                <div style={{ fontFamily: "poppins", fontSize: "14px", fontWeight: 700 }} className={"font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"}>
                    <input
                        style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 400,
                            borderColor: "#00A787",
                        }}
                        autoComplete="nope"
                        name="discount"
                        onChange={(e) => handleDiscountAndtaxChange(e, 'discount')}
                        placeholder="Discount"
                        className={
                            defaultInputSmStyle +
                            " text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full   "
                        }
                    />
                </div>
                <div style={{ fontFamily: "poppins", fontSize: "14px", fontWeight: 700 }} className={"font-title w-1/2 sm:w-1/4 text-right sm:pr-9 flex flex-row justify-end sm:block mb-1 whitespace-nowrap"}>
                    <NumberFormat value={discount ? discount : 0} />
                </div>
            </div>
            <div className={"flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-4 py-1 items-center"}>

                <div style={{ fontFamily: "poppins", fontSize: "14px", fontWeight: 700 }} className={"font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0 flex flex-row"}>
                    <select
                        onChange={(e) => handleGstTypeChange(e)}
                        name="taxPercentage"
                        style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 400,
                            borderColor: "#00A787",
                        }}
                        value={gstValue}
                        className={
                            defaultInputSmStyle +
                            " text-left remove-spinner border-2 focus:outline-none rounded-[7px]  h-9 w-full  "
                        }>
                        <option value="GST">GST</option>
                        <option value="IGST">IGST</option>




                    </select>

                </div>
                <div style={{ fontFamily: "poppins", fontSize: "14px", fontWeight: 700 }} className={"font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0 flex flex-row"}>
                    <input
                        style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 400,
                            borderColor: "#00A787",
                        }}
                        autoComplete="nope"
                        name="discount"
                        onChange={(e) => handleDiscountAndtaxChange(e, 'tax')}
                        placeholder="Tax"
                        type=""
                        className={
                            defaultInputSmStyle +
                            " text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full   "
                        }
                    />
                </div>
                <div style={{ fontFamily: "poppins", fontSize: "14px", fontWeight: 700 }} className={"font-title w-1/2 sm:w-1/4 text-right sm:pr-9 flex flex-row justify-end sm:block mb-1 whitespace-nowrap"}>
                    <NumberFormat value={tax ? tax : 0} />
                </div>
            </div>
            <div className={"flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-4 py-1 items-center"}>
                <div style={{ fontFamily: "poppins", fontSize: "14px", fontWeight: 700 }} className={"font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"}>
                    TOTAL
                </div>
                <div style={{ fontFamily: "poppins", fontSize: "14px", fontWeight: 700 }} className={"font-title w-1/2 sm:w-1/4 text-right sm:pr-9 flex flex-row justify-end sm:block mb-1 whitespace-nowrap"}>
                    <NumberFormat value={formData.toatalAmount ? formData.toatalAmount : 0} />
                </div>
            </div>

            {isModalOpen && (

                <ServiceModel
                    closeModal={closeModal}
                    setServiceRows={setServiceRows}
                    serviceRows={serviceRows}
                    handleSelectService={handleSelectService}
                />
            )}
            {showConfirmation && (
                <StockUpdateModel
                    message="Are you sure you want to proceed?"
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                />
            )}
        </div>
    );
}

export default ServiceDetails;
