import api from "../axiosConfig";
import {
   PURCHASE_RETURN_DELETE_FAIL,
   PURCHASE_RETURN_DELETE_REQUEST,
   PURCHASE_RETURN_DELETE_SUCCESS,
   PURCHASE_RETURN_EDIT_FAIL,
   PURCHASE_RETURN_EDIT_REQUEST,
   PURCHASE_RETURN_EDIT_SUCCESS,
   PURCHASE_RETURN_FIND_ALL_FAIL,
   PURCHASE_RETURN_FIND_ALL_REQUEST,
   PURCHASE_RETURN_FIND_ALL_SUCCESS,
   PURCHASE_RETURN_FIND_ONE_FAIL,
   PURCHASE_RETURN_FIND_ONE_REQUEST,
   PURCHASE_RETURN_FIND_ONE_SUCCESS,
   PURCHASE_RETURN_POST_FAIL,
   PURCHASE_RETURN_POST_REQUEST,
   PURCHASE_RETURN_POST_SUCCESS,
} from "../constants/purchaseReturnConstants";

export const purchaseReturnPost =
   (Invoice: any) => async (dispatch: any, getState: any) => {
      console.log("Invoice------------->",Invoice)
      dispatch({ type: PURCHASE_RETURN_POST_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();

         const { data } = await api.post(`/api/purchasereturn/save`, Invoice, {
            headers: { Authorization: `Bearer ${token?.tocken}` },
         });
         return dispatch({
            type: PURCHASE_RETURN_POST_SUCCESS,
            payload: data,
         });
      } catch (error: any) {
         dispatch({
            type: PURCHASE_RETURN_POST_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };

export const PchaseReturnEdit =
   (id: any, externalInvoice: any) => async (dispatch: any, getState: any) => {
      dispatch({ type: PURCHASE_RETURN_EDIT_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         const { data } = await api.put(
                        `/api/purchasereturn/edit/${id}`,
                       externalInvoice,
                        {
                           headers: { Authorization: `Bearer ${token?.tocken}` },
                         }
                    );
         
         return dispatch({
            type: PURCHASE_RETURN_EDIT_SUCCESS,
            payload: data,
         });
      } catch (error: any) {
         dispatch({
            type: PURCHASE_RETURN_EDIT_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };

// export const InternalInvoiceUpdate =
//    (id: any, externalInvoice: any) => async (dispatch: any, getState: any) => {
//       dispatch({ type: INTERNAL_INVOICE_UPDATE_REQUEST });
//       try {
//          const {
//             userSignin: {
//                userInfo: { token },
//             },
//          } = getState();
//          const { data } = await api.put(
//             `/api/internal/put/${id}`,
//             externalInvoice,
//             {
//                headers: { Authorization: `Bearer ${token?.tocken}` },
//             }
//          );
//          return dispatch({
//             type: INTERNAL_INVOICE_UPDATE_SUCCESS,
//             payload: data,
//          });
//       } catch (error: any) {
//          dispatch({
//             type: INTERNAL_INVOICE_UPDATE_FAIL,
//             payload:
//                error.response && error.response.data.message
//                   ? error.response.data.message
//                   : error.message,
//          });
//       }
//    };

// export const InternalDashboardGetCount =
//    () => async (dispatch: any, getState: any) => {
//       dispatch({ type: DASHBOARD_INTERNAL_INVOICE_GET_COUNT_REQUEST });

//       try {
//          const {
//             userSignin: {
//                userInfo: { token },
//             },
//          } = getState();
//          const { data } = await api.get(
//             `/api/dashboard/get-count/${token?._id}`,
//             {
//                headers: { Authorization: `Bearer ${token?.tocken}` },
//             }
//          );
//          return dispatch({
//             type: DASHBOARD_INTERNAL_INVOICE_GET_COUNT_SUCCESS,
//             payload: data,
//          });
//       } catch (error: any) {
//          dispatch({
//             type: DASHBOARD_INTERNAL_INVOICE_GET_COUNT_FAIL,
//             payload:
//                error.response && error.response.data.message
//                   ? error.response.data.message
//                   : error.message,
//          });
//       }
//    };

export const purchaseReturnFindAll =
   () => async (dispatch: any, getState: any) => {
      dispatch({ type: PURCHASE_RETURN_FIND_ALL_REQUEST });

      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         const { data } = await api.get(
            `/api/purchasereturn/find-all/${token?._id}`,
            {
               headers: { Authorization: `Bearer ${token?.tocken}` },
            }
         );
         return dispatch({
            type: PURCHASE_RETURN_FIND_ALL_SUCCESS,
            payload: data,
         });
      } catch (error: any) {
         dispatch({
            type: PURCHASE_RETURN_FIND_ALL_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };

export const purchaseInternalFindOne =
   (id: any) => async (dispatch: any, getState: any) => {
      dispatch({ type: PURCHASE_RETURN_FIND_ONE_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         const { data } = await api.get(`/api/purchasereturn/find-one/${id}`, {
            headers: { Authorization: `Bearer ${token?.tocken}` },
         });
         return dispatch({
            type: PURCHASE_RETURN_FIND_ONE_SUCCESS,
            payload: data,
         });
      } catch (error: any) {
         dispatch({
            type: PURCHASE_RETURN_FIND_ONE_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };

export const PurchaseReturnDelete =
   (id: any) => async (dispatch: any, getState: any) => {
      dispatch({ type: PURCHASE_RETURN_DELETE_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         const { data } = await api.delete(`/api/purchasereturn/delete/${id}`, {
            headers: { Authorization: `Bearer ${token?.tocken}` },
         });
         return dispatch({
            type: PURCHASE_RETURN_DELETE_SUCCESS,
            payload: data,
         });
      } catch (error: any) {
         dispatch({
            type: PURCHASE_RETURN_DELETE_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };

// export const GetCountryData = () => async (dispatch: any, getState: any) => {
//    dispatch({ type: COUNTRY_GET_REQUEST });
//    try {
//       const { data } = await api.get(`/api/country/getdetails`);
//       return dispatch({ type: COUNTRY_GET_SUCCESS, payload: data });
//    } catch (error: any) {
//       dispatch({
//          type: COUNTRY_GET_FAIL,
//          payload:
//             error.response && error.response.data.message
//                ? error.response.data.message
//                : error.message,
//       });
//    }
// };
