import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { paymentOutBillList, paymentOutEditFindOne, paymentOutPost, paymentOutUpdate } from '../../redux/actions/paymentOutAction';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { toast } from "react-toastify";
import { useData } from '../../layouts/shared/DataProvider';
import { fontFamilyGet } from '../../redux/actions/FontFamilyAction';
import { currencyGet } from '../../redux/actions/currencyAction';
import { InternalGetCount } from '../../redux/actions/InternalInvoiceAction';

const PaymentOutPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const usersignin = useSelector((state: any) => state.userSignin);
  const { userInfo } = usersignin;
  const userid = userInfo.token._id;
  const { selectedFont, setSelectedFont }: any = useData();
  const [paymentOutReceiptNo, setPaymentOutReceiptNo] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    clearErrors,
  } = useForm({
    defaultValues: {
      vendorName: "",
      invoiceNO: "",
      balanceAmount: "",
      receivedAmount: "",
      paymentType: "",
      user_id: userid,
    },
  });


  const params = useParams();
  const { id } = params;


  const [pendingbillOUtBillList, setpendingbillOutBillList] = useState([] as any)
  const [currencySymbol, setcurrencySymbol] = useState("₹");


  const fetchData = () => {
    dispatch(paymentOutBillList() as any).then((res: any) => {
      if (res) {
        setpendingbillOutBillList(res.payload)
      }
    });
    dispatch(fontFamilyGet(userid?._id) as any).then((res: any) => {
      if (res?.type === "FONTFAMILY_GET_SUCCESS") {
        let data = res?.payload?.find(
          (value: any) => value?.defaultInfo === true,
        );
        if (data) {
          setSelectedFont(data?.fontfamily);
        } else {
          setSelectedFont("poppins");
        }
      }
    });
    dispatch(currencyGet(userid?._id) as any).then((res: any) => {
      if (res) {
        let data = res.payload.find((value: any) => value.defaultInfo === true);
        if (data?.currencySymbol) {
          setcurrencySymbol(data.currencySymbol);
        } else {
          setcurrencySymbol("₹");
        }
      }
    });
    dispatch(InternalGetCount() as any).then((data: any) => {
      setPaymentOutReceiptNo(data?.payload?.paymentOutReceiptCount)
    });
  };


  useEffect(() => {
    fetchData()
  }, [])
  const [purchaseNo, setPurchaseNo] = useState([] as any)

  const fetchDatas = () => {
    if (id) {
      dispatch(paymentOutEditFindOne(id) as any).then((res: any) => {
        if (res?.type == "PAYMENTOUT_EDIT_FINDONE_SUCCESS") {
          setValue('vendorName', res.payload?.vendorName)
          setValue('invoiceNO', res.payload?.invoiceNo)
          setValue('balanceAmount', res.payload?.balanceAmount)
          setValue('receivedAmount', res.payload?.receivedAmount)
          setValue('paymentType', res.payload?.paymentType)
          setPurchaseNo([{ invoiceNo: res.payload?.invoiceNo }]);
        }

      })
    }

  }

  useEffect(() => {
    fetchDatas()
  }, [id])
  const onSubmit = (data: any) => {

    let postdata = {
      paymentOutReceiptNo: paymentOutReceiptNo,
      vendorName: data.vendorName,
      previousReceivedAmount: data.receivedAmount,
      previousBalanceAmount: data.balanceAmount,
      balanceAmount: data.balanceAmount - parseInt(data.receivedAmount),
      // billAmount: uniqueInvoiceNo?.[0]?.toatalAmount,
      chequeNum: data.chequeNum,
      invoiceNO: data.invoiceNO,
      paymentType: data.paymentType,
      receivedAmount: data.receivedAmount,
      user_id: userid,
    };
    if (id) {
      dispatch(paymentOutUpdate(id, postdata) as any).then((res: any) => {

        const queryParams = new URLSearchParams();

        queryParams.append("param6", selectedFont);
        queryParams.append("param7", currencySymbol);

        const url = `/api/paymentout_pdf/${id}/${userid}?${queryParams.toString()}`;

        fetch(url, {
          method: "GET",
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.blob();
          })
          .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link: any = document.createElement("a");
            link.href = url;
            const formattedDate = moment().format("YYYY-MM-DD");
            link.setAttribute(
              "download",
              `paymentreceipt_${formattedDate}.pdf`,
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            //setProductError(false);
            //setInvoiceUpdateId(response?.payload?.invoice_id);
          })
          .catch((error) => console.error("Error downloading PDF:", error));

        if (res) {
          // fetchData();
          toast.success("PaymentOut Update Successfully!", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 800,
          });
          navigate("/app/paymentsOut");

        }
      });

    }
    else {
      dispatch(paymentOutPost(postdata) as any).then((res: any) => {
        if (res?.payload?.paymentOut_id) {
          const queryParams = new URLSearchParams();
          queryParams.append("param6", selectedFont);
          queryParams.append("param7", currencySymbol);
          const url = `/api/paymentout_pdf/${res?.payload?.paymentOut_id}/${userid}?${queryParams.toString()}`;
          fetch(url, {
            method: "GET",
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.blob();
            })
            .then((blob) => {
              const url = window.URL.createObjectURL(new Blob([blob]));
              const link: any = document.createElement("a");
              link.href = url;
              const formattedDate = moment().format("YYYY-MM-DD");
              link.setAttribute(
                "download",
                `paymentreceipt_${formattedDate}.pdf`,
              );
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
            })
            .catch((error) => console.error("Error downloading PDF:", error));
        }
        if (res) {
          // fetchData();
          toast.success("PaymentOut Saved Successfully!", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 800,
          });
          navigate("/app/paymentsOut");

        }
      });
    }


  }

  const uniqueVendors = Array?.from(
    new Set(pendingbillOUtBillList?.map((item: any) => item.vendorName))
  ).map((name: any) => {
    return pendingbillOUtBillList?.find((item: any) => item.vendorName === name);
  });




  const vendorNames = (e: any) => {
    let defaultValues = {
      invoiceNO: "",
      balanceAmount: "",
      receivedAmount: "",
      paymentType: "",
    }
    reset({ ...defaultValues });
    const vendorValue = pendingbillOUtBillList.filter((item: any) => item.vendorName == e)
    setPurchaseNo(vendorValue)
  }

  const Purchaseno = (e: any) => {
    const balanceValue = pendingbillOUtBillList.find((item: any) => item.invoiceNo == e)
    setValue("balanceAmount", balanceValue.balanceAmount)

  }
  return (
    <div>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="bg-[#E1E8E7] " style={{ fontFamily: "poppins" }}>
            <div className="p-4 text-2xl font-bold text-[#045545]  text-lg   ml-2">
              Payment Out
            </div>
            <fieldset className="mx-3 rounded-lg">
              <legend className="p-4  font-bold text-[#008065] ">
                Payment
              </legend>
              <div className="flex flex-col lg:flex-row xl:flex-row gap-2 pl-4  sm:px-3  sm:px-3 md:px-3 lg:px-3 xl:px-3 2xl:px-3 mb-4">
                <div className="flex flex-col mb-2 w-48 md:w-full lg:w-1/3 xl:w-1/4">

                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                    className="required"
                  >
                    <span>Vendors Name</span>
                  </label>
                  <select
                    id="vendorName"
                    className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 lg:w-full"
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                    }}

                    {...register('vendorName'
                      , {
                        onChange: (e) =>
                          vendorNames(e.target.value),
                        required: "Vendor Name is required",
                      }
                    )}
                  >
                    <option value="" disabled selected hidden>
                      Select Vendor
                    </option>
                    {
                      uniqueVendors.map((data: any, index: any) => (
                        <option key={index} value={data.vendorName}>
                          {data.vendorName}
                        </option>
                      ))
                    }
                  </select>
                  {errors.vendorName && <p style={{ fontFamily: "poppins", color: 'red' }}>{errors.vendorName.message}</p>}
                </div>
                <div className="flex flex-col mb-2 w-48 md:w-full  lg:w-1/3 xl:w-1/4">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                    className="required"
                  >
                    <span>Invoice No</span>
                  </label>
                  <select
                    id="invoiceNO"
                    className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 lg:w-full"
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                    }}
                    {...register("invoiceNO", {
                      required: 'invoiceNO is required',
                      onChange: (e: any) => Purchaseno(e.target.value),
                    })}
                  >
                    <option value="" disabled selected hidden>
                      Select Purchase No
                    </option>
                    {
                      purchaseNo.map((data: any, index: any) => (
                        <option key={index} value={data.invoiceNo}>
                          {data.invoiceNo}
                        </option>
                      ))
                    }
                  </select>
                  {errors.invoiceNO && <p style={{ fontFamily: "poppins", color: 'red' }}>{errors.invoiceNO.message}</p>}
                </div>
                <div className="flex flex-col mb-2 w-48 md:w-full  lg:w-1/3 xl:w-1/4">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                  >
                    Balance Amount
                  </label>
                  <div className="relative">
                    <input
                      type="number"
                      className={
                        "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                      }
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                      {...register("balanceAmount")}
                      readOnly
                    />
                  </div>
                </div>
                <div className="flex flex-col mb-2 w-48 md:w-full  lg:w-1/3 xl:w-1/4">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                    className="required"
                  >
                    <span> Amount Payable</span>
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      className={
                        "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                      }
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                      {...register("receivedAmount", {
                        required: "Amount is required",
                      })}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^0-9]/g, "");
                        e.target.value = value;

                        clearErrors("receivedAmount");
                      }}
                    />

                    {errors.receivedAmount && <p style={{ fontFamily: "poppins", color: 'red' }}>{errors.receivedAmount.message}</p>}
                  </div>
                </div>
              </div>
              <div className="flex flex-col lg:flex-row xl:flex-row gap-2 pl-4  sm:px-3  sm:px-3 md:px-3 lg:px-3 xl:px-3 2xl:px-3 mb-4">

                <div className="flex flex-col mb-2 w-48 md:w-full  lg:w-1/3 xl:w-1/4">

                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                    className="required"
                  >
                    <span> Payment Type  </span>
                  </label>
                  <select
                    id="paymentType"
                    className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 lg:w-full"
                    style={{
                      fontFamily: "poppins",
                      fontSize: "13px",
                      fontWeight: 400,
                    }}
                    {...register("paymentType", {
                      required: "Select The Payment Type",
                    })}

                  >
                    <option value="">Please Select Type</option>
                    <option value="cash">Cash</option>
                    <option value="cheque">Cheque</option>
                  </select>
                  {errors.paymentType && <p style={{ fontFamily: "poppins", color: 'red' }}>{errors.paymentType.message}</p>}
                </div>
                {/* {selectedOption === "cheque" && (
                  <div className="flex flex-col mb-2 w-48 md:w-full  lg:w-1/3 xl:w-1/4">
                    <label
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                        color: "#008065",
                      }}
                    >
                      Cheque No
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        className={
                          "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                        }
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                        }}
                        {...register("chequeNum", {
                          required: "Check Number is Required",
                        })}
                        onChange={(e) => {
                          const value = e.target.value.replace(/[^0-9]/g, "");
                          e.target.value = value;
                        }}
                      />
                      {errors.chequeNum && <p style={{ fontFamily: "poppins", color: 'red' }}>{errors.chequeNum.message}</p>}
                    </div>
                  </div>
                )} */}
              </div>
              {/* <-Submit cancel-> */}
              <div className="lg:flex md:flex justify-end mb-3">
                <div className="flex justify-end w-full flex-col sm:flex-row pl-4">
                  <div className="pr-3">
                    <button
                      type="button"
                      className="rounded-xl mt-3 font-bold text-white  text-sm h-8 lg:w-[10rem] md:w-[10rem] sm:w-[10rem] w-48 bg-[#008065] "
                      onClick={() => reset()}
                      style={{ fontFamily: "poppins", fontSize: "14px" }}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="pr-3">
                    <button
                      type="submit"
                      className="rounded-xl mt-3 font-bold text-white  text-sm h-8 lg:w-[10rem] md:w-[10rem] sm:w-[10rem] w-48 bg-[#008065] "
                      style={{ fontFamily: "poppins", fontSize: "14px" }}
                    >
                      {"Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </form >
      </div >
    </div>
  )
}

export default PaymentOutPage
