import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const ChartsTwo = () => {
  const { saleslist } = useSelector((state: any) => state.SalesList);
  const [salesCount, setSalesCount] = useState([] as any);
  const [salesRevenue, setSalesRevenue] = useState([] as any);
  const [selectedMonth, setSelectedMonth] = useState<number>(
    moment().month() + 1,
  );

  const getMonthQty = (products: any, selectedMonth: number) => {
    const startDate = moment()
      .set({ month: selectedMonth - 1, date: 1 })
      .startOf("day");
    const endDate = moment(startDate).endOf("month");

    const data1: number[] = Array(endDate.date()).fill(0);
    const data2: number[] = Array(endDate.date()).fill(0);

    products.forEach((product: any) => {
      const creationDate = moment(product.creation_date);
      if (creationDate.isBetween(startDate, endDate, undefined, "[)")) {
        const dayIndex = creationDate.date() - 1;
        data1[dayIndex] += parseInt(product.qty);
        data2[dayIndex] += parseInt(product.grandTotal);
      }
    });
    setSalesCount(data1);
    setSalesRevenue(data2);
    return data1;
  };

  useEffect(() => {
    if (saleslist) getMonthQty(saleslist, selectedMonth);
  }, [selectedMonth, saleslist]);

  const currentMonth = new Date().getMonth() + 1; // Current month

  const labels = Array.from({ length: salesCount.length }, (_, i) => i + 1);
  const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedMonth(parseInt(event.target.value));
  };

  let options: any = {
    chart: {
      id: "vertical-bar-chart",
      type: "bar",
      height: 350,
      toolbar: {
        show: false, // Hide toolbar icons
      },
    },
    xaxis: {
      categories: labels,
    },
    dataLabels: {
      enabled: false, // Disable data labels
    },
  };
  let series: any = [
    {
      name: "Sales",
      data: [...salesCount],
    },
    {
      name: "Revenue",
      data: [...salesRevenue],
    },
  ];

  return (
    <div>
      <div className="col-span-12 rounded-lg  border border-stroke w-[85.5%] md:w-[93%] lg:w-[97%] xl:w-[94.5%] 2xl:w-[94.7%] ml-3 md:ml-3 lg:ml-3 xl:ml-0 h-[423px] bg-white px-5 pt-7.5 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:col-span-8">
        <div className="mb-4 justify-between gap-4 sm:flex">
          <div>
            <h4 className="text-xl font-semibold text-black dark:text-white">
              Sales Forecast
            </h4>
          </div>
          {/* <div> */}
          <div className="relative z-20 inline-block my-1">
            <select
              value={selectedMonth}
              onChange={handleMonthChange}
              className="border-2 border-black-900"
            >
              {moment.months().map((month, index) => (
                <option
                  key={index}
                  value={index + 1}
                  disabled={index + 1 > currentMonth}
                >
                  {month}
                </option>
              ))}
            </select>
          </div>
          {/* </div> */}
        </div>

        <div>
          <div id="chartTwo" className="">
            <Chart options={options} series={series} type="bar" height={350} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChartsTwo;
