import { useEffect, useState } from "react";
import NumberFormat from "../commen/NumberFormat";
import { toWords } from 'number-to-words';

const HomePreview = ({ Invoice, componentRef, closeModal, invoice }: any) => {

  const totalAmount = Invoice.toatalAmount;
  const roundedtotalAmount = Math.round(totalAmount);
  const totalAmountInWords = Number.isFinite(roundedtotalAmount) ? toWords(roundedtotalAmount) : 'Invalid amount';

 
  const [preview, setPreview] = useState(null as any);
  const handleCancelClick = () => {
    setPreview(null);
    closeModal();
  };

  useEffect(() => {
    setPreview(Invoice);
  }, [Invoice]);

  return (
    <div content={componentRef}>
      <div style={{ breakBefore: "page" }}></div>
      {/*   image and client information                */}

      <div className="grid grid-cols-6">
        <div className="col-start-1 col-end-2">
          {Invoice.imageData ? (
            <div className=" font-title  sm:text-left ">
              <label
                style={{ fontFamily: "poppins" }}
                className="   items-start  text-blue      "
              >
                <img
                  className=" pr-0 sm:pr-8 md:pr-8 pt-7 h-20 2xl:ml-7 xl:ml-7 lg:ml-7 md:ml-7 sm:ml-4 "
                  src={preview?.imageData}
                  alt="logonew "
                />
              </label>
            </div>
          ) : null}
        </div>

        <div className="col-start-2 col-span-4 ">
          {Invoice.companyName ? (
            <div className=" text-center sm:font-normal font-semibold pt-7 text-md sm:text-4xl ">
              {preview?.companyName}
            </div>
          ) : null}
        </div>
      </div>

      <div className="flex flex-col justify-center items-center">
        <div className="text-center">
          <div className="mt-1">
            <span>
              {Invoice.companyAddress ? (
                <span>Address: {preview?.companyAddress},</span>
              ) : null}
              {Invoice.companyCity ? (
                <span>{preview?.companyCity},</span>
              ) : null}

              {Invoice.companyState ? (
                <span className="pl-1">{preview?.companyState}-</span>
              ) : null}
              {Invoice.companyPostalCode ? (
                <span className="pl-1">{preview?.companyPostalCode},</span>
              ) : null}
              {Invoice.companygst ? (
                <span>
                  
                  Gstn:
                  {preview?.companygst}
                </span>
              ) : null}
            </span>
          </div>
          <div>
            <span>
              {Invoice.companyPan ? (
                <span>PAN: {preview?.companyPan},</span>
              ) : null}
              {Invoice.companyEmail ? (
                <>
                  Email:
                  <span className="lowercase"> {preview?.companyEmail},</span>
                </>
              ) : null}
              {preview?.companyMobile ? (
                <span>Mobile: {preview?.companyMobile},</span>
              ) : null}
            </span>
          </div>
        </div>
      </div>
      <div className="border "></div>
      {/******************** invoice name ********************/}

      <div className="bg-white  overflow-x-auto" id="InvoiceWrapper">
        <div className="justify-center flex flex-row relative ">
          <span
            className="w-60 md:w-11/12 lg:w-11/12 2xl:w-11/12 truncate rounded-[7px] text-md sm:text-2xl text-center  focus:outline-none"
            style={{
              fontFamily: "poppins",
              // fontSize: "34px",
              fontWeight: 700,
            }}
          >
            {invoice ? invoice : preview?.invoicehead}
          </span>
          {/* <input
            readOnly
            className="w-full rounded-[7px] text-md  sm:text-2xl  text-center xs:w-1/4 focus:outline-none "
            autoComplete="nope"
            style={{
              fontFamily: "poppins",
              // fontSize: "34px",
              fontWeight: 700,
            }}
            id="input_focus"
            value={invoice ? invoice : preview?.invoicehead}
          /> */}
          {/* </h1> */}
        </div>
        {/*     client details           */}
        <div className=" px-4 bg-cover bg-center  rounded-xl flex flex-col sm:flex-row justify-between ">
          <div className="flex-1 md:mx-2">
            <div className="flex flex-col sm:flex-row pt-3  justify-between dateReverse">
              <div className="">
                <div className="flex flex-row  items-center mb-2 ">
                  <div
                    className="flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    style={{
                      color: "#008065",
                      fontFamily: "poppins",

                      fontWeight: 700,
                    }}
                  >
                    Client Details (Shipped To)
                  </div>
                </div>
                {Invoice.clientName ? (
                  <>
                    <div
                      className="flex justify-between"
                      // style={{ maxWidth: "500px", width: "325px" }}
                    >
                      <div
                        style={{
                          fontFamily: "poppins",
                          marginRight: "66px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      >
                        Name
                      </div>
                      <div className="">
                        <input
                          className="focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                          readOnly
                          value={preview?.clientName}
                          style={{
                            fontFamily: "poppins",

                            fontWeight: 400,
                            width: "123px",
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice.clientAddress ? (
                  <>
                    <div
                      className="flex justify-between"
                      // style={{ maxWidth: "500px", width: "325px" }}
                    >
                      <div
                        style={{
                          fontFamily: "poppins",
                          marginRight: "52px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      >
                        Address
                      </div>
                      <div className="">
                        <input
                          className="focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                          readOnly
                          value={preview?.clientAddress}
                          style={{
                            fontFamily: "poppins",

                            fontWeight: 400,
                            width: "123px",
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice.clientCity ? (
                  <>
                    <div
                      className="flex justify-between"
                      // style={{ maxWidth: "500px", width: "325px" }}
                    >
                      <div
                        style={{
                          fontFamily: "poppins",
                          marginRight: "78px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      >
                        City
                      </div>
                      <div className="">
                        <input
                          className="focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                          readOnly
                          value={preview?.clientCity}
                          style={{
                            fontFamily: "poppins",

                            fontWeight: 400,
                            width: "123px",
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice.clientState ? (
                  <>
                    <div
                      className="flex justify-between "
                      // style={{ maxWidth: "500px", width: "325px" }}
                    >
                      <div
                        style={{
                          fontFamily: "poppins",
                          marginRight: "70px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      >
                        State
                      </div>
                      <div className="">
                        <input
                          className="focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                          readOnly
                          value={preview?.clientState}
                          style={{
                            fontFamily: "poppins",

                            fontWeight: 400,
                            width: "123px",
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice.clientPostalCode ? (
                  <>
                    <div
                      className="flex justify-between "
                      // style={{ maxWidth: "500px", width: "325px" }}
                    >
                      <div
                        style={{
                          fontFamily: "poppins",
                          marginRight: "27px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs whitespace-nowrap lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      >
                        Postal Code
                      </div>
                      <div className="">
                        <input
                          className="focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                          readOnly
                          value={preview?.clientPostalCode}
                          style={{
                            fontFamily: "poppins",

                            fontWeight: 400,
                            width: "123px",
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice.clientMobile ? (
                  <>
                    <div
                      className="flex justify-between "
                      // style={{ maxWidth: "500px", width: "325px" }}
                    >
                      <div
                        style={{
                          fontFamily: "poppins",
                          marginRight: "7px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs whitespace-nowrap lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      >
                        Mobile Number
                      </div>
                      <div className="">
                        <input
                          className="focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                          readOnly
                          value={preview?.clientMobile}
                          style={{
                            fontFamily: "poppins",

                            fontWeight: 400,
                            width: "123px",
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice.clientEmail ? (
                  <>
                    <div
                      className="flex justify-between "
                      // style={{ maxWidth: "500px", width: "325px" }}
                    >
                      <div
                        style={{
                          fontFamily: "poppins",

                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md mr-16 sm:mr-20 md:mr-20 lg:mr-20 xl:mr-20 2xl:mr-20"
                      >
                        Email
                      </div>
                      <div className="">
                        <input
                          className="focus:outline-none text-xs  lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                          readOnly
                          value={preview?.clientEmail}
                          style={{
                            fontFamily: "poppins",

                            fontWeight: 400,
                            width: "123px",
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice.clientPan ? (
                  <>
                    <div
                      className="flex justify-between "
                      // style={{ maxWidth: "500px", width: "325px" }}
                    >
                      <div
                        style={{
                          fontFamily: "poppins",
                          marginRight: "24px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md whitespace-nowrap"
                      >
                        PAN Number
                      </div>
                      <div className="">
                        <input
                          className="focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                          readOnly
                          value={preview?.clientPan}
                          style={{
                            fontFamily: "poppins",

                            fontWeight: 400,
                            width: "123px",
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice.clientgst ? (
                  <>
                    <div
                      className="flex justify-between "
                      // style={{ maxWidth: "500px", width: "325px" }}
                    >
                      <div
                        style={{
                          fontFamily: "poppins",
                          marginRight: "76px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      >
                        GST
                      </div>
                      <div className="">
                        <input
                          className="focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                          readOnly
                          value={preview?.clientgst}
                          style={{
                            fontFamily: "poppins",

                            fontWeight: 400,
                            width: "123px",
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
              </div>

              <div className="homePreview">
                <div className="flex flex-row items-center justify-between mb-2 mr-8 ">
                  <div
                    style={{
                      color: "#008065",
                      fontFamily: "poppins",

                      fontWeight: 700,
                    }}
                    className="flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md datePreview"
                  >
                    INVOICE #
                  </div>
                  <div className="font-title flex-1 text-right ">
                    <input
                      readOnly
                      value={preview?.invoiceNo}
                      style={{
                        fontFamily: "poppins",
                      }}
                      className="font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full  block focus:outline-none "
                    />
                  </div>
                  <div></div>
                </div>

                <>
                  <div className="flex flex-row justify-between items-center mb-2 mr-8 ">
                    <div
                      style={{
                        color: "#008065",
                        fontFamily: "poppins",

                        fontWeight: 700,
                      }}
                      className=" flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md datePreview"
                    >
                      Creation Date
                    </div>
                    <div className="font-title flex-1 text-right">
                      <input
                        readOnly
                        value={preview?.invoicedate}
                        style={{
                          fontFamily: "poppins",
                        }}
                        className="font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md block w-full   focus:outline-none "
                      ></input>
                    </div>

                    <div></div>
                  </div>
                </>

                {Invoice.duedate ? (
                  <>
                    <div className="flex flex-row justify-between items-center mb-2 mr-8">
                      <div
                        style={{
                          color: "#008065",
                          fontFamily: "poppins",

                          fontWeight: 700,
                        }}
                        className=" flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md datePreview"
                      >
                        Due Date
                      </div>
                      <div className="font-title flex-1 text-right ">
                        <div>
                          <input
                            readOnly
                            value={preview?.duedate}
                            style={{
                              fontFamily: "poppins",
                            }}
                            className="font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md  block w-full  focus:outline-none  "
                          ></input>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {/* product details */}
        <div className="px-8 mt-3">
          <div className="hidden sm:flex rounded-lg invisible sm:visible w-full flex-col sm:flex-row px-4 py-2 text-white bg-[#008065]">
            <div
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
              className=" sm:w-1/4 text-left sm:pr-11 "
            >
              <span className="inline-block">Product</span>
            </div>
            {/* <div
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
              className="w-40 sm:w-1/4 text-left  "
            >
              <span className="inline-block ">Hsn</span>
            </div> */}
            <div
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
              className=" w-full sm:w-1/4 2xl:text-left  2xl:pl-16  xl:text-center xl:mr-16 lg:text-center    md:text-center md:mr-14   sm:text-left     "
            >
              Price
            </div>

            <div
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
              className="w-full sm:w-1/4 text-center sm:text-center   "
            >
              Quantity
            </div>
            <div
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
              className=" w-full sm:w-1/4 text-right 2xl:text-right   "
            >
              Total
            </div>
          </div>
        </div>
        {Invoice?.product?.map((product: any, index: number) => {
          return (
            <div
              key={`${index}_${product.id}`}
              className="flex lg:flex-row flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible md:flex-row  xl:flex-row flex-col rounded-lg w-full  2xl:px-6 xl:px-6  md:px-7 px-4  py-1 items-center relative text-sm"
            >
              <div
                style={{
                  fontFamily: "poppins",
                  fontWeight: 700,
                }}
                className=" w-full sm:w-1/4 text-right   flex flex-row sm:block "
              >
                <span
                  style={{
                    marginBottom: "5px",
                  }}
                  className="sm:hidden w-1/2 flex flex-row  items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                >
                  Product
                </span>
                <span className="inline-block w-full  product   mb-1 sm:mb-0   2xl:px-3  xl:px-3 lg:px-3 md:px-3">
                  <input
                    readOnly
                    value={product.prodcut}
                    style={{
                      fontFamily: "poppins",
                      fontWeight: 400,
                    }}
                    className="font-title  block w-full 2xl:text-left  2xl:ml-3 xl:ml-2 md:ml-3  xl:text-left  lg:text-left  md:text-left  sm:text-left  sm:ml-7  text-right focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  />
                </span>
              </div>

              {/* <div
                style={{
                  fontFamily: "poppins",
                  fontWeight: 700,
                }}
                className=" w-full sm:w-1/4 text-right flex flex-row sm:block   "
              >
                <span
                  style={{
                    marginBottom: "5px",
                  }}
                  className="sm:hidden w-full flex flex-row  items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                >
                  hsnNo
                </span>
                {Invoice?.product.length > 0 ?
                  (<span className="inline-block w-full  sm:w-full mb-1 sm:mb-0 ">
                    <input
                      readOnly
                      style={{
                        fontFamily: "poppins",
                        fontWeight: 400,
                      }}
                      value={product?.hsn ? product.hsn : "N/A"}
                      className="font-title  block w-full 2xl:mr-5 xl:ml-4  md:ml-4  2xl:text-left  xl:text-left  lg:text-left  md:text-left    sm:text-left  sm:ml-7    text-right focus:outline-none text-xs sm:text-sm  lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    />
                  </span>) : (
                    <></>
                  )}
              </div> */}

              <div
                style={{
                  fontFamily: "poppins",
                  fontWeight: 700,
                }}
                className=" w-full sm:w-1/4 text-left   flex flex-row sm:block "
              >
                <span
                  style={{
                    marginBottom: "5px",
                  }}
                  className="sm:hidden w-full flex flex-row items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                >
                  Price
                </span>
                <span className="inline-block  w-full   mb-1 sm:mb-0 ">
                  <input
                    style={{
                      fontFamily: "poppins",
                      marginTop: "",
                      fontWeight: 400,
                    }}
                    readOnly
                    value={product?.rate}
                    type={"number"}
                    className="font-title text-right block w-full   2xl:text-right   2xl:ml-4   xl:text-right  xl:ml-4 lg:text-right lg:r-0  lg:mr-10   md:text-right md:mr-7   sm:text-right sm:mr-6 sm:w-20 focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  />
                </span>
              </div>

              <>
                <div
                  style={{
                    fontFamily: "poppins",
                    fontWeight: 700,
                  }}
                  className=" w-full sm:w-1/4 text-right flex flex-row sm:block   "
                >
                  <span
                    style={{
                      marginBottom: "5px",
                    }}
                    className="sm:hidden w-1/4 flex flex-row items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  >
                    Quantity
                  </span>
                  <span className="inline-block  w-full mb-1 sm:mb-0">
                    <input
                      readOnly
                      value={product.qty}
                      type={"number"}
                      style={{
                        fontFamily: "poppins",
                        marginTop: "",
                        fontWeight: 400,
                      }}
                      className="font-title text-right block w-full 2xl:text-center    xl:text-center xl:pl-4        lg:text-center md:text-center  md:mr-3  sm:text-center sm:mr-10  focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    />
                  </span>
                </div>
              </>

              <>
                <div
                  style={{
                    fontFamily: "poppins",
                    fontWeight: 700,
                  }}
                  className=" w-full sm:w-1/4 text-right  flex flex-row sm:block"
                >
                  <span
                    style={{
                      marginBottom: "5px",
                    }}
                    className="sm:hidden w-1/2  flex flex-row items-center text-xs  lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  >
                    Total
                  </span>
                  <span className="font-block w-full sm:w-full  md:w-full  lg:w-full lg:pr-4 md:pr-4  sm:pr-7  lg:pr-4  xl:pr-5 text-xs sm:text-sm">
                    <NumberFormat value={product?.amount}></NumberFormat>
                  </span>
                </div>
              </>
            </div>
          );
        })}
        {/********************************** * subtotal *******************************/}
        <div className="flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-4 py-1 items-center ">
          <div
            style={{
              fontFamily: "poppins",
              fontSize: "14px",
              fontWeight: 700,
            }}
            className="font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
          >
            Subtotal
          </div>

          <div
            style={{
              fontFamily: "poppins",
              fontSize: "14px",
              fontWeight: 700,
            }}
            className="font-block w-1/2 sm:w-1/4 text-right sm:pr-7 flex flex-row justify-end sm:block mb-1"
          >
            <NumberFormat value={preview?.subtotal} />
          </div>
        </div>
        {Invoice.discount ? (
          <div className="flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-4 py-1 items-center ">
            <div
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
              className="font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
            >
              <span>
                Discount
                <span className="ml-2">{preview?.discountprecentage}% </span>
              </span>
            </div>

            <div
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
              className="font-block w-1/2 sm:w-1/4 text-right sm:pr-7 flex flex-row justify-end sm:block mb-1"
            >
              <NumberFormat value={preview?.discount} />
            </div>
          </div>
        ) : null}
        {Invoice?.tax?.map((tax: any, index: number) => {
          return (
            <div key={index}>
              {/* Render each tax item with specific details */}
              <div className="flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-4 py-1 items-center">
                <div
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                  className="font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
                >
                  {tax.taxName}
                  <span className="ml-2">{tax.taxPersent}% </span>
                </div>

                <div
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                  className="font-title w-1/2 sm:w-1/4 text-right sm:pr-7 flex flex-row justify-end sm:block mb-1"
                >
                  <NumberFormat
                    value={tax.taxAmount}
                    // displayType={"text"}
                    // thousandSeparator={true}
                  />
                </div>
              </div>

              {/* Similarly render other tax details */}
              {/* Example: SCGST, IGST, vat, OTHERS, extrafees, DISCOUNT */}
            </div>
          );
        })}
        {Invoice?.extrafeesRows?.map((extrafeesRows: any, index: number) => {
          return (
            <div key={index}>
              <div className="flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-4 py-1 items-center">
                <div
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                  className="font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
                >
                  <span>Extrafees</span>
                </div>

                <div
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                  className="font-title w-1/2 sm:w-1/4 text-right sm:pr-7 flex flex-row justify-end sm:block mb-1"
                >
                  <NumberFormat value={extrafeesRows.examount} />
                </div>
              </div>
            </div>
          );
        })}

        {/*************************total****************************/}
        <div className="flex flex-row sm:flex-row sm:justify-end  rounded-lg sm:visible w-full px-4 py-1 items-center ">
          <div
            style={{
              fontFamily: "poppins",
              fontSize: "14px",
              fontWeight: 700,
            }}
            className="font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
          >
            Total
          </div>

          <div
            style={{
              fontFamily: "poppins",
              fontSize: "14px",
              fontWeight: 700,
            }}
            className="font-title w-1/2 sm:w-1/4 text-right sm:pr-7 flex flex-row justify-end sm:block mb-1"
          >
            <NumberFormat
              value={preview?.toatalAmount}

              //   displayType={"text"}
              //   thousandSeparator={true}
            />
          </div>
        </div>
             {/*************************AMOUNT IN WORDS****************************/}
         <div className="flex flex-row sm:flex-row sm:justify-start rounded-lg sm:visible w-full  sm:px-4 py-1 items-center ">
       
       <div
          style={{
            fontFamily: "poppins",
            fontSize: "14px",
            fontWeight: 700,
          }}
         className="font-title w-1/2 sm:w-1/4 text-left pl-4 md:pl-5 lg:pl-4 flex flex-row sm:block mb-1 sm:mb-0 whitespace-nowrap capitalize"
       >
             {totalAmountInWords} only. 
           
       </div>

      
     </div>
        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            // onClick={() => setOpenView(!viewOpen)}
            // ref={cancelButtonRef}
            onClick={handleCancelClick}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
export default HomePreview;
