import moment from "moment";
import NumberFormat from "../commen/NumberFormat";
import { toWords } from 'number-to-words';
import { useSelector } from "react-redux";


const PreviewPurchaseProductwise = ({ Invoice, componentRef, closeModal, selectedFont }: any) => {

    const formattedDate = moment(Invoice.invoicedate).format("DD/MM/YYYY");
    const totalAmount = Invoice.toatalAmount;
    const roundedtotalAmount = Math.round(totalAmount);
    const totalAmountInWords = Number.isFinite(roundedtotalAmount) ? toWords(roundedtotalAmount) : 'Invalid amount';
    const template = useSelector((state: any) => state.userColorTemplate);
    const { userdata } = template;
    const handleCancelClick = () => {
      closeModal();
    };
    return (
      <div content={componentRef} style={{ fontFamily: selectedFont }}>
        <div style={{ breakBefore: "page" }}></div>
        {/*   image and client information   */}
  
        <div className="grid grid-cols-6 ">
          <div className="col-start-1 col-end-2">
            {Invoice.imageData ? (
              <div className=" font-title  sm:text-left ">
                <label
                  style={{ fontFamily: selectedFont }}
                  className="   items-start  text-blue      "
                >
                  <img
                    className="pr-0 sm:pr-8 md:pr-8 pt-7 h-20 2xl:ml-7 xl:ml-7 lg:ml-7 md:ml-7 sm:ml-4 "
                    src={Invoice.imageData}
                    alt="logonew "
                  />
                </label>
              </div>
            ) : null}
          </div>
  
          <div className="col-start-2 col-span-4 ">
            {Invoice.companyName ? (
              <div className=" text-center  pt-7 text-lg font-semibold  sm:font-normal sm:text-4xl ">
                {Invoice.companyName}
              </div>
            ) : null}
          </div>
        </div>
  
        <div className="border "></div>
        {/*        *******************   invoice name                */}
  
        <div className="bg-white  overflow-x-auto" id="InvoiceWrapper">
          <div className="justify-center flex flex-row relative ">
            <span
              className="w-64 md:w-11/12 lg:w-11/12 2xl:w-11/12 truncate rounded-[7px] text-md sm:text-2xl text-center  focus:outline-none"
              style={{
                fontFamily: selectedFont,
                fontWeight: 700,
              }}
            >
              {Invoice.invoicehead}
            </span>
            {/* <span
                  readOnly
                  className="w-11/12 truncate rounded-[7px] text-md sm:text-2xl text-center  focus:outline-none "
                  autoComplete="nope"
                  style={{
                    fontFamily:selectedFont,
                    fontWeight: 700,
                  }}
                  id="input_focus"
                  value={Invoice.invoicehead}
                /> */}
            {/* </h1> */}
          </div>
          {/*     client details           */}
          <div className=" px-4 bg-cover bg-center  rounded-xl flex flex-col sm:flex-row justify-between ">
            <div className="flex-1 md:mx-2 column-reverse">
              <div className="flex flex-col sm:flex-row pt-3  justify-between pre-reverse">
                <div className="lg:w-[90%] xl:w-[50%]  xl:w-[50%] 2xl:w-[50%] md:w-[70%]  mr-2 ">
                  <div className="flex flex-row  items-center mb-2 ">
                    <div
                      className="flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      style={{
                        color: "#008065",
                        fontFamily: selectedFont,
  
                        fontWeight: 700,
                      }}
                    >
                  Vendor Details
                    </div>
                  </div>
                  {Invoice.vendorName ? (
                    <>
                      <div className="flex justify-between">
                        <div
                          style={{
                            fontFamily: selectedFont,
                            marginRight: "66px",
                            fontWeight: 700,
                            verticalAlign: "top",
                          }}
                          className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                        >
                          Name
                        </div>
                        <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%]  md:w-[70%] sm:w-[90%] ">
                          <input
                            className="focus:outline-none  text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full"
                            readOnly
                            value={Invoice.vendorName}
                            style={{
                              fontFamily: selectedFont,
                              fontWeight: 400,
                              verticalAlign: "top",
                            }}
                          />
                        </div>
                      </div>
                    </>
                  ) : null}
                  {Invoice.vendorMobile ? (
                    <>
                      <div className="flex justify-between ">
                        <div
                          style={{
                            fontFamily: selectedFont,
                            fontWeight: 700,
                            verticalAlign: "top",
                          }}
                          className="text-left text-xs whitespace-nowrap lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                        >
                          Mobile Number
                        </div>
                        <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%]  md:w-[70%] sm:w-full">
                          <input
                            className="focus:outline-none w-full text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full"
                            readOnly
                            value={Invoice.vendorMobile}
                            style={{
                              fontFamily: selectedFont,
  
                              fontWeight: 400,
                              verticalAlign: "top",
                            }}
                          />
                        </div>
                      </div>
                    </>
                  ) : null}
                  {Invoice.vendorEmail ? (
                    <>
                      <div className="flex justify-between ">
                        <div
                          style={{
                            fontFamily: selectedFont,
                            fontWeight: 700,
                            verticalAlign: "top",
                          }}
                          className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                        >
                          Email
                        </div>
                        <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%]  md:w-[70%] sm:w-full">
                          <input
                            className="focus:outline-none  text-xs  lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full"
                            readOnly
                            value={Invoice.vendorEmail}
                            style={{
                              fontFamily: selectedFont,
  
                              fontWeight: 400,
                              verticalAlign: "top",
                            }}
                          />
                        </div>
                      </div>
                    </>
                  ) : null}
                  {Invoice.vendorgst ? (
                    <>
                      <div className="flex justify-between ">
                        <div
                          style={{
                            fontFamily: selectedFont,
                            marginRight: "80px",
                            fontWeight: 700,
                            verticalAlign: "top",
                          }}
                          className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                        >
                          GST
                        </div>
                        <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%]  md:w-[70%] sm:w-full">
                          <input
                            className="focus:outline-none w-full text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full"
                            readOnly
                            value={Invoice.vendorgst}
                            style={{
                              fontFamily: selectedFont,
  
                              fontWeight: 400,
                              verticalAlign: "top",
                            }}
                          />
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
  
                <div className="">
                  <div className="flex flex-row items-center justify-between mb-2 mr-8">
                    <div
                      style={{
                        color: "#008065",
                        fontFamily: selectedFont,
  
                        fontWeight: 700,
                      }}
                      className="invoicepreview flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    >
                      Purchase Entry No
                    </div>
                    <div className="font-title flex-1 text-right ">
                      <input
                        readOnly
                        value={Invoice.invoiceNo}
                        style={{
                          fontFamily: selectedFont,
                        }}
                        className="font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full ml-2 sm:ml-auto block focus:outline-none "
                      />
                    </div>
                    <div></div>
                  </div>
               
                  {Invoice.purchaseEntryDate ? (
                    <>
                      <div className="flex flex-row justify-between items-center mb-6 mr-8">
                        <div
                          style={{
                            color: "#008065",
                            fontFamily: selectedFont,
  
                            fontWeight: 700,
                          }}
                          className="invoicepreview flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                        >
                          purchase Entry Date
                        </div>
                        <div className="font-title flex-1 text-right ">
                          <div>
                            <input
                              readOnly
                              value={Invoice.purchaseEntryDate}
                              style={{
                                fontFamily: selectedFont,
                              }}
                              className=" font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md ml-2 sm:ml-auto block w-full  focus:outline-none  "
                            ></input>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                   {Invoice.PurchaseBillNo ? (
                    <>
                      <div className="flex flex-row justify-between items-center mb-6 mr-8">
                        <div
                          style={{
                            color: "#008065",
                            fontFamily: selectedFont,
  
                            fontWeight: 700,
                          }}
                          className="invoicepreview flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                        >
                          Purchase Bill No
                        </div>
                        <div className="font-title flex-1 text-right ">
                          <div>
                            <input
                              readOnly
                              value={Invoice.PurchaseBillNo}
                              style={{
                                fontFamily: selectedFont,
                              }}
                              className=" font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md ml-2 sm:ml-auto block w-full  focus:outline-none  "
                            ></input>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null} 
                     {Invoice.purchaseBillDate ? (
                    <>
                      <div className="flex flex-row justify-between items-center mb-2 mr-8 ">
                        <div
                          style={{
                            color: "#008065",
                            fontFamily: selectedFont,
  
                            fontWeight: 700,
                          }}
                          className="invoicepreview flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                        >
                        purchase Bill Date
                        </div>
                        <div className="font-title flex-1 text-right">
                          <input
                            readOnly
                            value={Invoice.purchaseBillDate}
                            style={{
                              fontFamily: selectedFont,
                            }}
                            className=" font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md block w-full ml-2 sm:ml-auto  focus:outline-none "
                          ></input>
                        </div>
                        <div></div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
         
          {/* product details */}
          <div className="px-8 mt-3">
            <div className="hidden sm:flex rounded-lg invisible sm:visible w-full flex-col sm:flex-row px-4 py-2 text-white bg-[#008065]">
              <div
                style={{
                  fontFamily: selectedFont,
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className=" sm:w-1/4 md:w-1/6 lg:w-2/12 xl:w-2/12 2xl:w-1/12 text-left "
              >
                <span className="inline-block">S No</span>
              </div>
              <div
                style={{
                  fontFamily: selectedFont,
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className=" sm:w-1/4 md:w-8/12 lg:w-8/12 xl:w-6/12 2xl:w-6/12 text-left "
              >
                <span className="inline-block">Product</span>
              </div>
              <div
                style={{
                  fontFamily: selectedFont,
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className="sm:w-1/4 2xl:ml-10  border-blue-600"
              >
                <span className="">Hsn</span>
              </div>
              <div
                style={{
                  fontFamily: selectedFont,
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className=" w-full sm:w-1/4 2xl:text-center  2xl:pl-1  xl:text-center xl:mr-10 lg:text-center md:text-right lg:mr-6 md:mr-8  sm:text-left"
              >
                <span className="">Price</span>
              </div>
  
              <div
                style={{
                  fontFamily: selectedFont,
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className="w-full sm:w-1/4 text-left 2xl:text-left   sm:text-center"
              >
                <span className="2xl:pl-8">Qty</span>
              </div>
              <div
                style={{
                  fontFamily: selectedFont,
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className="w-full sm:w-1/4 text-left 2xl:text-center sm:text-center   "
              >
                Disc(%)
              </div>
              {userdata?.gstValue == "IGST" ? (
                <>
                  <div
                    style={{
                      fontFamily: selectedFont,
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                    className="w-full sm:w-1/4 text-center sm:text-center"
                  >
                    IGST(%)
                  </div>
                </>
              ) : (<>
                <div
                  style={{
                    fontFamily: selectedFont,
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                  className={"w-full sm:w-1/4 text-center sm:text-center"}
                >
                  CGST(%)
                </div>
                <div
                  style={{
                    fontFamily: selectedFont,
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                  className={"w-full sm:w-1/4 text-center sm:text-center"}
                >
                  <span className="inline-block">SGST(%)</span>
                </div>
              </>)}
              <div
                style={{
                  fontFamily: selectedFont,
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className=" w-full sm:w-1/4 text-right 2xl:text-right   "
              >
                Total
              </div>
            </div>
          </div>
          {Invoice?.product?.map((product: any, index: number) => {
    
            return (
              <div
                key={`${index}_${product.id}`}
                className="flex lg:flex-row flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible md:flex-row  xl:flex-row flex-col rounded-lg w-full  2xl:px-6 xl:px-6  md:px-7 px-4  py-1 items-center relative text-sm"
              >
                <div
                  style={{
                    fontFamily: selectedFont,
                    fontWeight: 700,
                  }}
                  className=" w-full sm:w-1/4 md:w-1/12 lg:w-1/12 xl:w-1/12 2xl:w-1/12 text-right sm: text-center flex flex-row sm:block"
                >
                  <span
                    style={{
                      marginBottom: "5px",
                    }}
                    className="sm:hidden w-1/2 flex flex-row  items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  >
                    S No
                  </span>
  
  
  
                  <span className="inline-block w-full  product   mb-1 sm:mb-0   2xl:px-3  lg:px-3 md:px-3">
                    <input
                      readOnly
                      value={index + 1}
                      style={{
                        fontFamily: selectedFont,
                        fontWeight: 400,
                      }}
                      className="font-title  block w-full 2xl:text-left xl:ml-2  md:ml-2 xl:text-left  lg:text-left  md:text-left  sm:text-left  sm:ml-7  text-right focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    />
                  </span>
  
                </div>
                <div
                  style={{
                    fontFamily: selectedFont,
                    fontWeight: 700,
                  }}
                  className=" w-full sm:w-1/4 md:w-8/12 lg:w-8/12 xl:w-6/12 2xl:w-6/12  lg:pl-4 xl:pl-0 text-right md:text-left xl:text-left 2xl:text-left flex flex-row sm:block"
                >
                  <span
                    style={{
                      marginBottom: "5px",
                    }}
                    className="sm:hidden w-1/2 flex flex-row  items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  >
                    Product
                  </span>
                  <span className="inline-block w-full  product   mb-1 sm:mb-0   2xl:px-3  lg:px-3 md:px-3">
                    <input
                      readOnly
                      value={product.prodcut}
                      style={{
                        fontFamily: selectedFont,
                        fontWeight: 400,
                      }}
                      className="font-title  block w-full 2xl:text-left xl:ml-8  md:ml-8 lg:ml-4  xl:text-left  lg:text-left  md:text-left  sm:text-left  sm:ml-7  text-right focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    />
                  </span>
                </div>
  
                <div
                  style={{
                    fontFamily: selectedFont,
                    fontWeight: 700,
                  }}
                  className=" w-full  sm:w-1/4   text-right"
                >
                  <span
                    style={{
                      marginBottom: "5px",
                    }}
                    className="sm:hidden w-full flex flex-row  items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  >
                    HsnNo
                  </span>
                  <span className="inline-block w-full  sm:w-full mb-1 sm:mb-0 ">
                    <input
                      readOnly
                      style={{
                        fontFamily: selectedFont,
                        fontWeight: 400,
                      }}
                      value={product.hsn ? product.hsn : "N/A"}
                      className="font-title  block w-full xl:w-10   2xl:ml-8 xl:ml-8 md:ml-4  2xl:text-left  xl:text-left  lg:text-left  md:text-left    sm:text-left  sm:ml-7    text-right focus:outline-none text-xs sm:text-sm  lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                    />
                  </span>
                </div>
  
                <div
                  style={{
                    fontFamily: selectedFont,
                    fontWeight: 700,
                  }}
                  className=" w-full sm:w-1/4 text-left   flex flex-row sm:block "
                >
                  <span
                    style={{
                      marginBottom: "5px",
                    }}
                    className="sm:hidden w-full flex flex-row items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  >
                    Price
                  </span>
                  <span className="inline-block  w-full   mb-1 sm:mb-0 ">
                    <input
                      style={{
                        fontFamily: selectedFont,
                        marginTop: "",
                        fontWeight: 400,
                      }}
                      readOnly
                      value={product?.rate}
                      type={"number"}
                      className="font-title  block w-full 2xl:text-center 2xl:ml-2 xl:ml-12 md:ml-3  xl:text-left  lg:text-left  md:text-left  sm:text-left  sm:ml-7  text-right focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    />
                  </span>
                </div>
  
  
                <div
                  style={{
                    fontFamily: selectedFont,
                    fontWeight: 700,
                  }}
                  className=" w-full sm:w-1/4 text-right flex flex-row sm:block   "
                >
                  <span
                    style={{
                      marginBottom: "5px",
                    }}
                    className="sm:hidden w-1/4 flex flex-row items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  >
                    Qty
                  </span>
                  <span className="inline-block  w-full mb-1 sm:mb-0">
                    <input
                      readOnly
                      value={product.qty}
                      type={"number"}
                      style={{
                        fontFamily: selectedFont,
                        marginTop: "",
                        fontWeight: 400,
                      }}
                      className="font-title text-right block w-full 2xl:text-center  xl:text-center xl:pl-12 2xl:pl-0 lg:text-center md:text-center  md:mr-3  sm:text-center sm:mr-10  focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    />
                  </span>
                </div>
  
                <div
                  style={{
                    fontFamily: selectedFont,
                    fontWeight: 700,
                  }}
                  className=" w-full sm:w-1/4 text-right flex flex-row sm:block   "
                >
                  <span
                    style={{
                      marginBottom: "5px",
                    }}
                    className="sm:hidden w-1/4 flex flex-row items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  >
                    Disc(%)
                  </span>
                  <span className="inline-block  w-full mb-1 sm:mb-0">
                    <input
                      readOnly
                      value={product.discount}
                      type={"number"}
                      style={{
                        fontFamily: selectedFont,
                        marginTop: "",
                        fontWeight: 400,
                      }}
                      className="font-title text-right block w-full 2xl:text-center    xl:text-center xl:pl-4        lg:text-center md:text-center  md:mr-3  sm:text-center sm:mr-10  focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    />
                  </span>
                </div>
  
                {userdata?.gstValue == "IGST" ? (
                  <>
                    <div
                      style={{
                        fontFamily: selectedFont,
                        fontWeight: 700,
                      }}
                      className={"  w-full sm:w-1/4 text-right flex flex-row sm:block"}
                    >
                      <span
                        style={{
                          marginBottom: "5px",
                        }}
                        className="sm:hidden w-1/4 flex flex-row items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      >
                        IGST(%)
                      </span>
                      <span className="inline-block  w-full mb-1 sm:mb-0">
                        <input
                          readOnly
                          value={product.prodigst}
                          type={"number"}
                          style={{
                            fontFamily: selectedFont,
                            marginTop: "",
                            fontWeight: 400,
                          }}
                          className="font-title text-right block w-full 2xl:text-center xl:text-center xl:pl-4 lg:text-center md:text-center  md:mr-3  sm:text-center sm:mr-10  focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                        />
                      </span>
                    </div>
                  </>
                ) : (<>
                  <div
                    style={{
                      fontFamily: selectedFont,
                      fontWeight: 700,
                    }}
                    className={"w-full sm:w-1/4 text-right flex flex-row sm:block"}
                  >
                    <span
                      style={{
                        marginBottom: "5px",
                      }}
                      className="sm:hidden w-1/4 flex flex-row items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    >
                      CGST(%)
                    </span>
                    <span className="inline-block  w-full mb-1 sm:mb-0">
                      <input
                        readOnly
                        value={product.prodcgst}
                        type={"number"}
                        style={{
                          fontFamily: selectedFont,
                          marginTop: "",
                          fontWeight: 400,
                        }}
                        className={`${"font-title text-right block w-full 2xl:text-center xl:text-center xl:pl-4 lg:text-center md:text-center  md:mr-3  sm:text-center sm:mr-10  focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"}`}
                      />
                    </span>
                  </div>
                  <div
                    style={{
                      fontFamily: selectedFont,
                      fontWeight: 700,
                    }}
                    className={"w-full sm:w-1/4 text-right flex flex-row sm:block"}
                  >
                    <span className="sm:hidden w-1/4 flex flex-row items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md">
                      SGST(%)
                    </span>
                    <span className="inline-block  w-full mb-1 sm:mb-0">
                      <input
                        readOnly
                        value={product.prodsgst}
                        type={"number"}
                        style={{
                          fontFamily: selectedFont,
                          marginTop: "",
                          fontWeight: 400,
                        }}
                        className="font-title text-right block w-full 2xl:text-center xl:text-center xl:pl-4 lg:text-center md:text-center  md:mr-3  sm:text-center sm:mr-10  focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      />
                    </span>
                  </div>
                </>)}
  
  
                <>
                  <div
                    style={{
                      fontFamily: selectedFont,
                      fontWeight: 700,
                    }}
                    className=" w-full sm:w-1/4 text-right  flex flex-row sm:block"
                  >
                    <span
                      style={{
                        marginBottom: "5px",
                      }}
                      className="sm:hidden w-1/2  flex flex-row items-center text-xs  lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    >
                      Total
                    </span>
                    <span className="font-block w-full sm:w-full  md:w-full text-xs  sm:text-sm lg:w-full lg:pr-4 md:pr-4  sm:pr-7  lg:pr-4  xl:pr-5 md:whitespace-nowrap md:text-xs">
                      <NumberFormat value={product.amount}></NumberFormat>
                    </span>
                  </div>
                </>
              </div>
            );
          })}
  
          {/********************************** * subtotal *******************************/}
          <div className="flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-4 py-1 items-center ">
            <div
              style={{
                fontFamily: selectedFont,
                fontSize: "14px",
                fontWeight: 700,
              }}
              className="font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
            >
              Subtotal
            </div>
  
            <div
              style={{
                fontFamily: selectedFont,
                fontSize: "14px",
                fontWeight: 700,
              }}
              className="font-block w-1/2 sm:w-1/4 text-right sm:pr-7 flex flex-row justify-end sm:block mb-1"
            >
              <NumberFormat
                value={Invoice.subtotal}
  
              //   displayType={"text"}
              //   thousandSeparator={true}
              />
            </div>
          </div>
          {/* ******************************cgstPercentage**************/}
  
          {Invoice?.tax?.map((tax: any, index: number) => {
            return (
              <div key={index}>
                {/* Render each tax item with specific details */}
                <div className="flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-4 py-1 items-center">
                  <div
                    style={{
                      fontFamily: selectedFont,
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                    className="font-title w-[60%] sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
                  >
                    {tax.taxName}
                    {tax.taxName !== "Extra Fees" ? (
                      <span className="ml-2">@</span>
                    ) : (
                      ""
                    )}
                    {tax.taxName !== "Extra Fees" ? tax.taxPersent : ""}
                    {tax.taxName !== "Extra Fees" ? <span>%</span> : ""}
                  </div>
  
                  <div
                    style={{
                      fontFamily: selectedFont,
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                    className="font-title w-1/2 sm:w-1/4 text-right sm:pr-7 flex flex-row justify-end sm:block mb-1"
                  >
                    <NumberFormat value={tax.taxAmount} />
                  </div>
                </div>
  
                {/* Similarly render other tax details */}
                {/* Example: SCGST, IGST, vat, OTHERS, extrafees, DISCOUNT */}
              </div>
            );
          })}
          {/*************************total****************************/}
          <div className="flex flex-row sm:flex-row sm:justify-end  rounded-lg sm:visible w-full px-4 py-1 items-center ">
            <div
              style={{
                fontFamily: selectedFont,
                fontSize: "14px",
                fontWeight: 700,
              }}
              className="font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
            >
              Total
            </div>
  
            <div
              style={{
                fontFamily: selectedFont,
                fontSize: "14px",
                fontWeight: 700,
              }}
              className="font-title w-1/2 sm:w-1/4 text-right sm:pr-7 flex flex-row justify-end sm:block mb-1"
            >
              <NumberFormat
                value={Invoice.toatalAmount}
              />
            </div>
          </div>
          {/*************************AMOUNT IN WORDS****************************/}
          <div className="flex flex-row sm:flex-row sm:justify-start rounded-lg sm:visible w-full  sm:px-4 py-1 items-center ">
            <div
              style={{
                fontFamily: selectedFont,
                fontSize: "14px",
                fontWeight: 700,
              }}
              className="font-title w-1/2 sm:w-1/4 text-left pl-4 md:pl-5 lg:pl-4 flex flex-row sm:block mb-1 sm:mb-0 whitespace-nowrap capitalize"
            >
              {totalAmountInWords} only.
            </div>
          </div>
 
        
          {/********************************Cancel*********************************/}
          <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
              // onClick={() => setOpenView(!viewOpen)}
              // ref={cancelButtonRef}
              onClick={handleCancelClick}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
}

export default PreviewPurchaseProductwise
