import api from "../axiosConfig";
import {
   REGISTER_GET_FAIL,
   REGISTER_GET_REQUEST,
   REGISTER_GET_SUCCESS,
   REGISTER_UPDATE_FAIL,
   REGISTER_UPDATE_REQUEST,
   REGISTER_UPDATE_SUCCESS,
   ROLL_GET_FAIL,
   ROLL_GET_REQUEST,
   ROLL_GET_SUCCESS,
   USERS_GET_FAIL,
   USERS_GET_REQUEST,
   USERS_GET_SUCCESS,
   USERS_PAGES_POST_FAIL,
   USERS_PAGES_POST_REQUEST,
   USERS_PAGES_POST_SUCCESS,
   USER_CHANGE_PASSWORD_FAIL,
   USER_CHANGE_PASSWORD_REQUEST,
   USER_CHANGE_PASSWORD_SUCCESS,
   USER_CODE_FAIL,
   USER_CODE_REQUEST,
   USER_CODE_SUCCESS,
   USER_DELETE_REQUEST,
   USER_DELETE_RESET,
   USER_DELETE_SUCCESS,
   USER_NEW_PASSWORD_FAIL,
   USER_NEW_PASSWORD_REQUEST,
   USER_NEW_PASSWORD_SUCCESS,
   USER_REGISTER_FAIL,
   USER_REGISTER_REQUEST,
   USER_REGISTER_SUCCESS,
   USER_SIGNIN_FAIL,
   USER_SIGNIN_REQUEST,
   USER_SIGNIN_SUCCESS,
   USER_UPDATE_FAIL,
   USER_UPDATE_REQUEST,
   USER_UPDATE_SUCCESS,
} from "../constants/userConstants";

export const signin = (signInfo: any) => async (dispatch: any) => {
   dispatch({ type: USER_SIGNIN_REQUEST });
   try {
      const { data } = await api.post(`/api/login`, signInfo);
      sessionStorage.setItem("userInfo", JSON.stringify(data));
      return dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
   } catch (error: any) {
      return dispatch({
         type: USER_SIGNIN_FAIL,
         payload:
            error.response && error.response.data.message
               ? error.response.data.message
               : error.message,
      });
   }
};

export const UsersGet = () => async (dispatch: any, getState: any) => {
   dispatch({ type: USERS_GET_REQUEST });
   try {
      const {
         userSignin: {
            userInfo: { token },
         },
      } = getState();
      const { data } = await api.get(`/api/users/findAll/`, {
         headers: { Authorization: `Bearer ${token?.tocken}` },
      });
      return dispatch({ type: USERS_GET_SUCCESS, payload: data });
   } catch (error: any) {
      dispatch({
         type: USERS_GET_FAIL,
         payload:
            error.response && error.response.data.message
               ? error.response.data.message
               : error.message,
      });
   }
};

export const registers = (regInfo: any) => async (dispatch: any) => {
   dispatch({ type: USER_REGISTER_REQUEST });
   try {
      const { data } = await api.post(`/api/generate-user`, regInfo);
      dispatch({ type: USER_REGISTER_SUCCESS, payload: data });
      return dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
   } catch (error: any) {
      return dispatch({
         type: USER_REGISTER_FAIL,
         payload:
            error.response && error.response.data.message
               ? error.response.data.message
               : error.message,
      });
   }
};

export const userCode = (codeInfo: any) => async (dispatch: any) => {
   dispatch({ type: USER_CODE_REQUEST });
   try {
      const { data } = await api.post(`/api/usercode`, codeInfo);
      
     return dispatch({ type: USER_CODE_SUCCESS, payload: data });
   } catch (error: any) {
      return dispatch({
         type: USER_CODE_FAIL,
         payload:
            error.response && error.response.data.message
               ? error.response.data.message
               : error.message,
      });
   }
};

export const registerAll = (id: any) => async (dispatch: any, getState: any) => {
   dispatch({ type: REGISTER_GET_REQUEST });
   try {
      const { data } = await api.get(`/api/getRegisterid/${id}`);
      return dispatch({ type: REGISTER_GET_SUCCESS, payload: data });
   } catch (error: any) {
      dispatch({
         type: REGISTER_GET_FAIL,
         payload:
            error.response && error.response.data.message
               ? error.response.data.message
               : error.message,
      });
   }
};

export const updateRegister = (id: any, update: any) => async (dispatch: any, getState: any) => {

   dispatch({ type: REGISTER_UPDATE_REQUEST });
   try {
      const { data } = await api.put(`/api/users/update/${id}`, update);
      return dispatch({ type: REGISTER_UPDATE_SUCCESS, payload: data });
   } catch (error: any) {
      dispatch({
         type: REGISTER_UPDATE_FAIL,
         payload:
            error.response && error.response.data.message
               ? error.response.data.message
               : error.message,
      });
   }
};

export const newchangePassword = (regInfo: any) => async (dispatch: any) => {
   dispatch({ type: USER_NEW_PASSWORD_REQUEST });
   try {
      const { data } = await api.post(`/api/change-password`, regInfo);
      dispatch({ type: USER_NEW_PASSWORD_SUCCESS, payload: data });
      return dispatch({ type: USER_NEW_PASSWORD_SUCCESS, payload: data });
   } catch (error: any) {
      return dispatch({
         type: USER_NEW_PASSWORD_FAIL,
         payload:
            error.response && error.response.data.message
               ? error.response.data.message
               : error.message,
      });
   }
};

export const changePassword = (password: any) => async (dispatch: any) => {
   dispatch({ type: USER_CHANGE_PASSWORD_REQUEST });
   try {
      const { data } = await api.post(`/api/change-password`, password);
      dispatch({ type: USER_CHANGE_PASSWORD_SUCCESS, payload: data });
      return dispatch({ type: USER_CHANGE_PASSWORD_SUCCESS, payload: data });
   } catch (error: any) {
      dispatch({
         type: USER_CHANGE_PASSWORD_FAIL,
         payload:
            error.response && error.response.data.message
               ? error.response.data.message
               : error.message,
      });
   }
};

export const usersPagesInvoice = (usersPage: any) => async (dispatch: any) => {
   dispatch({ type: USERS_PAGES_POST_REQUEST });
   try {
      const { data } = await api.post(`/api/userspages/save`, usersPage);
      return dispatch({ type: USERS_PAGES_POST_SUCCESS, payload: data });
   } catch (error: any) {
      dispatch({
         type: USERS_PAGES_POST_FAIL,
         payload:
            error.response && error.response.data.message
               ? error.response.data.message
               : error.message,
      });
   }
};




export const updateUser = (id: any, update: any) => async (dispatch: any, getState: any) => {

   dispatch({ type: USER_UPDATE_REQUEST });
   try {
      const { data } = await api.put(`/api/user/updateOne/${id}`, update);
      return dispatch({ type: USER_UPDATE_SUCCESS, payload: data });
   } catch (error: any) {
      dispatch({
         type: USER_UPDATE_FAIL,
         payload:
            error.response && error.response.data.message
               ? error.response.data.message
               : error.message,
      });
   }
};

export const userRolerAll = () => async (dispatch: any, getState: any) => {
   dispatch({ type: ROLL_GET_REQUEST });
   try {
      ;
      const { data } = await api.get(`/api/roleall`);
      return dispatch({ type: ROLL_GET_SUCCESS, payload: data });
   } catch (error: any) {
      dispatch({
         type: ROLL_GET_FAIL,
         payload:
            error.response && error.response.data.message
               ? error.response.data.message
               : error.message,
      });
   }
};

export const userDelete = (id: any) => async (dispatch: any, getState: any) => {
   dispatch({ type: USER_DELETE_REQUEST });
   try {
     const {
       userSignin: {
         userInfo: { token },
       },
     } = getState();
     const { data } = await api.delete(`/api/user/delete/${id}`, {
       headers: { Authorization: `Bearer ${token?.tocken}` },
     });
     return dispatch({ type: USER_DELETE_SUCCESS, payload: data });
 
   } catch (error: any) {
     dispatch({
       type: USER_DELETE_RESET,
       payload:
         error.response && error.response.data.message
           ? error.response.data.message
           : error.message,
     });
   }
 };

// export const updatebillModule = (id: any, update: any) => async (dispatch: any, getState: any) => {

//    dispatch({ type: USER_UPDATE_REQUEST });
//    try {
//       const { data } = await api.put(`/api/user/updateOne/${id}`, update);
//       return dispatch({ type: USER_UPDATE_SUCCESS, payload: data });
//    } catch (error: any) {
//       dispatch({
//          type: USER_UPDATE_FAIL,
//          payload:
//             error.response && error.response.data.message
//                ? error.response.data.message
//                : error.message,
//       });
//    }
// };



