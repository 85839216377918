import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { upiPaymentAll } from "../../redux/actions/upiPaymentAction";

function UpiModel({ closeModal, handleSelect }: any) {
  const [animate, setAnimate] = useState(true);
  const dispatch = useDispatch();
  const usersignin = useSelector((state: any) => state.userSignin);
  const {
    userInfo: { token },
  } = usersignin;

  const [upiList, setUpiList] = useState([] as any);

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#F1F7F6",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#F1F1F1",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const fetchData = () => {
    dispatch(upiPaymentAll() as any)
      .then((res: any) => {
        if (res) {
          setUpiList(res.payload);
        }
      })
      .catch((error: any) => {
        console.error("Error fetching banks:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <motion.div
      className="modal-container"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: animate ? 1 : 0,
      }}
      transition={{
        type: "spring",
        damping: 30,
        duration: 0.2,
      }}
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75">
        <motion.div className="relative rounded-lg overflow-hidden text-center bg-[white] shadow-xl transform transition-all my-8 w-11/12 mx-auto">
          <TableContainer
            sx={{ height: "80vh", overflowX: "auto", scrollbarWidth: "thin" }}
          >
            <Table stickyHeader aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{ fontSize: "14px", fontWeight: 600, color: "#008065" }}
                  >
                    S No
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontSize: "14px", fontWeight: 600, color: "#008065" }}
                  >
                    UPI PAY
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontSize: "14px", fontWeight: 600, color: "#008065" }}
                  >
                    UPI NUMBER
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontSize: "14px", fontWeight: 600, color: "#008065" }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {upiList.map((item: any, rowIndex: any) => (
                  <StyledTableRow key={rowIndex}>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "14px", fontWeight: 600 }}
                    >
                      {rowIndex + 1}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "14px", fontWeight: 600 }}
                    >
                      {item.upi_pay}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "14px", fontWeight: 600 }}
                    >
                      {item.upi_number}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontSize: "14px", fontWeight: 600 }}
                    >
                      <button
                        onClick={() => handleSelect(item)}
                        className="text-white  bg-[#00A787]  px-2 py-1 rounded-md"
                      >
                        Select
                      </button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="bg-[white] px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={closeModal}
            >
              Cancel
            </button>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
}

export default UpiModel;
