import { Box, IconButton } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

interface props {
  companyList: any;
  handleEditSection: any;
  handleDeleteSection: any;
}
const CompantTable = ({
  companyList,
  handleEditSection,
  handleDeleteSection,
}: props) => {
  const [companyLists, setCompanyLists] = useState([] as any);

  useEffect(() => {
    setCompanyLists(companyList);
  }, [companyList]);

  const rows = companyLists.map((company: any, index: any) => ({
    id: uuidv4(),
    sno: index + 1,
    ...company,
  }));

  const columns: GridColDef[] = [
    { field: "sno", headerName: "S No", width: 120 },
    {
      field: "companyimg",
      headerName: "Company Logo",
      flex: 2,
      minWidth: 190,
      renderCell: (params) => (
        <>
          {params?.row?.companyimg ? (
            <>
              <div className="flex items-center">
                <div className="relative inline-block shrink-0 rounded-2xl ">
                  <img
                    src={params.row.companyimg}
                    className="w-[40px] h-[40px] inline-block shrink-0 rounded-2xl "
                    alt="/#"
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex items-center">
                <span className="w-[40px] h-[40px] inline-block shrink-0 rounded-2xl bg-[#008065] text-center px-3.5 py-2 flex text-lg text-white">
                  {params?.row?.company_name
                    ? params.row.company_name.charAt(0).toUpperCase()
                    : ""}
                </span>
              </div>
            </>
          )}
        </>
      ),
    },
    {
      field: "company_name",
      headerName: "Company Name",
      flex: 2,
      minWidth: 190,
    },
    {
      field: "company_address",
      headerName: "Company Address",
      flex: 2,
      minWidth: 190,
    },
    { field: "city", headerName: "City", flex: 2, minWidth: 190 },
    { field: "state", headerName: "State", flex: 2, minWidth: 190 },
    { field: "postal_code", headerName: "Postal Code", flex: 2, minWidth: 190 },
    {
      field: "company_mobile",
      headerName: "Company Mobile",
      flex: 2,
      minWidth: 190,
    },
    {
      field: "company_email",
      headerName: "Company Email",
      flex: 2,
      minWidth: 190,
    },
    { field: "pan_no", headerName: "Pan No", flex: 2, minWidth: 190 },
    { field: "gst", headerName: "GST", flex: 2, minWidth: 190 },
    {
      field: "action",
      headerName: "Action",
      editable: false,
      flex: 2,
      minWidth: 190,
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={() => handleEditSection(params.row)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#008065"
              className="w-5 h-5 cursor-pointer"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
              />
            </svg>
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() => {
              handleDeleteSection(params.row);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#008065"
              className="w-5 h-5  cursor-pointer"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
              />
            </svg>
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <div
      className="bg-[#F1F7F6] p-[8px] m-[5px]"
      style={{ borderRadius: "8px" }}
    >
      <Box sx={{ height: "auto", width: "100%" }}>
        <DataGrid
          sx={{
            ".MuiDataGrid-columnHeaderTitle.css-t89xny-MuiDataGrid-columnHeaderTitle":
              { color: "rgb(0, 128, 101)", fontWeight: 600, fontSize: "14px" },
            ".MuiDataGrid-scrollbar.MuiDataGrid-scrollbar--vertical.css-1b9e9gy":
              { overflowY: "hidden" },
            ".MuiDataGrid-footerContainer.MuiDataGrid-withBorderColor.css-wop1k0-MuiDataGrid-footerContainer":
              {
                display: "none",
              },
            ".MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
              fontWeight: 600,
              fontSize: "14px",
            },
            "& .MuiDataGrid-checkboxInput": {
                color: "#008060 !important",
              },
          }}
          rows={rows}
          columns={columns}
          pageSizeOptions={[]}
          disableRowSelectionOnClick
        />
      </Box>
    </div>
  );
};

export default CompantTable;
