import React from 'react'

const AccountSvg = () => {
    return (
        <div>
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="40" height="40" viewBox="0 0 263.000000 233.000000"
                preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,233.000000) scale(0.100000,-0.100000)"
                    fill="#f472b6" stroke="none">
                    <path d="M1940 1941 c-5 -11 -10 -40 -10 -65 0 -45 0 -46 -33 -46 -18 0 -73
14 -123 30 -126 43 -186 58 -290 70 -126 15 -204 2 -321 -55 l-92 -45 -83 6
c-186 14 -339 -70 -418 -229 -61 -124 -61 -235 -1 -363 38 -82 109 -152 196
-195 66 -32 75 -34 180 -34 105 0 114 2 178 33 117 58 214 178 232 288 14 87
12 84 58 84 87 0 214 -22 297 -51 47 -16 115 -33 153 -36 l67 -6 0 -52 c0 -33
5 -57 14 -64 9 -8 63 -11 167 -9 l154 3 3 364 c2 260 -1 368 -9 377 -9 11 -47
14 -160 14 -136 0 -149 -2 -159 -19z m260 -366 l0 -305 -95 0 -95 0 0 305 0
305 95 0 95 0 0 -305z m-580 252 c47 -13 119 -34 160 -45 41 -12 92 -24 113
-27 l37 -6 0 -171 0 -171 -72 5 c-44 3 -93 15 -123 28 -68 30 -198 50 -330 50
-119 0 -183 11 -196 32 -5 7 -9 24 -9 38 0 53 7 55 187 60 l168 5 0 30 c0 38
-18 42 -173 44 l-114 1 -49 38 c-26 20 -48 42 -49 48 0 10 89 55 135 68 47 12
232 -3 315 -27z m-555 -84 c22 -7 57 -25 77 -40 l38 -25 -25 -30 c-74 -88 -10
-228 106 -228 31 0 32 0 26 -41 -8 -61 -62 -162 -108 -204 -69 -62 -141 -89
-234 -89 -65 0 -90 4 -136 26 -79 36 -133 89 -171 165 -28 58 -32 75 -32 147
0 66 5 91 26 138 76 165 260 242 433 181z"/>
                    <path d="M2084 1526 c-14 -11 -19 -30 -22 -88 -4 -83 9 -128 36 -128 34 0 42
21 42 117 0 80 -3 94 -19 102 -13 7 -24 6 -37 -3z"/>
                    <path d="M922 1718 c-7 -7 -12 -24 -12 -38 0 -18 -9 -31 -31 -44 -62 -36 -83
-103 -55 -171 17 -40 50 -64 108 -77 54 -12 68 -24 68 -59 0 -63 -61 -78 -109
-26 -25 26 -34 30 -48 21 -34 -21 -28 -53 20 -98 25 -23 47 -52 49 -65 7 -53
68 -49 68 4 0 14 13 33 35 49 62 47 79 104 51 170 -15 37 -71 76 -107 76 -14
0 -35 7 -47 16 -32 22 -27 73 9 93 28 16 27 16 95 -34 10 -7 20 -6 32 1 31 20
25 44 -22 88 -25 23 -46 51 -46 62 0 22 -18 44 -35 44 -6 0 -16 -5 -23 -12z"/>
                    <path d="M132 1190 l-22 -9 2 -368 3 -368 154 -3 c104 -2 158 1 167 9 8 7 14
31 14 60 l0 49 36 0 c19 0 83 -16 140 -35 137 -45 245 -65 353 -65 113 0 156
14 331 106 80 42 206 107 281 144 158 80 189 108 189 176 0 56 -22 99 -63 124
-58 36 -93 27 -277 -65 -93 -47 -174 -85 -179 -85 -5 0 -11 10 -15 23 -3 12
-19 36 -36 52 -31 29 -31 29 -193 37 -209 9 -246 14 -342 50 -44 16 -111 33
-150 36 l-70 7 -3 52 c-4 77 -17 83 -172 82 -69 0 -135 -4 -148 -9z m238 -375
l0 -306 -92 3 -93 3 -3 290 c-1 159 0 295 3 302 3 9 31 13 95 13 l90 0 0 -305z
m280 135 c106 -36 208 -50 355 -50 63 0 125 -4 139 -10 33 -12 44 -54 25 -89
-15 -26 -15 -26 -179 -31 l-165 -5 0 -30 0 -30 145 -8 c163 -9 202 -2 253 43
50 44 414 222 438 215 11 -4 27 -17 35 -31 13 -20 14 -29 5 -52 -13 -30 -19
-34 -416 -235 l-200 -102 -110 1 c-113 1 -239 26 -360 73 -27 10 -76 22 -107
26 l-58 7 0 167 c0 92 3 171 7 175 13 12 103 -4 193 -34z"/>
                    <path d="M257 1077 c-15 -10 -17 -27 -15 -106 3 -98 15 -122 52 -108 24 9 24
212 0 221 -20 8 -17 8 -37 -7z"/>
                </g>
            </svg>
        </div>
    )
}

export default AccountSvg
