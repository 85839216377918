/* eslint-disable eqeqeq */
import { Box, Checkbox } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { ProductsGet } from "../../redux/actions/productAction";
import { stockPurchaseGet } from "../../redux/actions/stockAction";
import { VendorProductAll } from "../../redux/actions/vendorAction";

function ProductModel({
  isOpen,
  closeModal,
  setProductRows,
  setStockList,
  handleSelect,
  prodcutRows,
}: any) {
  const dispatch = useDispatch();
  const [existingProductList, setExistingProductList] = useState([] as any);
  const [selectedProducts, setSelectedProducts] = useState([] as any);
  const usersignin = useSelector((state: any) => state.userSignin);
  const {
    userInfo: { token },
  } = usersignin;

  const productDetails = useSelector((state: any) => state.userProductget);
  const { productlist } = productDetails;

  const productdetails = useSelector((state: any) => state.stockList);
  const { stocklist } = productdetails;

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      
      const newSelectedProducts = existingProductList
        .filter(
          (item: any) =>
            item.qty &&
            !prodcutRows.some(
              (value: any) => value.prodcut === item.productName
            )
        )
        .map((item: any) => item.productName);
      setSelectedProducts(newSelectedProducts);
    } else {
      setSelectedProducts([]);
    }
  };

  const handleSelectProduct = (productName: string) => {
    setSelectedProducts((prevSelectedProducts: any) =>
      prevSelectedProducts.includes(productName)
        ? prevSelectedProducts.filter((name: string) => name !== productName)
        : [...prevSelectedProducts, productName]
    );
  };

  const handleOk = () => {
    const selectedProductsData = selectedProducts.map((productName: string) =>
      existingProductList.find((item: any) => item.productName === productName)
    );
    handleSelect(selectedProductsData);
    closeModal();
  };

  const handleCancelClick = () => {
    closeModal();
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();

    // let tempArr=[];

    // for (let i = 0; i < stocklist.length; i++) {
    //   for (let j = 0; j < productlist.length; j++) {
    //     if (
    //       stocklist[i].productName ===
    //       productlist[j].product_name &&
    //       stocklist[i].productQuantity != 0
    //     ) {
    //       tempArr.push({
    //         ...stocklist[i],
    //         ...productlist[j],
    //       });
    //     }
    //   }
    // }

    const tempArr = stocklist?.reduce((acc:any, stockItem:any) => {
      const matchingProduct = productlist?.find(
        (product:any) => product.product_name === stockItem.productName
      );
    
      if (matchingProduct && stockItem.productQuantity !== 0) {
        acc?.push({ ...stockItem, ...matchingProduct });
      }
    
      return acc;
    }, []);

     const filteredData = tempArr?.filter((product: any) => {
      return product?.productName?.toLowerCase().includes(query);
    });

    setExistingProductList(filteredData);
  };

  const fetchData = () => {
    dispatch(ProductsGet(token?._id) as any)
      .then((productRes: any) => {
        if (productRes.type === "PRODUCT_GET_SUCCESS") {
          dispatch(stockPurchaseGet(token?._id) as any).then((stockRes: any) => {
            setStockList(stockRes.payload)
            if (stockRes.type === "STOCK_PURCHASE_GET_SUCCESS") {
             
              let tempArr = [];
              for (let i = 0; i < stockRes.payload.length; i++) {
                for (let j = 0; j < productRes.payload.length; j++) {
                  if (
                    stockRes.payload[i].productName ===
                      productRes.payload[j].product_name &&
                    stockRes.payload[i].qty != 0
                  ) {
                    tempArr.push({
                      ...stockRes.payload[i],
                      ...productRes.payload[j],
                    });
                  }
                }
              }
              setExistingProductList(tempArr);
            }
          });
        }
      })
      .catch((error: any) => {
        console.error("Error fetching products:", error);
      });
  };
  const fetchDatas = () =>{
    dispatch(VendorProductAll(token._id)as any).then((res:any)=>{

    })
  }

  useEffect(() => {
    fetchData();
    fetchDatas();
  }, []);

  const rows = existingProductList.map((product: any, index: any) => ({
    id: uuidv4(),
    sno: index + 1,
    ...product,
  }));

  const columns: GridColDef[] = [
    {
      field: "select",
      headerName: "",
      width: 80,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Checkbox
          indeterminate={
            selectedProducts.length > 0 && selectedProducts.length < rows.length
          }
          checked={selectedProducts.length === rows.length}
          onChange={(event) => handleSelectAll(event)}
        />
      ),
      renderCell: (params) => (
        <Checkbox
          onChange={() => handleSelectProduct(params.row.product_name)}
          checked={
            selectedProducts.includes(params.row.product_name) &&
            !prodcutRows.some(
              (value: any) => value.product_name === params.row.product_name
            )
          }
          disabled={
            !params.row.qty    ||
            prodcutRows?.some(
              (value: any) => value.productName === params.row.productName
            )
          }
        />
      ),
    },
    { field: "sno", headerName: "S No", width: 120 },
    {
      field: "product_name",
      headerName: "Product Name",
      flex: 2,
      minWidth: 190,
    },
    {
      field: "price",
      headerName: "Rate",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "qty",
      headerName: "Quantity",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "units",
      headerName: "Units",
      flex: 2,
      minWidth: 150,
    },
  ];

  return (
    <div>
      <div className="bg-[#F1F7F6] rounded-xl px-3 py-3 mx-2">
        <motion.div
          className="modal-container"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          transition={{
            type: "spring",
            damping: 18,
          }}
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75">
            <motion.div className="relative rounded-lg overflow-hidden bg-[white] text-center shadow-xl transform transition-all my-8 w-11/12 mx-auto">
              <div
                className="bg-[#F1F7F6] p-[8px] m-[5px]"
                style={{ borderRadius: "8px" }}
              >
                <div className="flex justify-between flex-col lg:flex-row xl:flex-row 2xl:flex-row md:flex-row">
                  <div className="flex items-center">
                    <div className="relative flex mb-2">
                      <input
                        onChange={(e) => onSearch(e)}
                        type="text"
                        id="simple-search"
                        className="w-44  bg-gray-50 border border-[#008065] text-gray-900 text-sm rounded-lg p-2.5 pl-10 focus:outline-none"
                        placeholder="Search"
                        required
                      />
                      <span className="absolute inset-y-0 left-2 flex items-center pl-2 text-[#008065]">
                        <svg
                          className="w-4 h-4"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 20"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
                <Box sx={{ height: "45vh", width: "100%" }}>
                  <DataGrid
                    sx={{
                      ".MuiDataGrid-columnHeaderTitle.css-t89xny-MuiDataGrid-columnHeaderTitle":
                        {
                          color: "rgb(0, 128, 101)",
                          fontWeight: 600,
                          fontSize: "14px",
                        },
                      ".MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
                        fontWeight: 600,
                        fontSize: "14px",
                      },
                      ".css-1rtad1": {
                        display: "block !important",
                      },
                      ".css-j2otgh-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-j2otgh-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate":
                        {
                          color: " rgb(0, 128, 101) !important",
                        },
                    }}
                    rows={rows}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 5,
                        },
                      },
                    }}
                    pageSizeOptions={[5, 10, 25, 50]}
                    disableRowSelectionOnClick
                  />
                </Box>
              </div>
              <div className="bg-[white] px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-[white] text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleCancelClick}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#008065] text-base font-medium text-[white] hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleOk}
                >
                  OK
                </button>
              </div>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default ProductModel;
