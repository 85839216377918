import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { AppInvoiceSettingSave, InvoiceSettingDelete, invoiceSettingsGet, invoiceSettingsUpdate } from '../../../redux/actions/invoiceSettingsActions';
import InvoiceSettingsTable from '../../../Tables/InvoiceSettingsTable';
import ConfirmationPopup from '../../commen/ConfirmationPopup';
import moment from 'moment';
import { FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import { styled } from '@mui/system';

const InvoiceSettings = () => {
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            invoiceTitle: "",
            invoiceNumber: "",
            invoiceDate: "",
            invoiceYear: "",
            invoiceDigit: "00",
        },
    });
    const usersignin = useSelector((state: any) => state.userSignin);
    const token = usersignin?.userInfo?.token;
    const [invoiceDetail, setInvoiceDetail] = useState()
    const [defaultSettingInfo, setdefaultSettingInfo] = useState(false);
    const [selectedTittle, setSelectedTittle] = useState(null as any);
    const [tittleID, setTittleId] = useState(null as any);
    const [updateId, setUpdateId] = useState(null as any);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

    const handleDefaultCheckboxChange = (
        e: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setdefaultSettingInfo(e.target.checked);
    };

    const resetProduct = () => {
        const defaultValues = {
            invoiceTitle: "",
            invoiceNumber: "",
            invoiceDate: "",
            invoiceYear: "",
            invoiceDigit: "00",
        }
        setTittleId(null);
        setdefaultSettingInfo(false)
        setSelectedFormat('MM/DD/YYYY')
        reset({
            ...defaultValues,
        });
    }

    const onSubmit = (data: any) => {
        let invoiceSettingData = {
            invoiceTittle: data.invoiceTitle ? data.invoiceTitle : selectedTittle?.invoiceTitle,
            invoiceNumber: data.invoiceNumber ? data.invoiceNumber : selectedTittle?.invoiceNumber,
            invoiceDate: formattedDate,
            invoiceYear: currentYear,
            invoiceDigit: data.invoiceDigit,
            defaultInfo: defaultSettingInfo,
            user_id: token?._id,
        };
        if (tittleID) {
            dispatch(invoiceSettingsUpdate(tittleID, invoiceSettingData) as any).then((res: any) => {
                if (res) {
                    fetchData();
                    setTittleId(null);
                    setdefaultSettingInfo(false);
                    resetProduct();
                }
                toast.success("Update SuccessFully !", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    autoClose: 800,
                });
            });
        } else {
            dispatch(AppInvoiceSettingSave(invoiceSettingData) as any).then((res: any) => {
                if (res) {
                    fetchData();
                    resetProduct();
                    setdefaultSettingInfo(false);
                }
                toast.success("Saved SuccessFully !", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    autoClose: 800,
                });
            });
        }
    };



    const handleEdit = (value: any) => {
        let data = {
            invoiceTitle: value.invoiceTittle,
            invoiceNumber: value.invoiceNumber,
        }
        setFormattedDate(value.invoiceDate)
        setdefaultSettingInfo(value?.defaultInfo);
        setTittleId(value._id?.$oid);
        reset({
            ...data,
        });
    };
    const fetchData = () => {
        dispatch(invoiceSettingsGet(token?._id) as any).then((res: any) => {
            if (res) {
                setInvoiceDetail(res.payload);
            }
        });
    };
    useEffect(() => {
        fetchData();
    }, []);

    const handleDelete = (data: any) => {
        setShowConfirmation(true);
        setUpdateId(data._id?.$oid);
    };

    const handleConfirm = () => {
        dispatch(InvoiceSettingDelete(updateId) as any).then((res: any) => {
            if (res?.type === 'INVOICESETTING_DELETE_SUCCESS') {
                setShowConfirmation(false);
                reset();
                fetchData();
                setUpdateId(null);
            }
        });
    };

    const handleCancel = () => {
        setShowConfirmation(false);
    };


    const [selectedFormat, setSelectedFormat] = useState<string>('MM/DD/YYYY');
    const [formattedDate, setFormattedDate] = useState<string>('');
    useEffect(() => {
        const currentDate = new Date();
        let formattedDateValue = '';
        switch (selectedFormat) {
            case 'MM/DD/YYYY':
                formattedDateValue = `${String(currentDate.getMonth() + 1).padStart(2, '0')}/${String(currentDate.getDate()).padStart(2, '0')}/${currentDate.getFullYear()}`;
                break;
            case 'DD/MM/YYYY':
                formattedDateValue = `${String(currentDate.getDate()).padStart(2, '0')}/${String(currentDate.getMonth() + 1).padStart(2, '0')}/${currentDate.getFullYear()}`;
                break;
            case 'YYYY-MM-DD':
                formattedDateValue = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`;
                break;
            case 'DD-MM-YYYY':
                formattedDateValue = `${String(currentDate.getDate()).padStart(2, '0')}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${currentDate.getFullYear()}`;
                break;
            default:
                formattedDateValue = '';
        }
        setFormattedDate(formattedDateValue);
    }, [selectedFormat]);

    const dateFormats = [
        'MM/DD/YYYY',
        'DD/MM/YYYY',
        'YYYY-MM-DD',
        'DD-MM-YYYY',
    ];
    const currentYear: any = new Date().getFullYear();
    setValue("invoiceYear", currentYear);


    const CustomFormControl = styled(FormControl)({
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent',
            },
        },
    });

    const CustomSelect = styled(Select)({
        '&.MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'transparent',
            },
        },
    });

    const invoiceNumberFormat = [
        '00',
        '000',
        '0000',
    ];

    return (
        <div>
            <div>
                <div className="bg-[#E1E8E7] h-full" style={{ fontFamily: "poppins" }}>
                    <div className="text-2xl font-bold text-[#045545]  text-lg   ml-2">
                        Invoice Settings
                    </div>
                    <div>
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <fieldset className="mx-2 mb-4 rounded-lg">
                                <legend className="p-4  font-bold text-[#008065]  ml-2">
                                    Invoice Settings
                                </legend>
                                <div className="grid gap-4 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 ml-4">
                                    <div className="flex ml-1 gap-2 mb-3">
                                        <input
                                            type="checkbox"
                                            name="defaultBankInfo"
                                            placeholder="defaultBankInfo"
                                            onChange={handleDefaultCheckboxChange}
                                            checked={defaultSettingInfo}
                                            className={
                                                "border-2 focus:outline-none rounded-[7px] px-2 h-5 w-5 accent-green-700 cursor-pointer"
                                            }
                                            style={{
                                                fontFamily: "poppins",
                                                fontSize: "14px",
                                                fontWeight: 400,
                                            }}

                                        />
                                        <div className="text-[#008065] font-semibold">
                                            <label>Default Invoice Settings Info</label>
                                        </div>
                                    </div>

                                </div>
                                <div className="rounded-xl  py-3 mb-3  bg-[#E1E8E7] ">
                                    <div className="flex w-full flex-col pl-4 sm:flex-row">
                                        <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                                            <div className="flex flex-col mb-6 ">
                                                <label
                                                    className="required mb-1"
                                                    style={{
                                                        fontFamily: "poppins",
                                                        fontSize: "14px",
                                                        fontWeight: 700,
                                                        color: "#008065",
                                                    }}
                                                >

                                                    <span> Invoice Tittle</span>
                                                </label>
                                                <div className="relative flex">
                                                    <div className="relative">
                                                        <input
                                                            type="text"
                                                            placeholder="Invoice Tittle"
                                                            {...register("invoiceTitle",
                                                                {
                                                                    required: "Invoice Title is required",
                                                                    validate: (value) => {
                                                                        if (value.trim().length === 0) {
                                                                            return "Invoice Title is required";
                                                                        }
                                                                        return true;
                                                                    },
                                                                }
                                                            )}
                                                            
                                                            className={
                                                                "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                                                            }
                                                            style={{
                                                                fontFamily: "poppins",
                                                                fontSize: "14px",
                                                                fontWeight: 400,
                                                                borderColor: errors?.invoiceTitle ? "red" : "#008065",
                                                            }}
                                                        />
                                                        {errors?.invoiceTitle && (
                                                            <p
                                                                style={{
                                                                    fontFamily: "poppins",
                                                                    fontWeight: 700,
                                                                    color: "#ff0000",
                                                                }}
                                                                className="text-red-400 text-xs"
                                                            >
                                                                {errors?.invoiceTitle?.message}
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 ">
                                            <div className="flex flex-col mb-6 ">
                                                <label
                                                    style={{
                                                        fontFamily: "poppins",
                                                        fontSize: "14px",
                                                        fontWeight: 700,
                                                        color: "#008065",
                                                    }}
                                                    className='ml-2 mb-1 required'
                                                >

                                                    <span> Invoice Number Format</span>
                                                </label>
                                                <div className="relative flex">
                                                    <div className="relative  mx-2">
                                                        <input
                                                            type="text"
                                                            {...register("invoiceNumber",
                                                                {
                                                                    required: "invoice Number is required",
                                                                    validate: (value) => {
                                                                        if (value.trim().length === 0) {
                                                                            return "Invoice Number is required";
                                                                        }
                                                                        return true;
                                                                    },
                                                                }
                                                            )}
                                                           
                                                            placeholder=" Ex:Lala/"
                                                            className={
                                                                "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                                                            }
                                                            style={{
                                                                fontFamily: "poppins",
                                                                fontSize: "14px",
                                                                fontWeight: 400,
                                                                borderColor: errors?.invoiceNumber ? "red" : "#008065",
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="relative  mx-2">
                                                        <input
                                                            type="text"
                                                            {...register("invoiceYear", {
                                                                required: true,
                                                            })}
                                                            className={
                                                                "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full mx-2"
                                                            }
                                                            style={{
                                                                fontFamily: "poppins",
                                                                fontSize: "14px",
                                                                fontWeight: 400,
                                                                borderColor: "#008065",
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="relative  mx-2">
                                                        <select
                                                            className="text-balance border-2 focus:outline-none  border-[#008065] rounded-[7px] px-2 h-9 block lg:w-[6rem] md:w-[6rem] xl:w-[6rem] 2xl:w-[6rem] sm:w-full "
                                                            style={{
                                                                fontFamily: "poppins",
                                                                fontSize: "13px",
                                                                fontWeight: 400,
                                                            }}
                                                            {...register("invoiceDigit", {
                                                                required: true,
                                                            })}
                                                            defaultValue={""}
                                                        >
                                                            {invoiceNumberFormat.map((format, index) => (
                                                                <option key={index} value={format}>
                                                                    {format}
                                                                </option>
                                                            ))}


                                                        </select>
                                                    </div>
                                                </div>
                                                {(errors?.invoiceNumber || errors?.invoiceYear) && (
                                                    <p
                                                        style={{
                                                            fontFamily: "poppins",
                                                            fontWeight: 700,
                                                            color: "#ff0000",
                                                        }}
                                                        className="ml-2 text-red-400 text-xs"
                                                    >
                                                        {errors?.invoiceNumber?.message}
                                                    </p>
                                                )}
                                            </div>
                                        </div>

                                        <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 pl-4">
                                            <div className="flex flex-col mb-6 ">
                                                <label
                                                    className='mb-1'
                                                >
                                                    <span
                                                        style={{
                                                            fontFamily: "poppins",
                                                            fontSize: "14px",
                                                            fontWeight: 700,
                                                            color: "#008065",
                                                        }}>
                                                        Invoice Date Format:  </span>
                                                </label>
                                                <CustomFormControl variant="outlined" fullWidth>
                                                    <CustomSelect
                                                        labelId="date-format-label"
                                                        id="date-format"
                                                        defaultValue={selectedFormat}
                                                        label="Date Format"
                                                        className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-8 w-full bg-[white]"
                                                        onChange={(event) => setSelectedFormat(event.target.value as string)}
                                                        renderValue={() => formattedDate}
                                                    >
                                                        {dateFormats.map((format, index) => (
                                                            <MenuItem key={index} value={format}>
                                                                {format}
                                                            </MenuItem>
                                                        ))}
                                                    </CustomSelect>
                                                </CustomFormControl>

                                            </div>

                                        </div>

                                        <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 ">
                                        </div>

                                    </div>




                                    <div className="flex w-full flex-col sm:flex-row pl-4 ">
                                        <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2"></div>
                                        <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2"></div>
                                        <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-2 px-2 mt-5">
                                            <div className="flex flex-col w-44 rounded">
                                                <button
                                                    type="button"
                                                    className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065]"
                                                    style={{
                                                        backgroundColor: "#008065",
                                                        fontSize: "14px",
                                                        fontFamily: "poppins",
                                                    }}
                                                    onClick={resetProduct}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                        <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2 mt-5 ">
                                            <div className="flex flex-col w-44 ">
                                                <button
                                                    type="submit"
                                                    className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065] "
                                                    style={{
                                                        backgroundColor: "#008065",
                                                        fontSize: "14px",
                                                        fontFamily: "poppins",
                                                    }}
                                                >
                                                    {tittleID ? "Update" : "Submit"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
                <InvoiceSettingsTable
                    invoiceDetail={invoiceDetail}
                    AppSettingList={invoiceDetail}
                    edit={handleEdit}
                    handleDelete={handleDelete}
                />
                {showConfirmation && (
                    <ConfirmationPopup
                        message="Are you sure you want to proceed?"
                        onConfirm={handleConfirm}
                        onCancel={handleCancel}
                    />
                )}
            </div>
        </div >
    )
}

export default InvoiceSettings
