/* eslint-disable eqeqeq */
import ClearIcon from "@mui/icons-material/Clear";
import moment from "moment";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { environment } from "../../environment/environment";
import { ExternalPost } from "../../redux/actions/externalInvoiceAction";
import DeleteIcon from "../Icons/DeleteIcon";
import DollarIcon from "../Icons/DollarIcon";
import EmailIcon from "../Icons/EmailIcon";
import MobileIcon from "../Icons/MobileIcon";
import PlusCircleIcon from "../Icons/PlusCircleIcon";
import SecurityIcon from "../Icons/SecurityIcon";
import TaxesIcon from "../Icons/TaxesIcon";
import Carousel from "../commen/Carousel";
import NumberFormat from "../commen/NumberFormat";
import { IconStyle, defaultInputSmStyle } from "../commen/defaultStyles";
import "./../../layouts/App/App.css";
import DetailsPage from "./DetailsPage";

import { Dialog, Transition } from "@headlessui/react";
import "react-toastify/dist/ReactToastify.css";
import HomePreview from "./HomePreview";
import { useParams } from "react-router-dom";
function HomePage() {
  const defaultValues: any = 'free-Invoice-invoice.html';
  const params = useParams()
  const { id } = params


  const ref = useRef<HTMLInputElement | null>(null);
  const inputRef = useRef(null as any);
  const dispatch = useDispatch();
  const [heaername1, heaername2, heaername3] = id ? id.split('-') : defaultValues.split("-")
  const routerDate: any = [
    { name: `Invoice`, vlaue: `Invoice` },
    { name: `Freelancer Invoice`, vlaue: `freelancer` },
    { name: `Hourly Rate Invoice`, vlaue: `hourlyrate` },
    { name: `Photography Invoice`, vlaue: `photography` },
    { name: `Consulting Invoice`, vlaue: `consulting` },
    { name: `Graphic Design Invoice`, vlaue: `graphicdesign` },
    { name: `GST Invoice`, vlaue: `gst` },
    { name: `Proforma  Invoice`, vlaue: `proforma` },
    { name: `Quotation & Estimate`, vlaue: `quotation` },
    { name: `Medical Invoice`, vlaue: `medical` }
  ];
  const findeHeadrValue = routerDate.find((x: any) => x?.vlaue == heaername2)?.name
  // ****************************Date Picker and Date Section******************************************
  const [addValue, setAddValue] = useState(false);




  const handleAddvalue = () => {
    if (!addValue) {
      setFormData({
        ...formData,
        duedate: moment(new Date()).format("DD/MM/YYYY"),
      });
    }
    setAddValue(!addValue);
  };

  const [productError, setProductError] = useState(false);

  const [addDiscount, setAddDiscount] = useState(false);
  const [addMobile, setAddMobile] = useState(false);
  const [addPan, setAddPan] = useState(false);
  const [addGst, setAddGst] = useState(false);
  const [addEmail, setAddEmail] = useState(false);
  const handleAddMobile = (value: any) => {
    setAddMobile(!addMobile);
    if (value === "addTrueMobile") {
      setFormData({ ...formData, companyMobile: "", errors: { ...formData.errors, companyMobileErrorMessage: '' } });
    }
  };

  const handleAddPan = (value: any) => {
    setAddPan(!addPan);
    if (value === "addTruepan") {
      setFormData({ ...formData, companyPan: "", isValidPan: true, errors: { ...formData.errors, companyPanErrorMessage: '' } });
    }
  };

  const handleAddGst = (value: any) => {
    setAddGst(!addGst);
    if (value === "addtruegst") {
      setFormData({ ...formData, companygst: "", isValidGst: true, errors: { ...formData.errors, companygstErrorMessage: '' } });
    }
  };

  const handleAddEmail = (value: any) => {
    setAddEmail(!addEmail);
    if (value === "addTrueEmail") {
      setFormData({ ...formData, companyEmail: "", isValidEmail: true, errors: { ...formData.errors, companyEmailErrorMessage: '' } });
    }
  };

  const [addclientMobile, setAddClientMobile] = useState(false);
  const [addClientPan, setAddClientPan] = useState(false);
  const [addClientGst, setAddClientGst] = useState(false);
  const [addClientEmail, setAddClientEmail] = useState(false);
  const isViewMode: boolean = true; // or false, depending on your logic
  const inputRef2 = useRef(null as any);

  const clientMobile = (value: any) => {
    setAddClientMobile(!addclientMobile);
    if (value === "addtTrueClientno") {
      setFormData({ ...formData, clientMobile: "", errors: { ...formData.errors, clientMobileErrorMessage: '' } });
    }
  };

  const handleClientAddPan = (value: any) => {
    setAddClientPan(!addClientPan);
    if (value === "addTrueClientPanNo") {
      setFormData({ ...formData, clientPan: "", isClientPan: true, errors: { ...formData.errors, clientPanErrorMessage: '' } });
    }
  };

  const handleClientAddGst = (value: any) => {
    setAddClientGst(!addClientGst);
    if (value === "addTrueClientGst") {
      setFormData({ ...formData, clientgst: "", isClientGst: true, errors: { ...formData.errors, clientgstErrorMessage: '' } });
    }
  };
  const handleClientAddEmail = (value: any) => {

    setAddClientEmail(!addClientEmail);
    if (value === "addTrueClientEmail") {
      setFormData({ ...formData, clientEmail: "", isClientEmail: true, errors: { ...formData.errors, clientEmailErrorMessage: '' } });
    }
  };

  const handleAddDiscount = (value: any) => {
    if (value !== "add") {
      setFormData({
        ...formData, toatalAmount: formData.toatalAmount + formData.discount, discount: 0
      })
      setAddiscountvalue("")
      setAddiscountTotlal('');
      setAddDiscount(false);
    } else {
      setAddDiscount(true);
    }
  };


  const [invoiceDate, setInvoiceDate] = useState(new Date() as any);
  const [dueDate, setDueDate] = useState(new Date() as any);
  const [invoice, setInvoice] = useState(findeHeadrValue);
  const [hsnCode, setHsn] = useState("hsnHome");
  const [childValue, setChildValue] = useState("");
  const handleChildClick = (value: any) => {
    setInvoice(value)
    setFormData((prevValue: any) => {
      return { ...prevValue, invoicehead: value }
    })
    setChildValue(value);
  };

  const CustomInputWithIcon = ({ value, onClick }: any) => (
    <div className="relative">
      <input
        type="text"
        value={value}
        readOnly
        className={
          "h-10 text-sm  flex-1 text-md px-2 block w-[156px] sm:w-[156px] border-solid border-2 rounded-[7px] p-x2 focus:outline-none" +
          "text-center  w-[156px] border border-[#008065] border-[2px] font-poppins font-medium pl-9 pr-5"
        }
      />
      <FaCalendarAlt
        className="absolute right-4 top-0 mt-3 mr-2 cursor-pointer"
        onClick={onClick}
      />
    </div>
  );

  const CustomDueDate = ({ value, onClick }: any) => (
    <div className="relative">
      <input
        type="text"
        value={value}
        readOnly
        className={
          "h-10 text-sm  flex-1 text-md px-2 block w-[156px] sm:w-[156px] border-solid border-2 rounded-[7px] p-x2 focus:outline-none" +
          "text-center  w-[156px] border border-[#008065] border-[2px] font-poppins font-medium pl-9 pr-5"
        }
      />
      <FaCalendarAlt
        className="absolute right-4 top-0 mt-3 mr-2 cursor-pointer"
        onClick={onClick}
      />
    </div>
  );
  const [viewOpen, setOpenView] = useState(false);
  const cancelButtonRef = useRef(null);

  const openChoosePreview = () => {
    let finalErrors = [...prodcutErrors]
    const validation = prodcutRows?.some(
      (item: any) => !item.prodcut || !item.rate || !item.qty
    );

    prodcutRows.forEach((item: any, index: any) => {
      finalErrors[index].prodcut = item.prodcut ? true : false;
      finalErrors[index].rate = item.rate ? true : false;
      finalErrors[index].qty = item.qty ? true : false;
    });
    setProdcutErrors(finalErrors)

    if (!validation && formData.companyName && formData.clientName && !formData.errors.clientPanErrorMessage && !formData.errors.clientEmailErrorMessage && !formData.errors?.clientMobileErrorMessage && !formData.errors.clientPostalCodeErrorMessage && !formData.errors?.companyPostalCodeErrorMessage && !formData.errors?.clientgstErrorMessage && !formData.errors?.companyMobileErrorMessage && !formData.errors?.companyEmailErrorMessage && !formData.errors?.companyPanErrorMessage && !formData.errors?.companygstErrorMessage) {
      setOpenView(true);
    }
    else {
      setProductError(true);
      validateProdForm();
    }

  }
  const closeModal = () => {
    setOpenView(false);
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
  const gstRegex = (/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9]{1}$/);
  const postalcodeRegex = /^[1-9]\d{5}$/;

  // ***********************************************************************

  useEffect(() => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      invoicedate: moment(invoiceDate).format("DD/MM/YYYY"),
      duedate: addValue ? moment(dueDate).format("DD/MM/YYYY") : '',
    }));
  }, [invoiceDate, dueDate, addValue]);

  // ****************************************Image section***************************************************
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    e.preventDefault();
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Data = reader.result as string;
        setFormData({ ...formData, imageData: base64Data });
        if (inputRef2.current) {
          inputRef2.current.value = '';
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const changeInvoice = (e: string) => {
    setInvoice(e);
  };

  const handleBlur = () => {
    let change = invoice ? invoice : "Invoice";
    setInvoice(change);
    setEditing(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      // setEditing(false);
      setInvoice(invoice);
      const inputElement = document.getElementById("input_focus");
      if (inputElement) {
        inputElement.blur();
      }
    }
  };

  const [isEditing, setEditing] = useState(false);

  const handleEditClick = () => {
    setEditing(false);
    ref?.current?.focus();
  };

  const ClearFile = (e: any) => {
    e.preventDefault();
    formData.imageData = "";
    // setFormData(formData);
    setFormData({ ...formData, imageData: null });
    inputRef.current.value = "";
  };

  useEffect(() => {
    setFormData({ ...formData, imageData: null });
  }, [])

  // *******************************Product Sction*********************************
  const [prodcutRows, setProductRows] = useState<any>([
    { prodcut: "", hsn: "", rate: "", qty: "", amount: 0 },
  ] as any);
  const [prodcutErrors, setProdcutErrors] = useState<any>([
    { prodcut: false, rate: false, qty: false },
  ] as any);
  const [extrafeesRows, setExtrafeesRows] = useState([] as any);
  const [taxRows, setTaxRows] = useState([] as any);
  const [addiscountvalue, setAddiscountvalue] = useState(null as any);

  const [addiscountTotal, setAddiscountTotlal] = useState(null as any);

  const handleInputChange = (index: any, fieldName: any, value: any) => {
    // validateProdForm();
    const newArray: any = [...prodcutRows];
    const newErrors: any = [...prodcutErrors];

    formData.product = newArray;
    newArray[index][fieldName] = value;
    newErrors[index][fieldName] = value ? true : false;
    setProdcutErrors(newErrors);
    if (fieldName === "qty" || fieldName === "rate") {
      if (
        prodcutRows.length > 0 &&
        extrafeesRows.length > 0 &&
        taxRows.length > 0
      ) {
        newArray[index]["amount"] =
          newArray[index].rate && newArray[index].qty
            ? parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)
            : !newArray[index].rate
              ? 0
              : 0;
        setProductRows(newArray);
        let subAmount = 0;
        for (let i = 0; i < newArray.length; i++) {
          subAmount += newArray[i].amount;
        }

        let tAmount = 0;
        let exAmount = 0;
        for (let i = 0; i < taxRows.length; i++) {
          tAmount += parseFloat(taxRows[i].taxAmount);
        }
        for (let i = 0; i < extrafeesRows.length; i++) {
          exAmount += parseFloat(extrafeesRows[i].examount);
        }

        if (addiscountTotal) {
          let final = tAmount + exAmount + subAmount;
          let disoucnt = final - addiscountTotal;
          setFormData({
            ...formData,
            product: newArray,
            subtotal: subAmount,
            toatalAmount: disoucnt,
          });
        } else {
          let final = tAmount + exAmount + subAmount;
          setFormData({
            ...formData,
            product: newArray,
            subtotal: subAmount,
            toatalAmount: final,
          });
        }
      } else if (prodcutRows.length > 0 && taxRows.length > 0) {
        newArray[index]["amount"] =
          newArray[index].rate && newArray[index].qty
            ? parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)
            : !newArray[index].rate
              ? 0
              : 0;
        setProductRows(newArray);
        let subAmount = 0;
        for (let i = 0; i < newArray.length; i++) {
          subAmount += newArray[i].amount;
        }

        let tAmount = 0;
        for (let i = 0; i < taxRows.length; i++) {
          tAmount += parseFloat(taxRows[i].taxAmount);
        }

        if (addiscountTotal) {
          let final = tAmount + subAmount;
          let disoucnt = final - addiscountTotal;
          setFormData({
            ...formData,
            subtotal: subAmount,
            toatalAmount: disoucnt,
          });
        } else {
          let final = tAmount + subAmount;
          setFormData({
            ...formData,
            subtotal: subAmount,
            toatalAmount: final,
          });
        }
      } else if (prodcutRows.length > 0 && extrafeesRows.length > 0) {
        newArray[index]["amount"] =
          newArray[index].rate && newArray[index].qty
            ? parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)
            : !newArray[index].rate
              ? 0
              : 0;
        setProductRows(newArray);
        let subAmount = 0;
        for (let i = 0; i < newArray.length; i++) {
          subAmount += newArray[i].amount;
        }

        let exAmount = 0;

        for (let i = 0; i < extrafeesRows.length; i++) {
          exAmount += parseFloat(extrafeesRows[i].examount);
        }
        if (addiscountTotal) {
          let final = exAmount + subAmount;
          let disoucnt = final - addiscountTotal;
          setFormData({
            ...formData,
            subtotal: subAmount,
            toatalAmount: disoucnt,
          });
        } else {
          let final = exAmount + subAmount;
          setFormData({
            ...formData,
            subtotal: subAmount,
            toatalAmount: final,
          });
        }
      } else {
        newArray[index]["amount"] =
          newArray[index].rate && newArray[index].qty
            ? parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)
            : !newArray[index].rate
              ? 0
              : 0;
        setProductRows(newArray);
        let subAmount = 0;
        for (let i = 0; i < newArray.length; i++) {
          subAmount += newArray[i].amount;
        }
        if (addiscountTotal) {
          let final = subAmount - addiscountTotal;
          setFormData({
            ...formData,
            subtotal: subAmount,
            toatalAmount: final,
          });
        } else {
          setFormData({
            ...formData,
            subtotal: subAmount,
            toatalAmount: subAmount,
          });
        }
      }
    } else {
      setProductRows(newArray);
    }

    const filteredData = newErrors.filter((item: any) => item.prodcut === false || item.qty === false || item.rate === false);
    let trueValues: any = true;
    filteredData.forEach((item: any) => {
      if (item.prodcut === true && item.rate === true && item.qty === true) {
        trueValues = true;
      } else {
        trueValues = false;
      }
    });

    // setPreViewDis(trueValues)
  };

  const handleProductDelete = (arr: any) => {
    if (arr.length === 0) {
      setFormData({ ...formData, toatalAmount: 0, subtotal: 0 });
      return;
    }
    const newArray: any = [...arr];
    for (let index = 0; index < newArray.length; index++) {
      if (newArray[index].qty || newArray[index].rate) {
        if (
          prodcutRows.length > 0 &&
          extrafeesRows.length > 0 &&
          taxRows.length > 0
        ) {
          newArray[index]["amount"] =
            newArray[index].rate && newArray[index].qty
              ? parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)
              : !newArray[index].rate
                ? 0
                : 0;
          setProductRows(newArray);
          let subAmount = 0;
          for (let i = 0; i < newArray.length; i++) {
            subAmount += newArray[i].amount;
          }

          let tAmount = 0;
          let exAmount = 0;
          for (let i = 0; i < taxRows.length; i++) {
            tAmount += parseFloat(taxRows[i].taxAmount);
          }
          for (let i = 0; i < extrafeesRows.length; i++) {
            exAmount += parseFloat(extrafeesRows[i].examount);
          }

          if (addiscountTotal) {
            let final = tAmount + exAmount + subAmount;
            let disoucnt = final - addiscountTotal;
            setFormData({
              ...formData,
              product: newArray,
              subtotal: subAmount,
              toatalAmount: disoucnt,
            });
          } else {
            let final = tAmount + exAmount + subAmount;
            setFormData({
              ...formData,
              product: newArray,
              subtotal: subAmount,
              toatalAmount: final,
            });
          }
        } else if (prodcutRows.length > 0 && taxRows.length > 0) {
          newArray[index]["amount"] =
            newArray[index].rate && newArray[index].qty
              ? parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)
              : !newArray[index].rate
                ? 0
                : 0;
          setProductRows(newArray);
          let subAmount = 0;
          for (let i = 0; i < newArray.length; i++) {
            subAmount += newArray[i].amount;
          }

          let tAmount = 0;
          for (let i = 0; i < taxRows.length; i++) {
            tAmount += parseFloat(taxRows[i].taxAmount);
          }

          if (addiscountTotal) {
            let final = tAmount + subAmount;
            let disoucnt = final - addiscountTotal;
            setFormData({
              ...formData,
              subtotal: subAmount,
              toatalAmount: disoucnt,
            });
          } else {
            let final = tAmount + subAmount;
            setFormData({
              ...formData,
              subtotal: subAmount,
              toatalAmount: final,
            });
          }
        } else if (prodcutRows.length > 0 && extrafeesRows.length > 0) {
          newArray[index]["amount"] =
            newArray[index].rate && newArray[index].qty
              ? parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)
              : !newArray[index].rate
                ? 0
                : 0;
          setProductRows(newArray);
          let subAmount = 0;
          for (let i = 0; i < newArray.length; i++) {
            subAmount += newArray[i].amount;
          }

          let exAmount = 0;

          for (let i = 0; i < extrafeesRows.length; i++) {
            exAmount += parseFloat(extrafeesRows[i].examount);
          }
          if (addiscountTotal) {
            let final = exAmount + subAmount;
            let disoucnt = final - addiscountTotal;
            setFormData({
              ...formData,
              subtotal: subAmount,
              toatalAmount: disoucnt,
            });
          } else {
            let final = exAmount + subAmount;
            setFormData({
              ...formData,
              subtotal: subAmount,
              toatalAmount: final,
            });
          }
        } else {
          newArray[index]["amount"] =
            newArray[index].rate && newArray[index].qty
              ? parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)
              : !newArray[index].rate
                ? 0
                : 0;
          setProductRows(newArray);
          let subAmount = 0;
          for (let i = 0; i < newArray.length; i++) {
            subAmount += newArray[i].amount;
          }
          if (addiscountTotal) {
            let final = subAmount - addiscountTotal;
            setFormData({
              ...formData,
              subtotal: subAmount,
              toatalAmount: final,
            });
          } else {
            setFormData({
              ...formData,
              subtotal: subAmount,
              toatalAmount: subAmount,
            });
          }
        }
      } else {
        setProductRows(newArray);
      }
    }
  }




  const [formData, setFormData] = useState({
    errorMessage: "",
    errors: {},
    invoicehead: findeHeadrValue,
    invoiceNo: "2024/0001",

    imageData: "",
    companyName: "",

    companyAddress: "",
    companyCity: "",
    companyState: "",

    companyPostalCode: "",
    isValidPostalCode: true,
    companyMobile: "",
    companyEmail: "",
    isValidEmail: true,

    companyPan: "",
    isValidPan: true,
    companygst: "",
    isValidGst: true,

    clientName: "",

    clientAddress: "",
    clientCity: "",
    clientState: "",

    clientPostalCode: "",
    isclientPostalCode: true,
    clientMobile: "",
    clientEmail: "",

    clientPan: "",
    clientgst: "",
    isClientGst: true,
    discount: 0,

    product: [],
    tax: [],
    extrafees: [],
    extrafeesRows: [],
    discountprecentage: "",
    subtotal: 0,
    toatalAmount: 0,
    user_id: environment?.user_id,
    invoicedate: moment(invoiceDate).format("DD/MM/YYYY"),
    duedate: addValue ? moment(dueDate).format("DD/MM/YYYY") : "",
  } as any);


  const addRow = () => {
    setProductRows([
      ...prodcutRows,
      { prodcut: "", hsn: "", rate: "", qty: "", amount: 0 },
    ]);
    setProdcutErrors([
      ...prodcutErrors,
      {
        prodcut: productError,
        rate: productError,
        qty: productError,
      },
    ]);
  };

  const removeRow = (index: any, row: any) => {
    const newArray = [...prodcutRows];
    const newErrors = [...prodcutErrors];
    let deletedIndex = newArray[index]

    newArray.splice(index, 1);
    if (newArray.length === 0)
      newArray.push({ prodcut: "", hsn: "", rate: "", qty: "", amount: 0 });
    handleProductDelete(newArray);
    setProductRows(newArray);
    newErrors.splice(index, 1);
    if (newErrors.length === 0)
      newErrors.push({ prodcut: false, rate: false, qty: false });
    setProdcutErrors(newErrors);
    setFormData({
      ...formData,
      subtotal: formData?.subtotal - Number(deletedIndex?.amount),
      product: [...newArray],
    });
  };

  // *******************************Tax Sction*********************************

  const handleInputTaxChange = (index: any, fieldName: any, value: any) => {
    const newArray = [...taxRows];
    formData.tax = newArray;
    newArray[index][fieldName] = value;

    if (fieldName === "taxPersent") {
      if (extrafeesRows.length > 0 && taxRows.length > 0) {
        newArray[index]["taxAmount"] = newArray[index].taxPersent
          ? ((parseFloat(newArray[index].taxPersent) / 100) * formData.subtotal).toFixed(2)
          : 0;
        setTaxRows(newArray);
        let tAmount = 0;
        let exAmount = 0;
        for (let i = 0; i < newArray.length; i++) {
          tAmount += parseFloat(newArray[i].taxAmount);
        }
        for (let i = 0; i < extrafeesRows.length; i++) {
          exAmount += parseFloat(extrafeesRows[i].examount);
        }
        if (addiscountTotal) {
          let final =
            tAmount && exAmount
              ? tAmount + formData.subtotal + exAmount
              : tAmount
                ? tAmount + formData.subtotal
                : exAmount
                  ? formData.subtotal + exAmount
                  : formData.subtotal;
          // let final = tAmount ? tAmount + formData.subtotal : formData.subtotal
          let dicountamount = final - addiscountTotal;
          setFormData({ ...formData, toatalAmount: dicountamount });
        } else {
          let final = tAmount + formData.subtotal + exAmount;
          setFormData({ ...formData, toatalAmount: final });
        }
      } else {
        newArray[index]["taxAmount"] = newArray[index].taxPersent
          ? ((parseFloat(newArray[index].taxPersent) / 100) * formData.subtotal).toFixed(2)
          : 0;
        setTaxRows(newArray);
        let tAmount = 0;
        for (let i = 0; i < newArray.length; i++) {
          tAmount += parseFloat(newArray[i].taxAmount);
        }
        if (addiscountTotal) {
          let final = tAmount ? tAmount + formData.subtotal : formData.subtotal;
          let dicountamount = final - addiscountTotal;
          setFormData({ ...formData, toatalAmount: dicountamount });
        } else {
          let final = tAmount + formData.subtotal;
          setFormData({ ...formData, toatalAmount: final });
        }
      }
    } else {
      setTaxRows(newArray);
    }
    handleExistingDiscount();
  };

  function handleExistingtaxes() {
    if (taxRows.length > 0) handleExistingTaxChange();
    if (extrafeesRows.length > 0) handleExistingExtraFees();
    handleExistingDiscount()
  }

  useEffect(() => {
    handleExistingtaxes();
  }, [formData.subtotal])

  const handleExistingTaxChange = () => {
    for (let i = 0; i < taxRows.length; i++) {
      taxRows[i]["taxAmount"] = taxRows[i].taxPersent
        ? ((parseFloat(taxRows[i].taxPersent) / 100) * formData.subtotal).toFixed(2)
        : 0;
    }
    if (extrafeesRows.length > 0 && taxRows.length > 0) {
      let tAmount = 0;
      let exAmount = 0;
      for (let i = 0; i < taxRows.length; i++) {
        tAmount += parseFloat(taxRows[i].taxAmount);
      }
      for (let i = 0; i < extrafeesRows.length; i++) {
        exAmount += parseFloat(extrafeesRows[i].examount);
      }
      if (addiscountTotal) {
        let final =
          tAmount && exAmount
            ? tAmount + formData.subtotal + exAmount
            : tAmount
              ? tAmount + formData.subtotal
              : exAmount
                ? formData.subtotal + exAmount
                : formData.subtotal;
        let dicountamount = final - addiscountTotal;
        setFormData({ ...formData, toatalAmount: dicountamount });
      } else {
        let final = tAmount + formData.subtotal + exAmount;
        setFormData({ ...formData, toatalAmount: final });
      }
    }
    else {
      if (taxRows.length > 0) {
        let tAmount = 0;
        for (let i = 0; i < taxRows.length; i++) {
          tAmount += parseFloat(taxRows[i].taxAmount);
        }
        if (addiscountTotal) {
          let final = tAmount ? tAmount + formData.subtotal : formData.subtotal;
          let dicountamount = final - addiscountTotal;
          setFormData({ ...formData, toatalAmount: dicountamount });
        } else {
          let final = tAmount + formData.subtotal;
          setFormData({ ...formData, toatalAmount: final });
        }
      }
    }
  }

  const handleExistingDiscount = () => {
    setFormData({
      ...formData,
      discountprecentage: addiscountvalue,
    });
    if (addiscountvalue) {
      if (
        taxRows.length > 0 &&
        extrafeesRows.length > 0 &&
        prodcutRows.length > 0
      ) {
        let prodAmount = 0;
        let taxAmount = 0;
        let extAmount = 0;
        for (let i = 0; i < prodcutRows.length; i++) {
          prodAmount += parseFloat(prodcutRows[i].amount);
        }
        for (let i = 0; i < taxRows.length; i++) {
          taxAmount += parseFloat(taxRows[i].taxAmount);
        }
        for (let i = 0; i < extrafeesRows.length; i++) {
          extAmount += parseFloat(extrafeesRows[i].examount);
        }
        let final = prodAmount + taxAmount + extAmount;
        let dicountvalue: any = (parseFloat(addiscountvalue) / 100) * final;
        setAddiscountTotlal(dicountvalue);
        let dicountamount = final - dicountvalue;
        setFormData({
          ...formData,
          discount: dicountvalue,
          toatalAmount: dicountamount,
          discountprecentage: addiscountvalue,
        });
      } else if (taxRows.length > 0 && prodcutRows.length > 0) {
        let prodAmount = 0;
        let taxAmount = 0;

        for (let i = 0; i < prodcutRows.length; i++) {
          prodAmount += parseFloat(prodcutRows[i].amount);
        }
        for (let i = 0; i < taxRows.length; i++) {
          taxAmount += parseFloat(taxRows[i].taxAmount);
        }

        let final = prodAmount + taxAmount;
        let dicountvalue: any = (parseFloat(addiscountvalue) / 100) * final;
        setAddiscountTotlal(dicountvalue);
        let dicountamount = final - dicountvalue;
        setFormData({
          ...formData,
          discount: dicountvalue,
          toatalAmount: dicountamount,
          discountprecentage: addiscountvalue,
        });
      } else if (extrafeesRows.length > 0 && prodcutRows.length > 0) {
        let prodAmount = 0;
        let extAmount = 0;
        for (let i = 0; i < prodcutRows.length; i++) {
          prodAmount += parseFloat(prodcutRows[i].amount);
        }
        for (let i = 0; i < extrafeesRows.length; i++) {
          extAmount += parseFloat(extrafeesRows[i].examount);
        }
        let final = prodAmount + extAmount;
        let dicountvalue: any = (parseFloat(addiscountvalue) / 100) * final;
        setAddiscountTotlal(dicountvalue);
        let dicountamount = final - dicountvalue;
        setFormData({
          ...formData,
          discount: dicountvalue,
          toatalAmount: dicountamount,
          discountprecentage: addiscountvalue,
        });
      } else {
        let prodAmount = 0;
        for (let i = 0; i < prodcutRows.length; i++) {
          prodAmount += parseFloat(prodcutRows[i].amount);
        }
        let final = prodAmount;
        let dicountvalue: any = (parseFloat(addiscountvalue) / 100) * final;
        setAddiscountTotlal(dicountvalue);
        let dicountamount = final - dicountvalue;
        setFormData({
          ...formData,
          discount: dicountvalue,
          toatalAmount: dicountamount,
          discountprecentage: addiscountvalue,
        });
      }
    } else {
      setAddiscountvalue(null);
      setAddiscountTotlal(null);
      formData.discount = 0;
      if (taxRows.length > 0 && extrafeesRows.length > 0) {
        let tAmount = 0;
        let subAmount = 0;
        for (let i = 0; i < taxRows.length; i++) {
          subAmount += parseFloat(taxRows[i].taxAmount);
        }
        for (let i = 0; i < extrafeesRows.length; i++) {
          tAmount += parseFloat(extrafeesRows[i].examount);
        }
        let final = tAmount + subAmount + formData.subtotal;
        setFormData({ ...formData, toatalAmount: final });
      } else if (taxRows.length > 0) {
        let subAmount = 0;
        for (let i = 0; i < taxRows.length; i++) {
          subAmount += parseFloat(taxRows[i].taxAmount);
        }
        let final = subAmount + formData.subtotal;
        setFormData({ ...formData, toatalAmount: final });
      } else if (extrafeesRows.length > 0) {
        let tAmount = 0;
        for (let i = 0; i < extrafeesRows.length; i++) {
          tAmount += parseFloat(extrafeesRows[i].examount);
        }
        let final = tAmount + formData.subtotal;
        setFormData({ ...formData, toatalAmount: final });
      } else {
        setFormData({ ...formData, toatalAmount: formData.subtotal });
      }
    }
  }
  const handleExistingExtraFees = () => {
    for (let index = 0; index < extrafeesRows.length; index++) {
      if (formData.subtotal) {
        extrafeesRows[index]["examount"] = parseFloat(extrafeesRows[index]["expercentage"] ? extrafeesRows[index]["expercentage"] : 0).toFixed(2);
      }
      else {
        extrafeesRows[index]["examount"] = parseFloat(formData.subtotal).toFixed(2);
      }
    }
    if (extrafeesRows.length > 0 && taxRows.length > 0) {
      let tAmount: any = 0;
      let subtAmount: any = 0;
      for (let i = 0; i < extrafeesRows.length; i++) {
        tAmount += parseFloat(extrafeesRows[i].examount);
      }
      for (let i = 0; i < taxRows.length; i++) {
        subtAmount += parseFloat(taxRows[i].taxAmount);
      }
      if (addiscountTotal) {
        let final = tAmount
          ? tAmount + formData.subtotal + subtAmount
          : formData.subtotal + subtAmount;
        let dicountamount = final - addiscountTotal;
        setFormData({ ...formData, toatalAmount: dicountamount });
      } else {
        let final = tAmount
          ? tAmount + formData.subtotal + subtAmount
          : formData.subtotal + subtAmount;
        setFormData({ ...formData, toatalAmount: final });
      }
    }
    else {
      let tAmount: any = 0;
      for (let i = 0; i < extrafeesRows.length; i++) {
        tAmount += parseFloat(extrafeesRows[i].examount);
      }
      if (addiscountTotal) {
        let final = tAmount ? tAmount + formData.subtotal : formData.subtotal;
        let dicountamount = final - addiscountTotal;
        setFormData({ ...formData, toatalAmount: dicountamount });
      } else {
        let final = tAmount ? tAmount + formData.subtotal : formData.subtotal;
        setFormData({ ...formData, toatalAmount: final.toFixed(2) });
      }
    }
  }

  const addTaxRow = () => {
    setTaxRows([...taxRows, { taxName: "", taxPersent: "", taxAmount: 0 }]);
  };

  const removeTaxRow = (index: any, row: any) => {
    const newArray = [...taxRows];
    let deletedTaxRow = newArray[index];
    newArray.splice(index, 1);
    setTaxRows(newArray);
    setFormData({
      ...formData,
      tax: [...newArray],
      toatalAmount: formData.toatalAmount - deletedTaxRow.taxAmount
    });
  };

  // *******************************Extra fees Sction*********************************
  const handleInputExtaraChange = (index: any, fieldName: any, value: any) => {
    const newArray = [...extrafeesRows];
    formData.extrafeesRows = newArray;

    newArray[index][fieldName] = value;
    if (fieldName === "expercentage") {
      if (taxRows.length > 0 && extrafeesRows.length > 0) {
        newArray[index]["examount"] = parseFloat(value ? value : '0').toFixed(2);
        setExtrafeesRows(newArray);
        let tAmount: any = 0;
        let subtAmount: any = 0;

        for (let i = 0; i < newArray.length; i++) {
          tAmount += parseFloat(newArray[i].examount);
        }

        for (let i = 0; i < taxRows.length; i++) {
          subtAmount += parseFloat(taxRows[i].taxAmount);
        }
        if (addiscountTotal) {
          let final = tAmount
            ? tAmount + formData.subtotal + subtAmount
            : formData.subtotal + subtAmount;
          let dicountamount = final - addiscountTotal;
          setFormData({ ...formData, toatalAmount: dicountamount });
        } else {
          let final = tAmount
            ? tAmount + formData.subtotal + subtAmount
            : formData.subtotal + subtAmount;
          setFormData({ ...formData, toatalAmount: Number(final).toFixed(2) });
        }
      } else {
        newArray[index]["examount"] = parseFloat(value ? value : '0').toFixed(2);
        setExtrafeesRows(newArray);
        let tAmount: any = 0;

        for (let i = 0; i < newArray.length; i++) {
          tAmount += parseFloat(newArray[i].examount);
        }
        if (addiscountTotal) {
          let final = tAmount ? tAmount + formData.subtotal : formData.subtotal;
          let dicountamount = final - addiscountTotal;
          setFormData({ ...formData, toatalAmount: dicountamount });
        } else {
          let final = tAmount ? tAmount + formData.subtotal : formData.subtotal;
          setFormData({ ...formData, toatalAmount: Number(final).toFixed(2) });
        }
      }
    } else {
      setExtrafeesRows(newArray);
    }
    handleExistingDiscount();
  };

  const addExtaraRow = () => {
    setExtrafeesRows([
      ...extrafeesRows,
      { exname: "", expercentage: "", examount: 0 },
    ]);
  };

  const removeExtaraRow = (index: any, row: any) => {
    const newArray = [...extrafeesRows];
    let deletedExtraRow = newArray[index];
    newArray.splice(index, 1);
    setExtrafeesRows(newArray);
    setFormData({
      ...formData,
      extrafeesRows: [],
      toatalAmount: formData.toatalAmount - deletedExtraRow.examount
    });
  };

  const [companyErrors, setCompanyErrors] = useState<{ companyName?: string }>(
    {}
  );
  const [clientErrors, setClientErrors] = useState<{ clientName?: string }>({});

  let datevalue = moment(new Date()).format("YYYY-MM-DD");
  const [year] = datevalue.split("-");
  const [countvalue, setCount] = useState(
    String(`${year}/`) + String(1).padStart(4, "0")
  );

  /********************** handle change uppercase validation *************************/

  const handleChangeCap = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toUpperCase();
    const { name } = e.target;
    setFormData({ ...formData, [name]: value });
    let errorMessage = "";
    let isValidPan = true;

    let isValidGst = true;
    let isClientPan = true;
    let isClientGst = true;

    if (name.startsWith("company")) {
      if (name === "companyPan" && value) {
        isValidPan = panRegex.test(value);
        errorMessage = isValidPan ? "" : "Invalid PAN number format";
      }

      if (name === "companygst" && value) {
        isValidGst = gstRegex.test(value);
        errorMessage = isValidGst ? "" : "Invalid GST number format";
      }

    } else if (name.startsWith("client")) {
      if (name === "clientPan" && value) {
        isClientPan = panRegex.test(value);
        errorMessage = isClientPan ? "" : "Invalid PAN number format";
      }
      if (name === "clientgst" && value) {
        isClientGst = gstRegex.test(value);
        errorMessage = isClientGst ? "" : "Invalid GST number format";
      }
    }
    setFormData({
      ...formData,
      [name]: value,
      errors: {
        ...formData.errors,
        [`${name}ErrorMessage`]: errorMessage,
      },
      isValidPan:
        name === "companyPan"
          ? isValidPan
          : formData.isValidPan,
      isValidGst:
        name === "companygst"
          ? isValidGst
          : formData.isValidGst,

      isClientGst: name === "clientgst" ? isClientGst : formData.isClientGst,
      isClientPan:
        name === "clientPan"
          ? isClientPan
          : formData.isClientPan,
    });
  };


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setCompanyErrors({ ...companyErrors, [name]: "" });
    setClientErrors({ ...clientErrors, [name]: "" });
    setCount(countvalue);

    let errorMessage = "";
    let isValidPostalCode = true;
    let isValidMobile = true;
    let isValidEmail = true;

    let isclientPostalCode = true;
    let isclientMobile = true;
    let isClientEmail = true;


    if (name.startsWith("company")) {
      if (name === "companyEmail" && value) {
        isValidEmail = emailRegex.test(value);
        errorMessage = isValidEmail ? "" : "Invalid email format";
      }
      if (name === "companyPostalCode" && value) {
        isValidPostalCode = postalcodeRegex.test(value);
        if (!isValidPostalCode) {
          errorMessage = "Invalid postal code";
        }
      }
      if (name === "companyMobile" && value) {
        isValidMobile = value.length === 10;
        errorMessage = isValidMobile ? "" : "Invalid mobile number ";
      }

    } else if (name.startsWith("client")) {
      if (name === "clientEmail" && value) {
        isClientEmail = emailRegex.test(value);
        errorMessage = isClientEmail ? "" : "Invalid email format";
      }
      if (name === "clientMobile" && value) {
        isclientMobile = value.length === 10;
        errorMessage = isclientMobile ? "" : "Invalid mobile number";
      }
      if (name === "clientPostalCode" && value) {
        isclientPostalCode = postalcodeRegex.test(value);
        errorMessage = isclientPostalCode ? "" : "Invalid postal code";
      }

    }
    setFormData({
      ...formData,
      [name]: value,
      errors: {
        ...formData.errors,
        [`${name}ErrorMessage`]: errorMessage,
      },
      isValidEmail:
        name === "companyEmail"
          ? isValidEmail
          : name === "clientEmail"
            ? isValidEmail
            : formData.isValidEmail,
      isValidPostalCode:
        name === "companyPostalCode"
          ? isValidPostalCode
          : name === "companyPostalCode"
            ? isValidPostalCode
            : formData.isValidPostalCode,

      isClientEmail:
        name === "companyEmail"
          ? isClientEmail
          : name === "clientEmail"
            ? isClientEmail
            : formData.isClientEmail,
      isclientPostalCode:
        name === "clientPostalCode"
          ? isclientPostalCode
          : name === "clientPostalCode"
            ? isclientPostalCode
            : formData.isclientPostalCode,

    });
  };

  // *****************************DisCount Value************************************

  const handleChangediscount = (e: any) => {
    setAddiscountvalue(e.target.value);
    setFormData({
      ...formData,
      discountprecentage: e.target.value,
    });
    if (e.target.value) {
      if (
        taxRows.length > 0 &&
        extrafeesRows.length > 0 &&
        prodcutRows.length > 0
      ) {
        let prodAmount = 0;
        let taxAmount = 0;
        let extAmount = 0;
        for (let i = 0; i < prodcutRows.length; i++) {
          prodAmount += parseFloat(prodcutRows[i].amount);
        }
        for (let i = 0; i < taxRows.length; i++) {
          taxAmount += parseFloat(taxRows[i].taxAmount);
        }
        for (let i = 0; i < extrafeesRows.length; i++) {
          extAmount += parseFloat(extrafeesRows[i].examount);
        }
        let final = prodAmount + taxAmount + extAmount;
        let dicountvalue: any = (parseFloat(e.target.value) / 100) * final;
        setAddiscountTotlal(dicountvalue);
        let dicountamount = final - dicountvalue;
        setFormData({
          ...formData,
          discount: dicountvalue,
          toatalAmount: dicountamount,
          discountprecentage: e.target.value,
        });
      } else if (taxRows.length > 0 && prodcutRows.length > 0) {
        let prodAmount = 0;
        let taxAmount = 0;

        for (let i = 0; i < prodcutRows.length; i++) {
          prodAmount += parseFloat(prodcutRows[i].amount);
        }
        for (let i = 0; i < taxRows.length; i++) {
          taxAmount += parseFloat(taxRows[i].taxAmount);
        }

        let final = prodAmount + taxAmount;
        let dicountvalue: any = (parseFloat(e.target.value) / 100) * final;
        setAddiscountTotlal(dicountvalue);
        let dicountamount = final - dicountvalue;
        setFormData({
          ...formData,
          discount: dicountvalue,
          toatalAmount: dicountamount,
          discountprecentage: e.target.value,
        });
      } else if (extrafeesRows.length > 0 && prodcutRows.length > 0) {
        let prodAmount = 0;
        let extAmount = 0;
        for (let i = 0; i < prodcutRows.length; i++) {
          prodAmount += parseFloat(prodcutRows[i].amount);
        }

        for (let i = 0; i < extrafeesRows.length; i++) {
          extAmount += parseFloat(extrafeesRows[i].examount);
        }

        let final = prodAmount + extAmount;
        let dicountvalue: any = (parseFloat(e.target.value) / 100) * final;
        setAddiscountTotlal(dicountvalue);
        let dicountamount = final - dicountvalue;
        setFormData({
          ...formData,
          discount: dicountvalue,
          toatalAmount: dicountamount,
          discountprecentage: e.target.value,
        });
      } else {
        let prodAmount = 0;
        for (let i = 0; i < prodcutRows.length; i++) {
          prodAmount += parseFloat(prodcutRows[i].amount);
        }
        let final = prodAmount;
        let dicountvalue: any = (parseFloat(e.target.value) / 100) * final;
        setAddiscountTotlal(dicountvalue);
        let dicountamount = final - dicountvalue;
        setFormData({
          ...formData,
          discount: dicountvalue,
          toatalAmount: dicountamount,
          discountprecentage: e.target.value,
        });
      }
    } else {
      setAddiscountvalue(null);
      setAddiscountTotlal(null);
      formData.discount = 0;
      if (taxRows.length > 0 && extrafeesRows.length > 0) {
        let tAmount = 0;
        let subAmount = 0;
        for (let i = 0; i < taxRows.length; i++) {
          subAmount += parseFloat(taxRows[i].taxAmount);
        }
        for (let i = 0; i < extrafeesRows.length; i++) {
          tAmount += parseFloat(extrafeesRows[i].examount);
        }
        let final = tAmount + subAmount + formData.subtotal;
        setFormData({ ...formData, toatalAmount: final });
      } else if (taxRows.length > 0) {
        let subAmount = 0;
        for (let i = 0; i < taxRows.length; i++) {
          subAmount += parseFloat(taxRows[i].taxAmount);
        }
        let final = subAmount + formData.subtotal;
        setFormData({ ...formData, toatalAmount: final });
      } else if (extrafeesRows.length > 0) {
        let tAmount = 0;
        for (let i = 0; i < extrafeesRows.length; i++) {
          tAmount += parseFloat(extrafeesRows[i].examount);
        }
        let final = tAmount + formData.subtotal;
        setFormData({ ...formData, toatalAmount: final });
      } else {
        setFormData({ ...formData, toatalAmount: formData.subtotal });
      }
    }
  };
  //********************************* */ dowload validation***************************************************//
  // const [proderrors, setProdErrors] = useState<{ [key: string]: string }>({});

  const validateProdForm = () => {
    if (formData.companyName?.trim() === "") {
      companyErrors["companyName"] = "Company Name is requried  ";
    }
    if (formData.clientName?.trim() === "") {
      clientErrors["clientName"] = "Client Name is requried  ";
    }

  };

  // ******************************************Post Method******************************************

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let finalErrors = [...prodcutErrors]
    const validation = prodcutRows?.some(
      (item: any) => !item.prodcut || !item.rate || !item.qty
    );
    // let item: any = validation ? false : true
    // setPreViewDis(item)
    prodcutRows.forEach((item: any, index: any) => {
      finalErrors[index].prodcut = item.prodcut ? true : false;
      // finalErrors[index].hsn = item.hsn ? true : false;
      finalErrors[index].rate = item.rate ? true : false;
      finalErrors[index].qty = item.qty ? true : false;
    });
    setProdcutErrors(finalErrors)


    if (!validation && formData.companyName && formData.clientName && !formData.errors.clientPanErrorMessage && !formData.errors.clientEmailErrorMessage && !formData.errors?.clientMobileErrorMessage && !formData.errors.clientPostalCodeErrorMessage && !formData.errors?.companyPostalCodeErrorMessage && !formData.errors?.clientgstErrorMessage && !formData.errors?.companyMobileErrorMessage && !formData.errors?.companyEmailErrorMessage && !formData.errors?.companyPanErrorMessage && !formData.errors?.companygstErrorMessage) {

      let obj = {
        invoicehead: findeHeadrValue,
        invoiceNo: formData.invoiceNo,
        invoicedate: formData.invoicedate,
        duedate: formData.duedate,
        imageData: formData.imageData,
        companyName: formData.companyName,
        companyAddress: formData.companyAddress,
        companyCity: formData.companyCity,
        companyState: formData.companyState,
        companyPostalCode: formData.companyPostalCode,
        companyMobile: formData.companyMobile,
        companyEmail: formData.companyEmail,
        companyPan: formData.companyPan,
        companygst: formData.companygst,
        clientName: formData.clientName,
        clientAddress: formData.clientAddress,
        clientCity: formData.clientCity,
        clientState: formData.clientState,
        clientPostalCode: formData.clientPostalCode,
        clientMobile: formData.clientMobile,
        clientEmail: formData.clientEmail,
        clientPan: formData.clientPan,
        clientgst: formData.clientgst,
        discount: formData.discount.toFixed(2),
        product: formData.product,
        tax: formData.tax,
        extrafees: formData.extrafees,
        extrafeesRows: formData.extrafeesRows,
        discountprecentage: formData.discountprecentage,
        subtotal: formData.subtotal,
        toatalAmount: Number(formData.toatalAmount).toFixed(2),
        hsnCode: hsnCode,
      }
      dispatch(ExternalPost(obj) as any).then((response: any) => {
        const queryParams = new URLSearchParams();
        queryParams.append("param1", "#000000");
        queryParams.append("param2", "#000000");
        queryParams.append("param3", "#000000");
        queryParams.append("param4", "#000000");
        fetch(
          `/api/generate_pdf/${response?.payload?.invoice_id}?${queryParams.toString()}`
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.blob();
          })
          .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link: any = document.createElement("a");
            link.href = url;
            const formattedDate = moment().format('YYYY-MM-DD')
            link.setAttribute("download", `invoice_${formData.clientName}_${formattedDate}.pdf`);
            // link.setAttribute("download", "invoice.pdf");
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            setProductError(false);
          })
          .catch((error) => console.error("Error downloading PDF:", error));
      });
    } else {

      validateProdForm();
      setProductError(true);
    }
  };

  const handlerInvoicenum = (e: any) => {

    setFormData((prevValue: any) => {
      return { ...prevValue, invoiceNo: e }
    })
    setCount(e);
  };

  return (
    <>
      <div className="bg-[white] lg:mx-4  xl:mx-0 xl:ml-20 xl:mr-20 xs:mx-1 pb-2 pt-4 ">
        <form onSubmit={handleSubmit}>
          <Carousel onClick={handleChildClick} />
          <div className="justify-center flex flex-row relative">
            <input
              className={`${childValue
                ? " lg:w-fit 2xl:w-fit xl:w-fit md:w-fit"
                : "  "
                } rounded-[7px] text-center xs:w-1/4 mt-2 focus:outline-emerald-500 lg:text-3xl xl:text-3xl 2xl:text-3xl md:text-3xl text-base pl-5`}
              autoComplete="nope"
              style={{
                fontFamily: "poppins",
                fontWeight: 700,
              }}
              name="invoice"
              onBlur={handleBlur}
              value={invoice}
              type="text"
              aria-label="Search"
              ref={ref}
              id="input_focus"
              onKeyDown={handleKeyDown}
              onChange={(e) => {
                changeInvoice(e.target.value);
              }}
              onFocus={(e) => {
                setEditing(true);
              }}
            />
            {/* </h1> */}
            <div
              className={`${childValue ? "ml-2 " : "ml-2"
                } mt-3 lg:mt-4 2xl:mt-4 xl:mt-4 md:mt-4`}
            >
              {isEditing ? (
                <></>
              ) : (
                <button
                  type="button"
                  onClick={handleEditClick}
                  id="combo_1"
                  aria-label="Aria Name"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="#008065"
                    className="w-6 h-6 cursor-pointer"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                    />
                  </svg>
                </button>
              )}
            </div>
          </div>
          {/* Background Image */}
          <div
            className={
              " px-8 bg-cover bg-center  rounded-xl flex flex-col sm:flex-row justify-between "
            }
          >
            <div
              className={"flex  xs:flex-col flex-col sm:flex-row items-center"}
            >
              <div className={" font-title  sm:text-left"}>
                <label
                  style={{ fontFamily: "poppins" }}
                  className="object-cover  pb-5 flex flex-col items-center px-1 cursor-pointer hover:bg-blue "
                >
                  <input
                    type="file"
                    accept="image/*"
                    ref={inputRef}
                    className="hidden"
                    onChange={(e) => handleFileChange(e)}
                  />
                  {formData.imageData ? (
                    <>
                      {/* <ClearIcon
                        sx={{ marginLeft: "7rem" }}
                        onClick={(e) => ClearFile(e)}
                      /> */}
                      <svg
                        onClick={(e) => ClearFile(e)}
                        style={{ marginLeft: "10rem" }}
                        className="h-6  text-grey"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      <img
                        className="w-[150px] h-[95px] inline-block shrink-0 rounded-lg "
                        alt="logo"
                        src={formData.imageData}
                      />
                    </>
                  ) : (
                    <>
                      <div
                        className="object-cover  pb-5 flex flex-col items-center px-1 bg-white text-blue rounded-lg shadow-md tracking-wide border border-blue cursor-pointer hover:bg-blue "
                      >
                        <svg
                          className="w-10 h-10 mt-4"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                        </svg>
                        <span className="mt-1 text-base leading-normal">
                          Add a Business Logo
                        </span>
                      </div>

                    </>
                  )}
                </label>
              </div>
            </div>
            <div>
              <div className="sm:flex-1 mt-2 p-2">
                <div className="flex flex-row justify-between items-center mb-2 mr-6">
                  <div
                    style={{
                      color: "#008065",
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                    className=" flex-1 pr-2 whitespace-nowrap"
                  >
                    INVOICE #
                  </div>
                  <div className="font-title text-right ml-4">
                    <input
                      value={countvalue}
                      name="invoiceNo"
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        borderColor: "#008065",
                      }}
                      autoComplete="nope"
                      placeholder="Invoice No"
                      className={
                        " text-center font-medium h-10 text-sm flex-1 font-title text-md px-2 block border-solid border-2 rounded-[7px] p-x2 focus:outline-none w-[156px]"
                      }
                      onChange={(e) => handlerInvoicenum(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-between items-center mb-2 mr-6">
                  <div
                    id="creationDate"
                    style={{
                      color: "#008065",
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                    className="flex-1  pr-2 whitespace-nowrap"
                  >
                    Invoice Date
                  </div>
                  <div className=" flex-1 text-right">
                    <DatePicker
                      selected={invoiceDate}
                      onChange={(date) => setInvoiceDate(date)}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select a date"
                      calendarClassName="datepicker-with-icon"
                      popperClassName="datepicker-popper"
                      customInput={<CustomInputWithIcon />}
                    />
                  </div>
                </div>
                {addValue ? (
                  <div className="flex flex-row justify-between items-center mb-2 ">
                    <div
                      id="creationDate"
                      style={{
                        color: "#008065",
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      className="flex-1  pr-2 whitespace-nowrap"
                    >
                      Due Date
                    </div>
                    <div className=" flex-1 text-right relative ml-5">
                      <div>
                        <DatePicker
                          // style={{ fill: "red !important" }}
                          selected={dueDate}
                          // onChange={(date) => setDueDate(date)}
                          onChange={(date) => {
                            setDueDate(date);
                            setFormData({
                              ...formData,
                              duedate: moment(date).format("DD/MM/YYYY"),
                            });
                          }}
                          dateFormat={"dd/MM/yyyy"}
                          scrollableYearDropdown
                          customInput={<CustomDueDate />}
                          showPopperArrow={false}
                        />
                      </div>
                    </div>
                    <div>
                      <button
                        type="button"
                        onClick={handleAddvalue}
                        id="combo_2"
                        aria-label="Aria Name"
                      >
                        <svg
                          className="h-6  text-grey"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                ) : formData?.duedate ? (
                  <div className="flex flex-row justify-between items-center mb-2 ">
                    <div
                      id="creationDate"
                      style={{
                        color: "#008065",
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      className="flex-1  pr-2 whitespace-nowrap"
                    >
                      Due Date
                    </div>
                    <div className=" flex-1 text-right relative ml-5">
                      <div>
                        <DatePicker
                          // style={{ fill: "red !important" }}
                          selected={dueDate}
                          // onChange={(date) => setDueDate(date)}
                          onChange={(date) => {
                            // setDueDate(date);
                            setFormData({
                              ...formData,
                              duedate: moment(date).format("DD/MM/YYYY"),
                            });
                          }}
                          dateFormat={"dd/MM/yyyy"}
                          scrollableYearDropdown
                          customInput={<CustomDueDate />}
                          showPopperArrow={false}
                        />
                      </div>
                    </div>
                    <div>
                      <button
                        type="button"
                        onClick={handleAddvalue}
                        id="combo_2"
                        aria-label="Aria Name"
                      >
                        <svg
                          className="h-6  text-grey"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>

                ) : (
                  <>
                    <div className="flex flex-row justify-between items-center mb-2">
                      <div
                        style={{
                          color: "#008065",
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 700,
                        }}
                        // className=" flex-1"
                        className={
                          !isViewMode
                            ? "flex-1"
                            : "text-left flex-1 md:text-right md:-ml-44 pr-2"
                        }
                      ></div>
                      <div className="font-title flex-1 text-right relative">
                        <div>
                          <button
                            type="button"
                            className="rounded-xl font-bold text-[#008065] flex flex-row items-center justify-center md:h-10 text-sm h-8  block w-full pr-3 whitespace-nowrap "
                            onClick={handleAddvalue}
                          >
                            <PlusCircleIcon
                              style={IconStyle}
                              className="h-5 w-5"
                            />
                            Add Due Date
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <hr className="mx-8" style={{ borderColor: "#008065" }} />
          {/* Customer Billing Info */}
          <div className={"flex flex-col sm:flex-row pt-3 px-8"}>
            <div className="flex-1 md:mx-2">
              <div
                style={{
                  color: "#008065",
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
              >
                Company Details (Billed By)
              </div>
              <div className="flex flex-row items-center mb-2">
                <div
                  className=" flex-1 required mt-4"
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                >
                  <span>Name</span>
                </div>
                <div className="font-title flex-1 text-left mt-3">
                  <>
                    <div>
                      <input
                        autoComplete="nope"
                        placeholder="Company Name"
                        className={
                          "border-2 focus:outline-none rounded-[7px]  px-2 h-9   w-48 lg:w-64 xl:w-64 md:w-48 xl:w-48  2xl:w-11/12"
                        }
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                          borderColor: companyErrors.companyName ? "#ff0000" : "#008065 ",
                        }}
                        name="companyName"
                        value={formData.companyName}
                        onChange={(e) => handleChange(e)}
                      />
                      {companyErrors.companyName && (
                        <p
                          style={{
                            fontFamily: "poppins",
                            fontWeight: 700,
                            color: "#ff0000",
                          }}
                          className="text-red-400 text-xs"
                        >
                          {companyErrors.companyName}
                        </p>
                      )}
                    </div>
                  </>
                </div>
              </div>

              <div className="flex flex-row justify-between items-center mb-2">
                <div
                  className=" flex-1"
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                >
                  Address
                </div>
                <div className="font-title flex-1 text-left">
                  <input
                    autoComplete="nope"
                    placeholder=" Address (Optional)"
                    className={
                      "border-2 focus:outline-none rounded-[7px] px-2 h-9  w-48 lg:w-64 xl:w-64 md:w-48 xl:w-48  2xl:w-11/12"
                    }
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                      borderColor: "#008065",
                    }}
                    name="companyAddress"
                    value={formData.companyAddress}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>

              <div className="flex flex-row items-center mb-2">
                <div
                  className=" flex-1"
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                >
                  City
                </div>
                <div className="font-title flex-1 text-left">
                  <div>
                    <input
                      autoComplete="nope"
                      placeholder="City (Optional)"
                      className={
                        "border-2 focus:outline-none rounded-[7px] px-2 h-9  w-48 lg:w-64 xl:w-64 md:w-48 xl:w-48  2xl:w-11/12"
                      }
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                        borderColor: "#008065",
                      }}
                      name="companyCity"
                      value={formData.companyCity}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-row items-center mb-2">
                <div
                  className=" flex-1"
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                >
                  State
                </div>
                <div className="font-title flex-1 text-left">
                  <div>
                    <input
                      autoComplete="nope"
                      placeholder="State (Optional)"
                      className={
                        "border-2 focus:outline-none rounded-[7px] px-2 h-9  w-48 lg:w-64 xl:w-64 md:w-48 xl:w-48  2xl:w-11/12"
                      }
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                        borderColor: "#008065",
                      }}
                      name="companyState"
                      value={formData.companyState}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-row items-center mb-2">
                <div
                  className=" flex-1 mb-2"
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                >
                  Postal Code
                </div>
                <div className="font-title flex-1 text-left">
                  <div>
                    <input
                      type="number"
                      autoComplete="nope"
                      placeholder="Postalcode (Optional)"
                      className={
                        "border-2 focus:outline-none rounded-[7px] px-2 h-9 w-48 lg:w-64 xl:w-64 md:w-48 xl:w-48  2xl:w-11/12"
                      }
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 400,
                        borderColor: formData.isValidPostalCode
                          ? "#008065"
                          : "#FF0000",
                      }}
                      name="companyPostalCode"
                      value={formData.companyPostalCode}
                      onChange={(e) => handleChange(e)}
                    />
                    {formData.errors?.companyPostalCodeErrorMessage && (
                      <span
                        style={{
                          fontFamily: "poppins",
                          fontWeight: 700,
                          color: "#ff0000",
                        }}
                        className="text-red-400 text-xs">
                        {formData.errors.companyPostalCodeErrorMessage}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
                <div className="flex mb-2">
                  {addMobile ? (
                    <>
                      <div className="font-title flex-1 text-left">
                        <div className="font-title flex-1 ">
                          <input
                            type="number"
                            autoComplete="nope"
                            placeholder="Mobile (Optional)"
                            className={
                              "border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full  xl:w-64 md:w-36 xl:w-48 w-full 2xl:w-full"
                            }
                            style={{
                              fontFamily: "poppins",
                              fontSize: "14px",
                              fontWeight: 400,
                              borderColor:
                                formData.companyMobile.length <= 10
                                  ? "#008065"
                                  : "#ff0000",
                            }}
                            maxLength={10}
                            name="companyMobile"
                            value={formData.companyMobile}
                            // onChange={(e) => handleChange(e)}
                            onChange={(e) => {
                              const re = /^[0-9\b]+$/;
                              if (re.test(e.target.value) || (e.target.value == "")) {
                                handleChange(e)
                              }
                            }}
                            onInput={(event) => {
                              event.currentTarget.value =
                                event.currentTarget.value.slice(
                                  0,
                                  event.currentTarget.maxLength
                                );
                              if (event.currentTarget.value.length === 10) {
                                event.currentTarget.style.borderColor =
                                  "#008065";
                              } else if (event.currentTarget.value === "") {
                                event.currentTarget.style.borderColor =
                                  "#008065";

                              } else {
                                event.currentTarget.style.borderColor = "#ff0000";
                              }
                            }}
                          />
                          {formData.errors?.companyMobileErrorMessage && (
                            <span
                              style={{
                                fontFamily: "poppins",
                                fontWeight: 700,
                                color: "#ff0000",
                              }}
                              className="text-red-400 text-xs">
                              {formData.errors.companyMobileErrorMessage}
                            </span>
                          )}
                        </div>
                      </div>
                      <button
                        className="text-left"
                        onClick={() => handleAddMobile("addTrueMobile")}
                      >
                        <svg
                          className="h-6 mr-3  text-grey"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </button>
                    </>
                  ) : (
                    <>
                      <div
                        className=" flex-1"
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 700,
                        }}
                      >
                        <button
                          type="button"
                          className="rounded-xl font-bold text-[#008065] flex flex-row  md:h-10 text-sm h-8   block  w-full "
                          onClick={() => handleAddMobile("addMobile")}
                        >
                          <MobileIcon
                            style={IconStyle}
                            className="h-5 w-5 mx-1"
                          />
                          <span className=" text-[#008065] font-bold text-sm ">
                            Add Mobile
                          </span>
                        </button>
                      </div>
                    </>
                  )}
                </div>

                <div className="flex mb-2">
                  {addEmail ? (
                    <>
                      <div className="font-title flex-1 text-left">
                        <input
                          autoComplete="nope"
                          placeholder="Email (Optional)"
                          className={
                            "border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full   xl:w-64 md:w-36 xl:w-48 w-full 2xl:w-full "
                          }
                          style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 400,
                            borderColor: formData.isValidEmail
                              ? "#008065"
                              : "#FF0000",
                          }}
                          name="companyEmail"
                          value={formData.companyEmail}
                          onChange={(e) => handleChange(e)}
                        />
                        {formData.errors?.companyEmailErrorMessage && (
                          <span
                            style={{
                              fontFamily: "poppins",
                              fontWeight: 700,
                              color: "#ff0000",
                            }}
                            className="text-red-400 text-xs">
                            {formData.errors.companyEmailErrorMessage}
                          </span>
                        )}
                      </div>
                      <button
                        type="button"
                        onClick={() => handleAddEmail("addTrueEmail")}
                      >
                        <svg
                          className="h-6 mr-3  text-grey"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </button>
                    </>
                  ) : (
                    <>
                      <div
                        className=" flex-1"
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 700,
                        }}
                      >
                        <button
                          type="button"
                          className="rounded-xl font-bold text-[#008065] flex flex-row  md:h-10 text-sm h-8   block w-48 "
                          onClick={() => handleAddEmail("addEmail")}
                        >
                          <EmailIcon style={IconStyle} className="h-5 w-5 " />
                          Add Email
                        </button>
                      </div>
                    </>
                  )}
                </div>

                <div className="flex mb-2  ">
                  {addPan ? (
                    <>
                      <div className="font-title flex-1 text-left">
                        <div>
                          <input
                            autoComplete="nope"
                            placeholder="PanNumber (Optional)"
                            className={
                              "border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full  xl:w-64 md:w-36 xl:w-48 w-full 2xl:w-full"
                            }
                            style={{
                              fontFamily: "poppins",
                              fontSize: "14px",
                              fontWeight: 400,
                              borderColor: formData.isValidPan
                                ? "#008065"
                                : "#FF0000",
                            }}
                            name="companyPan"
                            value={formData.companyPan}
                            onChange={(e) => handleChangeCap(e)}
                          />
                          {formData.errors?.companyPanErrorMessage && (
                            <span
                              style={{
                                fontFamily: "poppins",
                                fontWeight: 700,
                                color: "#ff0000",
                              }}
                              className="text-red-400 text-xs">
                              {formData.errors.companyPanErrorMessage}
                            </span>
                          )}
                        </div>
                      </div>
                      <button
                        type="button"
                        onClick={() => handleAddPan("addTruepan")}
                      >
                        <svg
                          className="h-6 mr-3 text-grey"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </button>
                    </>
                  ) : (
                    <>
                      <div
                        className=" flex-1"
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 700,
                        }}
                      >
                        <button
                          type="button"
                          className="rounded-xl font-bold text-[#008065] flex flex-row  md:h-10 text-sm h-8   block w-48 "
                          onClick={() => handleAddPan("addPan")}
                        >
                          <PlusCircleIcon
                            style={IconStyle}
                            className="h-5 w-5"
                          />
                          Add PAN
                        </button>
                      </div>
                    </>
                  )}
                </div>

                <div className="flex mb-2">
                  {addGst ? (
                    <>
                      <div className="font-title flex-1 text-left">
                        <div>
                          <input
                            autoComplete="nope"
                            placeholder="GST (Optional)"
                            className={
                              "border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full xl:w-64 md:w-36 xl:w-48 w-full 2xl:w-full "
                            }
                            style={{
                              fontFamily: "poppins",
                              fontSize: "14px",
                              fontWeight: 400,
                              borderColor: formData.isValidGst
                                ? "#008065"
                                : "#FF0000",
                            }}
                            name="companygst"
                            value={formData.companygst}
                            onChange={(e) => handleChangeCap(e)}
                          />
                          {formData.errors?.companygstErrorMessage && (
                            <span
                              style={{
                                fontFamily: "poppins",
                                fontWeight: 700,
                                color: "#ff0000",
                              }}
                              className="text-red-400 text-xs">
                              {formData.errors.companygstErrorMessage}
                            </span>
                          )}
                        </div>
                      </div>
                      <button
                        type="button"
                        onClick={() => handleAddGst("addtruegst")}
                      >
                        <svg
                          className="h-6 mr-3 text-grey"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </button>
                    </>
                  ) : (
                    <>
                      <div
                        className=" flex-1"
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 700,
                        }}
                      >
                        <button
                          type="button"
                          className="rounded-xl font-bold text-[#008065] flex flex-row  md:h-10 text-sm h-8   block w-48 "
                          onClick={() => handleAddGst("addGst")}
                        >
                          <PlusCircleIcon
                            style={IconStyle}
                            className="h-5 w-5"
                          />
                          Add GST
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="  flex-1 md:mx-2">
              <div
                style={{
                  color: "#008065",
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
              >
                Client Details (Billed To)
              </div>
              <div className="flex flex-row justify-between items-center mb-2">
                <div
                  className="flex-1 required mt-4 "
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                >
                  <span>Name</span>
                </div>
                <div className="font-title flex-1 text-left mt-3">
                  <input
                    autoComplete="nope"
                    placeholder="Client Name"
                    className={
                      "border-2 focus:outline-none rounded-[7px] px-2 h-9  w-48 lg:w-64 xl:w-64 md:w-48 xl:w-48  2xl:w-11/12"
                    }
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                      borderColor: clientErrors.clientName ? "#ff0000" : "#008065",
                    }}
                    name="clientName"
                    value={formData.clientName}
                    onChange={(e) => handleChange(e)}
                  />
                  {clientErrors.clientName && (
                    <p
                      style={{
                        fontFamily: "poppins",
                        fontWeight: 700,
                        color: "#ff0000",
                      }}
                      className="text-red-400 text-xs"
                    >
                      {clientErrors.clientName}
                    </p>
                  )}
                </div>
              </div>

              <div className="flex flex-row justify-between items-center mb-2">
                <div
                  className=" flex-1 mt-1 mb-2"
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                >
                  
                  Address
                </div>
                <div className="font-title flex-1 text-left">
                  <input
                    autoComplete="nope"
                    placeholder="Address (Optional)"
                    className={
                      "border-2 focus:outline-none rounded-[7px] px-2 h-9  w-48 lg:w-64 xl:w-64 md:w-48 xl:w-48  2xl:w-11/12 "
                    }
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                      borderColor: "#008065",
                    }}
                    name="clientAddress"
                    value={formData.clientAddress}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>

              <div className="flex flex-row justify-between items-center mb-2">
                <div
                  className="flex-1"
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                >
                  City
                </div>
                <div className="font-title flex-1 text-left ">
                  <input
                    autoComplete="nope"
                    placeholder="City (Optional)"
                    className={
                      "border-2 focus:outline-none rounded-[7px] px-2 h-9  w-48 lg:w-64 xl:w-64 md:w-48 xl:w-48  2xl:w-11/12"
                    }
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                      borderColor: "#008065",
                    }}
                    maxLength={10}
                    name="clientCity"
                    value={formData.clientCity}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>

              <div className="flex flex-row justify-between items-center mb-2">
                <div
                  className="flex-1"
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                >
                  State
                </div>
                <div className="font-title flex-1 text-left ">
                  <input
                    autoComplete="nope"
                    placeholder="State (Optional)"
                    className={
                      "border-2 focus:outline-none rounded-[7px] px-2 h-9  w-48 lg:w-64 xl:w-64 md:w-48 xl:w-48  2xl:w-11/12"
                    }
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                      borderColor: "#008065",
                    }}
                    maxLength={10}
                    name="clientState"
                    value={formData.clientState}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>

              <div className="flex flex-row justify-between items-center mb-2">
                <div
                  className="flex-1 mb-2"
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                >
                  Postal Code
                </div>
                <div className="font-title flex-1 text-left ">
                  <input
                    type="number"
                    autoComplete="nope"
                    placeholder="Postalcode (Optional)"
                    className={
                      "border-2 focus:outline-none rounded-[7px] px-2 h-9  w-48 lg:w-64 xl:w-64 md:w-48 xl:w-48  2xl:w-11/12"
                    }
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                      borderColor: formData.isclientPostalCode
                        ? "#008065"
                        : "#FF0000",
                    }}
                    name="clientPostalCode"
                    value={formData.clientPostalCode}
                    onChange={(e) => handleChange(e)}
                  />
                  {formData.errors?.clientPostalCodeErrorMessage && (
                    <span
                      style={{
                        fontFamily: "poppins",
                        fontWeight: 700,
                        color: "#ff0000",
                      }}
                      className="text-red-400 text-xs">
                      {formData.errors.clientPostalCodeErrorMessage}
                    </span>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
                <div className="flex mb-2">
                  {addclientMobile ? (
                    <>
                      <div className="font-title flex-1 text-left ">
                        <input
                          type="number"
                          autoComplete="nope"
                          placeholder="Mobile (Optional)"
                          className={
                            "border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full 2xl:w-full xl:w-64 md:w-36 sm:w-full"
                          }
                          style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 400,
                            borderColor:
                              formData.clientMobile.length <= 10
                                ? "#008065"
                                : "#ff0000",
                          }}
                          maxLength={10}
                          name="clientMobile"
                          value={formData.clientMobile}
                          onChange={(e) => {
                            const re = /^[0-9\b]+$/;
                            if (re.test(e.target.value) || (e.target.value == "")) {
                              handleChange(e)
                            }
                          }}
                          onInput={(event) => {
                            event.currentTarget.value =
                              event.currentTarget.value.slice(
                                0,
                                event.currentTarget.maxLength
                              );
                            if (event.currentTarget.value.length === 10) {
                              event.currentTarget.style.borderColor =
                                "#008065";
                            } else if (event.currentTarget.value === "") {
                              event.currentTarget.style.borderColor =
                                "#008065";

                            } else {
                              event.currentTarget.style.borderColor = "#ff0000";
                            }
                          }}
                        />
                        {formData.errors?.clientMobileErrorMessage && (
                          <span
                            style={{
                              fontFamily: "poppins",
                              fontWeight: 700,
                              color: "#ff0000",
                            }}
                            className="text-red-400 text-xs">
                            {formData.errors.clientMobileErrorMessage}
                          </span>
                        )}
                      </div>
                      <button
                        type="button"
                        onClick={() => clientMobile("addtTrueClientno")}
                      >
                        <svg
                          className="h-6 mr-3 text-grey"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </button>
                    </>
                  ) : (
                    <>
                      <div
                        className=" flex-1"
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 700,
                        }}
                      >
                        <button
                          type="button"
                          className="rounded-xl font-bold text-[#008065] flex flex-row  md:h-10 text-sm h-8   block w-48 "
                          onClick={() => clientMobile("addClientMobile")}
                        >
                          <MobileIcon
                            style={IconStyle}
                            className="h-5 w-5 mx-1"
                          />
                          <span className=" text-[#008065] font-bold text-sm ">
                            Add Mobile
                          </span>
                        </button>
                      </div>
                    </>
                  )}
                </div>

                <div className="flex mb-2">
                  {addClientEmail ? (
                    <>
                      <div className="font-title flex-1 text-left">
                        <input
                          autoComplete="nope"
                          placeholder="Email (Optional)"
                          className={
                            "border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full 2xl:w-full xl:w-64 md:w-36 sm:w-full"
                          }
                          style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 400,
                            borderColor: formData.isClientEmail
                              ? "#008065"
                              : formData.clientEmail
                                ? "red"
                                : "green",
                          }}
                          name="clientEmail"
                          value={formData.clientEmail}
                          onChange={(e) => handleChange(e)}
                        />
                        {formData.errors?.clientEmailErrorMessage && (
                          <span
                            style={{
                              fontFamily: "poppins",
                              fontWeight: 700,
                              color: "#ff0000",
                            }}
                            className="text-red-400 text-xs">
                            {formData.errors.clientEmailErrorMessage}
                          </span>
                        )}
                      </div>
                      <button
                        type="button"
                        onClick={() =>
                          handleClientAddEmail("addTrueClientEmail")
                        }
                      >
                        <svg
                          className="h-6 mr-3 text-grey"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </button>
                    </>
                  ) : (
                    <>
                      <div
                        className=" flex-1"
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 700,
                        }}
                      >
                        <button
                          type="button"
                          className="rounded-xl font-bold text-[#008065] flex flex-row  md:h-10 text-sm h-8   block w-48 "
                          onClick={() => handleClientAddEmail("addClientEmail")}
                        >
                          <EmailIcon style={IconStyle} className="h-5 w-5 " />
                          Add Email
                        </button>
                      </div>
                    </>
                  )}
                </div>

                <div className="flex mb-2">
                  {addClientPan ? (
                    <>
                      <div className="font-title flex-1 text-left ">
                        <input
                          autoComplete="nope"
                          placeholder="PanNumber (Optional)"
                          className={
                            "border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full  2xl:w-full xl:w-64 md:w-36 sm:w-full"
                          }
                          style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 400,
                            borderColor: formData.isClientPan
                              ? "#008065"
                              : formData.clientPan
                                ? "red"
                                : "green",
                          }}
                          name="clientPan"
                          value={formData.clientPan}
                          onChange={(e) => handleChangeCap(e)}
                        />
                        {formData.errors?.clientPanErrorMessage && (
                          <span
                            style={{
                              fontFamily: "poppins",
                              fontWeight: 700,
                              color: "#ff0000",
                            }}
                            className="text-red-400 text-xs">
                            {formData.errors.clientPanErrorMessage}
                          </span>
                        )}
                      </div>
                      <button
                        type="button"
                        onClick={() => handleClientAddPan("addTrueClientPanNo")}
                      >
                        <svg
                          className="h-6 mr-3 text-grey"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </button>
                    </>
                  ) : (
                    <>
                      <div
                        className=" flex-1"
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 700,
                        }}
                      >
                        <button
                          type="button"
                          className="rounded-xl font-bold text-[#008065] flex flex-row  md:h-10 text-sm h-8   block w-48 "
                          onClick={() => handleClientAddPan("addClientPan")}
                        >
                          <PlusCircleIcon
                            style={IconStyle}
                            className="h-5 w-5"
                          />
                          Add PAN
                        </button>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex mb-2">
                  {addClientGst ? (
                    <>
                      <div className="font-title flex-1 text-left ">
                        <input

                          autoComplete="nope"
                          placeholder="GST (Optional)"
                          className={
                            "border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full 2xl:w-full xl:w-64  md:w-36 sm:w-full"
                          }
                          style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 400,
                            borderColor: formData.isClientGst
                              ? "#008065"
                              : "#FF0000",
                          }}
                          name="clientgst"
                          value={formData.clientgst}
                          onChange={(e) => handleChangeCap(e)}
                        />

                        {formData.errors?.clientgstErrorMessage && (
                          <span
                            style={{
                              fontFamily: "poppins",
                              fontWeight: 700,
                              color: "#ff0000",
                            }}
                            className="text-red-400 text-xs">
                            {formData.errors.clientgstErrorMessage}
                          </span>
                        )}
                      </div>
                      <button
                        type="button"
                        onClick={() => handleClientAddGst("addTrueClientGst")}
                      >
                        <svg
                          className="h-6 mr-3 text-grey"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </button>
                    </>
                  ) : (
                    <>
                      <div
                        className=" flex-1"
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 700,
                        }}
                      >
                        <button
                          type="button"
                          className="rounded-xl font-bold text-[#008065] flex flex-row  md:h-10 text-sm h-8   block  w-48 "
                          onClick={() => handleClientAddGst("addClientGst")}
                        >
                          <PlusCircleIcon
                            style={IconStyle}
                            className="h-5 w-5"
                          />
                          Add GST
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Customer Billing Info Finished */}
          <hr className="mx-8 mt-2" style={{ borderColor: "#008065" }} />

          {/* Products */}
          <div className="py-2 px-4">
            <div
              className={
                "hidden sm:flex rounded-lg invisible sm:visible w-full flex-col sm:flex-row px-4 py-2 text-white"
              }
              style={{ backgroundColor: "#008065" }}
            >
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={" w-full sm:w-1/4 text-center sm:pr-10"}
              >
                <span className="inline-block">Products</span>
              </div>
              {/* <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={" w-full sm:w-1/4 text-center sm:pr-10"}
              >
                <span className="inline-block">HSN</span>
              </div> */}
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={" w-full sm:w-1/4 text-center sm:pr-10"}
              >
                Rate
              </div>
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={" w-full sm:w-1/4 text-center sm:pr-10"}
              >
                Quantity
              </div>
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={"  w-full sm:w-1/4 text-right sm:pr-10"}
              >
                Total
              </div>
            </div>

            {prodcutRows?.map((productrow: any, index: any) => (
              <div
                key={index}
                className={
                  "flex flex-col sm:flex-row rounded-lg sm:visible w-full px-4 py-2 items-center relative mb-2" +
                  (index % 2 !== 0 ? " bg-gray-50 " : "")
                }
              >
                <div
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                  className={
                    " w-full sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-3 sm:mb-0 md:mb-0 lg:mb-0 xl:mb-0 2xl:mb-0"
                  }
                >
                  <span className="sm:hidden w-1/2 flex flex-row items-center">
                    Products
                  </span>

                  <span className={"inline-block w-1/2 sm:w-full mb-1 sm:mb-0"}>
                    <div className="font-title flex-1 text-left">
                      <input
                        autoComplete="nope"
                        value={productrow.prodcut ? productrow.prodcut : ""}
                        placeholder="Product Name"
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                          borderColor: !prodcutErrors[index]?.prodcut && productError ? "red" : "#00A787",
                        }}
                        className={defaultInputSmStyle + " text-right"}
                        onChange={(event) =>
                          handleInputChange(
                            index,
                            "prodcut",
                            event.target.value
                          )
                        }
                      />
                      {!prodcutErrors[index]?.prodcut && productError && (
                        <p
                          style={{
                            fontFamily: "poppins",
                            fontWeight: 700,
                            color: "#ff0000",
                          }}
                          className="absolute text-red-400 text-xs">
                          Product is required
                        </p>
                      )}
                    </div>
                  </span>
                </div>
                {/* <div
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                  className={
                    " w-full sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-3"
                  }
                >
                  <span className="sm:hidden w-1/2 flex flex-row items-center">
                    HSN
                  </span>
                  <span className={"inline-block w-1/2 sm:w-full mb-1 sm:mb-0"}>
                    <div className="font-title flex-1 text-left">
                      <input
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                          borderColor: "#00A787",
                        }}
                        autoComplete="nope"
                        value={productrow.hsn}
                        onChange={(event) =>
                          handleInputChange(index, "hsn", event.target.value)
                        }
                        placeholder="HSN"
                        type="number"
                        className={
                          defaultInputSmStyle +
                          " text-right hide_arrow remove-spinner"
                        }
                      />
                  
                    </div>
                  </span>
                </div> */}
                <div
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                  className={
                    " w-full sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-3 sm:mb-0 md:mb-0 lg:mb-0 xl:mb-0 2xl:mb-0"
                  }
                >
                  <span className="sm:hidden w-1/2 flex flex-row items-center">
                    Rate
                  </span>

                  <span className={"inline-block w-1/2 sm:w-full mb-1 sm:mb-0"}>
                    <div className="font-title flex-1 text-left">
                      <input
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                          borderColor: !prodcutErrors[index]?.rate && productError ? "red" : "#00A787",
                        }}
                        autoComplete="nope"
                        value={productrow.rate}
                        onChange={(event) =>
                          handleInputChange(index, "rate", event.target.value)
                        }
                        placeholder="Rate"
                        type="number"
                        className={
                          defaultInputSmStyle +
                          " text-right hide_arrow remove-spinner"
                        }
                      />
                      {!prodcutErrors[index]?.rate && productError && (
                        <p
                          style={{
                            fontFamily: "poppins",
                            fontWeight: 700,
                            color: "#ff0000",
                          }}
                          className="absolute text-red-400 text-xs">
                          Rate is required
                        </p>
                      )}
                    </div>
                  </span>
                </div>
                <div
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                  className={
                    " w-full sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-3 sm:mb-0 md:mb-0 lg:mb-0 xl:mb-0 2xl:mb-0"
                  }
                >
                  <span className="sm:hidden w-1/2 flex flex-row items-center">
                    Quantity
                  </span>
                  <span className={"inline-block w-1/2 sm:w-full mb-1 sm:mb-0"}>
                    <div className="font-title flex-1 text-left">
                      <input
                        autoComplete="nope"
                        type="number"
                        value={productrow.qty}
                        onChange={(event) =>
                          handleInputChange(index, "qty", event.target.value)
                        }
                        placeholder="Quantity"
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                          borderColor: !prodcutErrors[index]?.qty && productError ? "red" : "#00A787",
                        }}
                        className={
                          defaultInputSmStyle + " text-right remove-spinner"
                        }
                      />
                      {!prodcutErrors[index]?.qty && productError && (
                        <p
                          style={{
                            fontFamily: "poppins",
                            fontWeight: 700,
                            color: "#ff0000",
                          }}
                          className="absolute text-red-400 text-xs">
                          Quantity is required
                        </p>
                      )}
                    </div>
                  </span>
                </div>
                <div
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                  className={
                    " w-full sm:w-1/4 text-right sm:pr-9 flex flex-row sm:block"
                  }
                >
                  <span className="sm:hidden w-1/2 flex flex-row items-center">
                    Total
                  </span>
                  <span className={"inline-block w-1/2 sm:w-full"}>
                    <NumberFormat value={productrow?.amount} />
                  </span>
                </div>

                <div
                  onClick={() => removeRow(index, productrow)}
                  className="w-full sm:w-10 sm:absolute sm:right-0"
                >
                  <div className="w-full text-[#9C0000] font-semibold font-title h-8 sm:h-8 sm:w-8 cursor-pointer rounded-2xl bg-[#FFE0E0] flex justify-center items-center text-center">
                    <DeleteIcon className="h-4 w-4" style={IconStyle} />
                    <span className="block sm:hidden">Delete Product</span>
                  </div>
                </div>
              </div>
            ))}

            {/* Add Product Actions */}

            <div
              style={{ fontFamily: "poppins", fontSize: "14px" }}
              className="flex flex-col sm:flex-row rounded-lg sm:visible w-full px-4 py-2 items-center sm:justify-end"
            >
              <div className=" w-full sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1">
                <button
                  type="button"
                  className="rounded-xl font-bold text-white flex flex-row items-center justify-center md:h-10 text-sm h-8 px-2  block w-full "
                  style={{ backgroundColor: "#008065" }}
                  onClick={addRow}
                >
                  {/* <PlusCircleIcon
                                                        style={IconStyle}
                                                        className="h-5 w-5"
                                                    /> */}
                  Add New Product
                </button>
              </div>
              <div className="font-title w-full sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1"></div>
            </div>
            {/* Add Product Actions Finished*/}

            {/* Subtotal Start */}
            <div
              className={
                "flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-4 py-1 items-center "
              }
            >
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={
                  "font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
                }
              >
                Subtotal
              </div>
              <div
                className={
                  "font-title w-1/2 sm:w-1/4 text-right sm:pr-9 flex flex-row justify-end sm:block mb-1"
                }
              >
                <NumberFormat value={formData.subtotal} />
              </div>
            </div>
            {/* Subtotal Finished */}

            {/* Taxes */}
            {taxRows?.map((tax: any, index: any) => (
              <div
                key={`${index}_${tax.id}`}
                className={
                  "flex flex-col sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-4 py-1 items-center relative"
                }
              >
                <div
                  className={
                    "font-title w-full sm:w-3/5 text-right sm:pr-8 flex flex-row sm:block"
                  }
                >
                  <div className="sm:hidden w-1/3 flex flex-row items-center">
                    Tax Type
                  </div>

                  <div
                    className={
                      "w-2/3 sm:w-full 2xl:ml-24 2xl:pr-4 xl:ml-20  lg:ml-16 md:ml-14 md:pr-1 mb-1 sm:mb-0 flex flex-row items-center sm:justify-end input"
                    }
                  >
                    <div
                      className={
                        "w-1/2 sm:w-1/3 pr-1 2xl:pr-6 xl:pr-6 lg:pr-6 md:pr-6 sm:pr-1 input "
                      }
                    >
                      <input
                        autoComplete="nope"
                        value={tax.taxName ? tax.taxName : ""}
                        type={"text"}
                        placeholder="Tax Title %"
                        className={
                          "h-10 text-sm  flex-1 text-md px-2 block w-full 2xl:w-full xl:w-full lg:w-full md:w-full sm:w-full border-solid border-2 rounded-[7px] p-x2 block  w-full 2xl:w-full xl:w-full lg:w-full md:w-full sm:w-full  focus:outline-none " +
                          " text-right "
                        }
                        style={{ borderColor: "#008065" }}
                        onChange={(event) =>
                          handleInputTaxChange(
                            index,
                            "taxName",
                            event.target.value
                          )
                        }
                      />
                    </div>
                    <div
                      className={
                        "w-1/2 sm:w-1/3 relative flex flex-row items-center" +
                        " pr-4"
                      }
                    >
                      <>
                        <input
                          autoFocus
                          autoComplete="nope"
                          value={tax.taxPersent}
                          type={"number"}
                          placeholder={"Tax percentage %"}
                          className={
                            "h-10 text-sm  flex-1 text-md px-2 block  w-full 2xl:w-full xl:w-full lg:w-full md:w-full sm:w-full border-solid border-2 rounded-[7px] p-x2 block  w-full 2xl:w-full xl:w-full lg:w-full md:w-full sm:w-full focus:outline-none " +
                            " text-right "
                          }
                          style={{ borderColor: "#008065" }}
                          onChange={(event) =>
                            handleInputTaxChange(
                              index,
                              "taxPersent",
                              event.target.value
                            )
                          }
                        />
                      </>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    "font-title w-full sm:w-1/4 text-right sm:pr-9 flex flex-row sm:block"
                  }
                >
                  <span className="sm:hidden w-1/2 flex flex-row items-center">
                    Amount
                  </span>

                  <span className={"inline-block w-1/2 sm:w-full"}>
                    <>
                      <div className="w-full">
                        <NumberFormat value={tax.taxAmount} />
                      </div>
                    </>
                  </span>
                </div>
                <div
                  className="w-full sm:w-10 sm:absolute sm:right-0"
                  onClick={() => removeTaxRow(index, tax)}
                >
                  <div className="w-full text-[#9C0000] font-semibold font-title h-8 sm:h-8 sm:w-8 cursor-pointer rounded-2xl bg-[#FFE0E0] flex justify-center items-center text-center">
                    <DeleteIcon className="h-4 w-4" style={IconStyle} />
                    <span className="block sm:hidden">Delete Tax</span>
                  </div>
                </div>
              </div>
            ))}
            {/* Discount */}
            {addDiscount && (
              <>
                <div
                  className={
                    "flex flex-col sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-4 py-1 items-center relative"
                  }
                >
                  <div
                    className={
                      "font-title w-full sm:w-3/5 text-right sm:pr-8 flex flex-row sm:block"
                    }
                  >
                    <div className="sm:hidden w-1/3 flex flex-row items-center">
                      Tax Type
                    </div>
                    <div
                      className={
                        "w-2/3 sm:w-full 2xl:ml-24 2xl:pr-4 xl:ml-20  lg:ml-16 md:ml-14 md:pr-1  mb-1 sm:mb-0 flex flex row   items-center sm:justify-end input"
                      }
                    >
                      <div
                        className={
                          "w-1/2 sm:w-1/3 pr-1 2xl:pr-6 xl:pr-6 lg:pr-6 md:pr-6 sm:pr-1 input "
                        }
                      >
                        <input
                          autoComplete="nope"
                          type={"text"}
                          defaultValue={"Discount (%)"}
                          className={
                            "h-10 text-sm  flex-1 text-md px-2 block  w-full 2xl:w-full xl:w-full lg:w-full md:w-full sm:w-full border-solid border-2 rounded-[7px] p-x2 block  w-full 2xl:w-full xl:w-full lg:w-full md:w-full sm:w-full focus:outline-none " +
                            " text-right "
                          }
                          style={{ borderColor: "#008065" }}
                          readOnly
                        />
                      </div>
                      <div
                        className={
                          "w-1/2 sm:w-1/3  relative flex flex-row items-center pr-3"
                        }
                      >
                        <>
                          <input
                            autoFocus
                            autoComplete="nope"
                            type={"number"}
                            placeholder="Percentage %"
                            className={
                              "h-10 text-sm  flex-1 text-md px-2 block  w-full 2xl:w-full xl:w-full lg:w-full md:w-full sm:w-full border-solid border-2 rounded-[7px] p-x2 block  w-full 2xl:w-full xl:w-full lg:w-full md:w-full sm:w-full focus:outline-none " +
                              " text-right "
                            }
                            style={{
                              borderColor: "#008065",
                              color: "black",
                            }}
                            name="discountprecentage"
                            value={addiscountvalue}
                            onInput={(e) => {
                              handleChangediscount(e);
                            }}
                          />
                          <span className="ml-1"></span>
                        </>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      "font-title w-full sm:w-1/4 text-right sm:pr-9 flex flex-row sm:block"
                    }
                  >
                    <span className="sm:hidden w-1/2 flex flex-row items-center">
                      Amount
                    </span>
                    <span className={"inline-block w-1/2 sm:w-full"}>
                      <>
                        <div className="w-full">
                          <NumberFormat value={formData.discount} />
                        </div>
                      </>
                    </span>
                  </div>
                  <div
                    className="w-full sm:w-10 sm:absolute sm:right-0"
                    onClick={() => handleAddDiscount("delete")}
                  >
                    <div className="w-full text-[#9C0000] font-semibold font-title h-8 sm:h-8 sm:w-8 cursor-pointer rounded-2xl bg-[#FFE0E0] flex justify-center items-center text-center">
                      <DeleteIcon className="h-4 w-4" style={IconStyle} />
                      <span className="block sm:hidden">Delete Discount</span>
                    </div>
                  </div>
                </div>
              </>
            )}
            {/* Discount Finished*/}
            {extrafeesRows?.map((tax: any, index: any) => (
              <div
                key={`${index}_${tax.id}`}
                className={
                  "flex flex-col sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-4 py-1 items-center relative"
                }
              >
                <div
                  className={
                    "font-title w-full sm:w-3/5 text-right sm:pr-8 flex flex-row sm:block"
                  }
                >
                  <div className="sm:hidden w-1/3 flex flex-row items-center">
                    Tax Type
                  </div>

                  <div
                    className={
                      "w-2/3 sm:w-full 2xl:ml-24 2xl:pr-4 xl:ml-20  lg:ml-16 md:ml-14 md:pr-1  mb-1 sm:mb-0 flex flex-row items-center sm:justify-end input"
                    }
                  >
                    <div
                      className={
                        "w-1/2 sm:w-1/3 pr-1 2xl:pr-6 xl:pr-6 lg:pr-6 md:pr-6 sm:pr-1 input "
                      }
                    >
                      <input
                        autoComplete="nope"
                        value={tax.exname ? tax.exname : "Extra Fees"}
                        type={"text"}
                        placeholder="Extra Fees Title "
                        className={
                          "h-10 text-sm  flex-1 text-md px-2 block w-full sm:w-full border-solid border-2 rounded-[7px] p-x2 focus:outline-none " +
                          " text-right "
                        }
                        style={{ borderColor: "#008065" }}
                        onChange={(event) =>
                          handleInputExtaraChange(
                            index,
                            "exname",
                            event.target.value
                          )
                        }
                      />
                    </div>
                    <div
                      className={
                        "w-1/2 sm:w-1/3 relative flex flex-row items-center" +
                        " pr-4"
                      }
                    >
                      <>
                        <input
                          autoFocus
                          autoComplete="nope"
                          value={tax.expercentage}
                          type={"number"}
                          placeholder={"Extra Fees Amount"}
                          className={
                            "h-10 text-sm  flex-1 text-md px-2 block w-full sm:w-full border-solid border-2 rounded-[7px] p-x2 focus:outline-none " +
                            " text-right "
                          }
                          style={{ borderColor: "#008065" }}
                          onChange={(event) =>
                            handleInputExtaraChange(
                              index,
                              "expercentage",
                              event.target.value
                            )
                          }
                        />
                      </>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    "font-title w-full sm:w-1/4 text-right sm:pr-9 flex flex-row sm:block"
                  }
                >
                  <span className="sm:hidden w-1/2 flex flex-row items-center">
                    Amount
                  </span>

                  <span className={"inline-block w-1/2 sm:w-full"}>
                    <>
                      <div className="w-full">
                        <NumberFormat value={tax.examount ? tax.examount : 0} />
                      </div>
                    </>
                  </span>
                </div>
                <div
                  className="w-full sm:w-10 sm:absolute sm:right-0"
                  onClick={() => removeExtaraRow(index, tax)}
                >
                  <div className="w-full text-[#9C0000] font-semibold font-title h-8 sm:h-8 sm:w-8 cursor-pointer rounded-2xl bg-[#FFE0E0] flex justify-center items-center text-center">
                    <DeleteIcon className="h-4 w-4" style={IconStyle} />
                    <span className="block sm:hidden">Delete Tax</span>
                  </div>
                </div>
              </div>
            ))}

            {/* Taxes Finished*/}


            {/* Add Tax Action */}

            <div
              style={{ fontFamily: "poppins", fontSize: "14px" }}
              className="flex flex-col sm:flex-row rounded-lg sm:visible w-full px-4 py-2 items-center sm:justify-end"
            >
              <div className=" w-full sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1">
                <button
                  type="button"
                  className="rounded-xl font-bold text-white flex flex-row items-center justify-center text-sm h-8 px-2  block w-full "
                  style={{ backgroundColor: "#008065" }}
                  onClick={addTaxRow}
                >
                  <TaxesIcon style={IconStyle} className="h-5 w-5" />
                  Add Taxes (%)
                </button>
              </div>

              <div
                style={{ fontFamily: "poppins", fontSize: "14px" }}
                className=" w-full sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1"
              >
                <button
                  type="button"
                  className="rounded-xl font-bold text-white flex flex-row items-center justify-center text-sm h-8 px-2  block w-full "
                  style={{ backgroundColor: "#008065" }}
                  onClick={() => handleAddDiscount("add")}
                >
                  <DollarIcon style={IconStyle} className="w-5 h-5" />
                  Add Discount
                </button>
              </div>

              <div
                style={{ fontFamily: "poppins", fontSize: "14px" }}
                className=" w-full sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1"
              >
                <button
                  type="button"
                  className="rounded-xl font-bold text-white flex flex-row items-center justify-center text-sm h-8 px-2  block w-full "
                  style={{ backgroundColor: "#008065" }}
                  onClick={addExtaraRow}
                >
                  {/* <DollarIcon style={IconStyle} className="w-5 h-5" /> */}
                  Add Extra Fee
                </button>
              </div>
            </div>
            {/* Add Tax Action Finished*/}

            {/* Subtotal Start */}
            <div
              className={
                "flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
              }
            >
              <div
                className={
                  "w-full sm:w-1/2 px-4 py-1 flex flex-row rounded-lg items-center"
                }
              // style={{ backgroundColor: "#008065" }}
              >
                <div
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                  className={
                    "  w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"
                  }
                >
                  Total
                </div>
                <div
                  className={
                    "font-title text-lg w-1/2 text-right sm:pr-9 flex flex-row justify-end sm:block items-center"
                  }
                >
                  <NumberFormat value={formData.toatalAmount} />
                </div>
              </div>
            </div>
            {/* Subtotal Finished */}

            <div
              style={{ fontFamily: "poppins", fontSize: "14px" }}
              className="flex flex-col sm:flex-row rounded-lg sm:visible w-full px-4 py-2 items-center sm:justify-end"
            >
              <div className=" w-full sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1"></div>

              <div
                style={{ fontFamily: "poppins", fontSize: "14px" }}
                className=" w-full sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1"
              >
                <button
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    backgroundColor: "#008065",
                  }}
                  className="rounded-xl font-bold text-white flex flex-row items-center justify-center text-sm h-8 px-2  block w-full "
                  type="button"
                  onClick={openChoosePreview}
                // disabled = {preViewdDis}
                >
                  {/* PreView */}
                  <SecurityIcon className="h-5 w-5 mr-1" />
                  {"PreView"}
                </button>
              </div>

              <div
                style={{ fontFamily: "poppins", fontSize: "14px" }}
                className=" w-full sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1"
              >
                <button
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    backgroundColor: "#008065",
                  }}
                  className="rounded-xl font-bold text-white flex flex-row items-center justify-center text-sm h-8 px-2  block w-full "
                  type="submit"
                >
                  <SecurityIcon className="h-5 w-5 mr-1" /> Download
                </button>
              </div>
            </div>
          </div>
          <DetailsPage />
        </form>
      </div>
      <Transition.Root show={viewOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={setOpenView}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all  mx-4 lg:w-8/12">
                  <HomePreview Invoice={formData} closeModal={closeModal} invoice={invoice} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default HomePage;
