import { useEffect, useState } from "react";
import VendorsPage from "../VendorsPage";
import VendorProducts from "../VendorProducts";
import { useDispatch } from "react-redux";
import { VendorAll } from "../../../redux/actions/vendorAction";


const VendorTab = () => {
  const User_id = sessionStorage.getItem("userInfo");
  const userId = JSON.parse(User_id as any);
  const dispatch = useDispatch()
  const [openTab, setOpenTab] = useState(1);
  const [vendorList, setVendorList] = useState([] as any);

  const fetchData = () => {
    dispatch(VendorAll(userId?.token?._id) as any).then((res: any) => {
      if (res) {
        setVendorList(res.payload);
      }
    });
  };

  useEffect(() => {
    if (userId?.token?._id) {
      fetchData();
    }
  }, []);
  return (
    <div>
      <div className="bg-[#E1E8E7] lg:h-[650px] xl:h-[650px] 2xl:h-[650px] h-screen  ">
        <div className=" ">
          <ul className="flex flex-wrap text-sm font-medium text-center text-[#008065]  dark:text-gray-400 ">
            <li className="-mb-px mr-1">
              <button
                style={{
                  borderBottom: openTab === 1 ? "2px solid #008065" : "",
                }}
                onClick={() => setOpenTab(1)}
                className={
                  "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg font-bold"
                }
              >
                Vendor Details
              </button>
            </li>
            <li className="mr-1">
              <button
                onClick={() => setOpenTab(2)}
                style={{
                  borderBottom: openTab === 2 ? "2px solid #008065" : "",
                }}
                className={
                  "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg font-bold"
                }
              >
                Vendor ProductList
              </button>
            </li>


          </ul>
        </div>
        <div className=" mt-6 ">
          <div className={openTab === 1 ? "block  " : "hidden"}>
            <VendorsPage vendorList={vendorList} fetchData={fetchData}/>
          </div>
          {openTab === 2 && (
            <div className={openTab === 2 ? "block" : "hidden"}>
              <VendorProducts vendorList={vendorList} fetchData={fetchData} />
            </div>
          )}

        </div>
      </div>
    </div>
  );
};

export default VendorTab;
