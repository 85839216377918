import {
  Box,
  IconButton
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuidv4 } from "uuid";

const ProductTables = ({
  productList,
  handleEditSection,
  handleDeactiveSection,
}: any) => {
  const [productListValues, setProductListValues] = useState([] as any);
  
  useEffect(() => {
    if (productList) {
      const value = productList.filter((item: any) => {
        return item.status === false ? "" : item;
      });

      setProductListValues(value);
    }
  }, [productList]);

  const [sortOrder, setSortOrder] = useState("asc");

  const handleSortChange = (newSortOrder: any) => {
    const sortedData = [...productListValues].sort((a, b) => {
      if (newSortOrder === "asc") {
        return a.product_name.localeCompare(b.product_name);
      } else {
        return b.product_name.localeCompare(a.product_name);
      }
    });
    setProductListValues(sortedData);
    setSortOrder(newSortOrder);
  };

  // ***********************************search******************************
  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    const filteredData = productList?.filter(
      (product: any) =>
        product?.product_id?.toLowerCase().includes(query) ||
        product?.product_name?.toLowerCase().includes(query) ||
        product?.vendor_name?.toLowerCase().includes(query)
    );
    setProductListValues(filteredData);
  };

  // Add unique ids to each row
  const rows = productListValues?.map((item: any, index: any) => ({
    id: uuidv4(),
    sl_No: index + 1,
    ...item,
  }));

  const columns: GridColDef[] = [
    {
      field: "sl_No",
      headerName: "Sl No",
      width: 130,
      editable: false,
    },
    {
      field: "product_id",
      headerName: "Product ID",
      width: 150,
      editable: false,
    },
    {
      field: "product_name",
      headerName: "Product Name",
      width: 180,
      editable: false,
    },
    {
      field: "hsn",
      headerName: "HSN/SAC",
      width: 140,
      editable: false,
      renderCell: (params: any) => (
        <>{params.row.hsn ? params.row.hsn : "N/A"}</>
      ),
    },
    {
      field: "price",
      headerName: "MRP",
      editable: false,
      width: 120,
      renderCell: (params: any) => (
        <>{Number(params?.row?.price)?.toFixed(2)}</>
      ),
    },
    {
      field: "sellingPrice",
      headerName: "Selling Price",
      editable: false,
      width: 180,
    },
    {
      field: "qty",
      headerName: "Qty",
      editable: false,
      width: 120,
    },
    {
      field: "units",
      headerName: "Types",
      editable: false,
      width: 120,
    },
    {
      field: "gst",
      headerName: "GST",
      editable: false,
      width: 120,
    },
    {
      field: "GstAmount",
      headerName: "GST Amount",
      editable: false,
      width: 160,
    },
    {
      field: "CgstPercentage",
      headerName: "CGST Tax",
      editable: false,
      width: 140,
    },
    {
      field: "CgstAmount",
      headerName: "CGST Amount",
      editable: false,
      width: 180,
    },
    {
      field: "SgstPercentage",
      headerName: "SGST Tax",
      editable: false,
      width: 140,
    },
    {
      field: "SgstAmount",
      headerName: "SGST Amount",
      editable: false,
      width: 180,
    },
    {
      field: "igstTax",
      headerName: "IGST Tax",
      editable: false,
      width: 130,
    },
    {
      field: "igstAmount",
      headerName: "IGST Amount",
      editable: false,
      width: 160,
    },
    {
      field: "discount",
      headerName: "Discount",
      editable: false,
      width: 140,
      renderCell: (params: any) => (
        <>{params.row.discount ? params.row.discount : "N/A"}</>
      ),
    },
    {
      field: "action",
      headerName: "Actions",
      editable: false,
      width: 140,
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={() => handleEditSection(params.row)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#008065"
              className="w-5 h-5 cursor-pointer"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
              />
            </svg>
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() => handleDeactiveSection(params.row)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#008065"
              className="w-5 h-5  cursor-pointer"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
              />
            </svg>
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <div>
      <div className="bg-[#F1F7F6] rounded-xl px-3 py-3 mx-2">
        <div className="flex justify-between flex-col lg:flex-row xl:flex-row 2xl:flex-row md:flex-row">
          <div className="flex items-center">
            <div className="relative flex mb-2">
              <input
                onChange={(e) => onSearch(e)}
                type="text"
                id="simple-search"
                className="w-44  bg-gray-50 border border-[#008065] text-gray-900 text-sm rounded-lg p-2.5 pl-10 focus:outline-none"
                placeholder="Search"
                required
              />
              <span className="absolute inset-y-0 left-2 flex items-center pl-2 text-[#008065]">
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div className="block mb-2  ">
            <div className="relative flex items-center ">
              <select
                defaultValue={""}
                id="sortDropdown"
                className="block w-[11rem] h-10 p-2 text-sm  border border-[#008065] rounded-lg bg-gray-50  dark:bg-gray-700 dark:border-gray-600 "
                onChange={(e) => handleSortChange(e.target.value)}
              >
                <option value="" disabled selected hidden>
                  Sort By
                </option>
                <option value="asc">Product Name A to Z</option>
                <option value="desc">Product Name Z to A</option>
              </select>
            </div>
          </div>
        </div>
        <Box
          sx={{
            height: 400,
            width: "100%",
            // maxHeight: "60vh",
            overflowX: "auto",
            overflowY: "auto",
          }}
        >
          <DataGrid
            sx={{
              color: "#000",
              fontSize: "14px",
              fontWeight: 500,
              ".MuiDataGrid-columnHeaderTitle.css-t89xny-MuiDataGrid-columnHeaderTitle":
                {
                  backgroundColor: "#fff",
                  color: "#008060 !important",
                  fontWeight: 600,
                  fontSize: "14px",
                },
              "& .MuiDataGrid-checkboxInput": {
                color: "#008060 !important",
              },
              ".MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
                fontWeight: 600,
                fontSize: "14px",
              },
            }}
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25, 50]}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </Box>
      </div>
    </div>
  );
};

export default ProductTables;
