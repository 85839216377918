import { GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { FiPlusCircle } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { SalesgettMethod } from "../redux/actions/SalesAction";
import DashBoardTable from "./DashBoardTable";

const SalesTabe = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stockdetails = useSelector((state: any) => state.SalesList);
  const { saleslist } = stockdetails;

  const [salseDetails, setSalseDetails] = useState([] as any);

  useEffect(() => {
    const fetchData = () => {
      dispatch(SalesgettMethod() as any).then((response: any) => {
        setSalseDetails(response?.payload.reverse());
      });
    };
    fetchData();
  }, []);
  // *******************sort*********************
  const [sortOrder, setSortOrder] = useState<string>("asc");
  const handleSortChange = (newSortOrder: string) => {
    const sortedData = [...salseDetails].sort((a: any, b: any) => {
      if (newSortOrder === "asc") {
        return a.prodcut.localeCompare(b.prodcut);
      } else {
        return b.prodcut.localeCompare(a.prodcut);
      }
    });
    setSalseDetails(sortedData);
    setSortOrder(newSortOrder);
  };

  const handleCreateInvoice = () => {
    navigate("/app/invoice");
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    let data = [...saleslist];
    data = data?.filter((value: any) =>
      value?.prodcut?.toLowerCase().includes(query?.toLowerCase())
    );
    setSalseDetails(data);
  };

  // Add unique ids to each row
  const rows = salseDetails?.map((item: any, index: any) => ({
    id: uuidv4(),
    sl_No: index + 1,
    ...item,
  }));

  const columns: GridColDef[] = [
    {
      field: "sl_No",
      headerName: "Sl No",
      width: 130,
      editable: false,
    },
    {
      field: "prodcut",
      headerName: "GOODS",
      width: 140,
      editable: false,
    },
    {
      field: "hsn",
      headerName: "HSN/SAC",
      editable: false,
      width: 140,
      renderCell: (params: any) => (
        <>{params?.row?.hsn ? params?.row?.hsn : "N/A"}</>
      ),
    },
    {
      field: "rate",
      headerName: "RATE (PERQTY)",
      editable: false,
      width: 180,
      renderCell: (params: any) => (
        <>{params?.row?.rate ? params?.row?.rate : "N/A"}</>
      ),
    },
    {
      field: "qty",
      headerName: "QTY",
      editable: false,
      width: 120,
      renderCell: (params: any) => (
        <>{params.row.qty ? params.row.qty : "N/A"}</>
      ),
    },
    {
      field: "amount",
      headerName: "AMOUNT",
      editable: false,
      width: 140,
      renderCell: (params: any) => (
        <>
          {params?.row?.amount
            ? Number(params?.row?.amount)?.toFixed(2)
            : "N/A"}
        </>
      ),
    },
    {
      field: "prodcgst",
      headerName: "CGST%",
      editable: false,
      width: 130,
      renderCell: (params: any) => (
        <>{params?.row?.prodcgst ? params?.row?.prodcgst : "N/A"}</>
      ),
    },
    {
      field: "prodsgst",
      headerName: "SGST%",
      editable: false,
      width: 120,
      renderCell: (params: any) => (
        <>{params?.row?.prodsgst ? params?.row?.prodsgst : "N/A"}</>
      ),
    },
    {
      field: "igst",
      headerName: "IGST%",
      editable: false,
      width: 120,
      renderCell: (params: any) => (
        <>{params?.row?.prodigst ? params?.row?.prodigst : "N/A"}</>
      ),
    },
    {
      field: "prodvat",
      headerName: "VAT%",
      editable: false,
      width: 120,
      renderCell: (params: any) => (
        <>{params.row.prodvat ? params.row.prodvat : "N/A"}</>
      ),
    },
    {
      field: "prodothers",
      headerName: "OTHERS%",
      editable: false,
      width: 150,
      renderCell: (params: any) => (
        <>{params.row?.prodothers ? params.row?.prodothers : "N/A"}</>
      ),
    },
    {
      field: "totalgst",
      headerName: "TOTAL GST %",
      editable: false,
      width: 180,
      renderCell: (params: any) => (
        <>{params.row.totalgst ? params.row.totalgst : "N/A"}</>
      ),
    },
    {
      field: "grandTotal",
      headerName: "GRAND TOTAL",
      editable: false,
      width: 180,
      renderCell: (params: any) => (
        <>{params.row.grandTotal ? params.row.grandTotal?.toFixed(2) : "N/A"}</>
      ),
    },
  ];

  return (
    <div>
      <div className="flex justify-between flex-col lg:flex-row xl:flex-row 2xl:flex-row md:flex-row">
        <div className="p-4  font-bold text-[#008065]  ml-2">Sales - Report</div>
        <div className="p-4  font-bold text-[#008065]  ml-2">
          <button
            type="button"
            className="rounded-xl font-bold text-white flex flex-row items-center justify-center  text-xs h-8 px-2  block w-full whitespace-nowrap sm:px-10 lg:text-sm md:text-sm"
            style={{ backgroundColor: "#008065" }}
            onClick={handleCreateInvoice}
          >
            <FiPlusCircle className="h-5 w-5" />
            <span className={`flex-1 ml-3 text-left whitespace-nowrap  text-sm font-bold`} >Create New Invoice </span>
          </button></div>


      </div>
      <div className="mt-1">
        <DashBoardTable />
      </div>

    </div>
  );
};

export default SalesTabe;
