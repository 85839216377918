/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import CompanyTab from "./CompanyTab";
import BankPage from "./BankPage";
import TermsPage from "./TermsPage";
import UPIPaymentPage from "./UPIPaymentPage";
import NotesPage from "./NotesPage";
import FooterNotesPage from "./FooterNotesPage";

function CompanyPage() {
  const [openTab, setOpenTab] = useState(1 as any);
  return (
    <div>
      <div className=" pl-4">
        <ul className="flex flex-wrap text-sm font-medium text-center text-[#008065] border-b border-gray-200 dark:border-gray-700 dark:text-gray-400 ">
          <li className="me-2 mt-2 ">
            <button
              onClick={() => setOpenTab(1)}
              className={
                openTab === 1
                  ? "inline-block p-4 text-[#008065]  border-b-2 border-[#008065] cursor-pointer rounded-t-lg  dark:bg-gray-800 dark:hover:text-[#008065] font-bold"
                  : "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg active dark:bg-gray-800 dark:hover:text-[#008065] font-bold"
              }
            >
              Company Info
            </button>
          </li>
          <li className="me-2 mt-2">
            <button
              // disabled={companylist?.length > 0 ? false : true}
              onClick={() => setOpenTab(2)}
              // className={
              //         `
              //         ${companylist?.length > 0 ? "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg  dark:bg-gray-800 dark:hover:text-[#008065] font-bold " : "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg active dark:bg-gray-800 dark:hover:text-[#008065] cursor-not-allowed opacity-50 font-bold"}
              //  ${openTab === 2 ? "inline-block p-4 text-[white] bg-[#008065] cursor-pointer rounded-t-lg  dark:bg-gray-800 dark:hover:text-[#008065]  font-bold" : "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg active dark:bg-gray-800 dark:hover:text-[#008065] font-bold"}`

              //     }
              className={`
                         ${openTab === 2
                  ? "inline-block p-4 text-[#008065]  border-b-2 border-[#008065] cursor-pointer rounded-t-lg  dark:bg-gray-800 dark:hover:text-[#008065] font-bold"
                  : "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg active dark:bg-gray-800 dark:hover:text-[#008065] font-bold"
                }`}
            >
              Bank Info
            </button>
          </li>
          <li className="me-2 mt-2">
            <button
              // disabled={companylist?.length > 0 ? false : true}
              onClick={() => setOpenTab(3)}
              // className={
              //         `
              //         ${companylist?.length > 0 ? "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg  dark:bg-gray-800 dark:hover:text-[#008065] font-bold " : "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg active dark:bg-gray-800 dark:hover:text-[#008065] cursor-not-allowed opacity-50 font-bold"}
              //  ${openTab === 2 ? "inline-block p-4 text-[white] bg-[#008065] cursor-pointer rounded-t-lg  dark:bg-gray-800 dark:hover:text-[#008065]  font-bold" : "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg active dark:bg-gray-800 dark:hover:text-[#008065] font-bold"}`

              //     }
              className={`
                         ${openTab === 3
                  ? "inline-block p-4 text-[#008065]  border-b-2 border-[#008065] cursor-pointer rounded-t-lg  dark:bg-gray-800 dark:hover:text-[#008065] font-bold"
                  : "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg active dark:bg-gray-800 dark:hover:text-[#008065] font-bold"
                }`}
            >
              UPI Payment Info
            </button>
          </li>
          <li className="me-2 mt-2">
            <button
              // disabled={companylist?.length > 0 && banklist?.length > 0 ? false : true}
              onClick={() => setOpenTab(4)}
              //     className={
              //         `
              //         ${banklist?.length > 0 && companylist?.length > 0 ? "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg  dark:bg-gray-800 dark:hover:text-[#008065]  font-bold" : "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg active dark:bg-gray-800 dark:hover:text-[#008065] cursor-not-allowed opacity-50 font-bold"}
              //  ${openTab === 3 ? "inline-block p-4 text-[white] bg-[#008065] cursor-pointer rounded-t-lg  dark:bg-gray-800 dark:hover:text-[#008065] font-bold " : "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg active dark:bg-gray-800 dark:hover:text-[#008065] font-bold"}`

              //     }

              className={`
                         ${openTab === 4
                  ? "inline-block p-4 text-[#008065]  border-b-2 border-[#008065] cursor-pointer rounded-t-lg  dark:bg-gray-800 dark:hover:text-[#008065] font-bold "
                  : "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg active dark:bg-gray-800 dark:hover:text-[#008065] font-bold"
                }`}
            >
              Terms & Conditions Info
            </button>
          </li>
          <li className="me-2 mt-2">
            <button
              // disabled={companylist?.length > 0 && banklist?.length > 0 ? false : true}
              onClick={() => setOpenTab(5)}
              //     className={
              //         `
              //         ${banklist?.length > 0 && companylist?.length > 0 ? "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg  dark:bg-gray-800 dark:hover:text-[#008065]  font-bold" : "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg active dark:bg-gray-800 dark:hover:text-[#008065] cursor-not-allowed opacity-50 font-bold"}
              //  ${openTab === 3 ? "inline-block p-4 text-[white] bg-[#008065] cursor-pointer rounded-t-lg  dark:bg-gray-800 dark:hover:text-[#008065] font-bold " : "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg active dark:bg-gray-800 dark:hover:text-[#008065] font-bold"}`

              //     }

              className={`
                         ${openTab === 5
                  ? "inline-block p-4 text-[#008065]  border-b-2 border-[#008065] cursor-pointer rounded-t-lg  dark:bg-gray-800 dark:hover:text-[#008065] font-bold "
                  : "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg active dark:bg-gray-800 dark:hover:text-[#008065] font-bold"
                }`}
            >
              Notes
            </button>
          </li>
          <li className="me-2 mt-2">
            <button
              // disabled={companylist?.length > 0 && banklist?.length > 0 ? false : true}
              onClick={() => setOpenTab(6)}
              //     className={
              //         `
              //         ${banklist?.length > 0 && companylist?.length > 0 ? "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg  dark:bg-gray-800 dark:hover:text-[#008065]  font-bold" : "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg active dark:bg-gray-800 dark:hover:text-[#008065] cursor-not-allowed opacity-50 font-bold"}
              //  ${openTab === 3 ? "inline-block p-4 text-[white] bg-[#008065] cursor-pointer rounded-t-lg  dark:bg-gray-800 dark:hover:text-[#008065] font-bold " : "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg active dark:bg-gray-800 dark:hover:text-[#008065] font-bold"}`

              //     }

              className={`
                         ${openTab === 6
                  ? "inline-block p-4 text-[#008065]  border-b-2 border-[#008065] cursor-pointer rounded-t-lg  dark:bg-gray-800 dark:hover:text-[#008065] font-bold "
                  : "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg active dark:bg-gray-800 dark:hover:text-[#008065] font-bold"
                }`}
            >
              Footer Notes
            </button>
          </li>
        </ul>
      </div>
      <div className=" mt-6">
        <div className={openTab === 1 ? "block" : "hidden"}>
          <CompanyTab openTab={openTab} />
        </div>
        {openTab === 2 && (
          <div className={openTab === 2 ? "block" : "hidden"}>
            <BankPage />
          </div>
        )}
        {openTab === 3 && (
          <div className={openTab === 3 ? "block" : "hidden"}>
            <UPIPaymentPage />
          </div>
        )}
        {openTab === 4 && (
          <div className={openTab === 4 ? "block" : "hidden"}>
            <TermsPage />
          </div>
        )}
        {openTab === 5 && (
          <div className={openTab === 5 ? "block" : "hidden"}>
            <NotesPage />
          </div>
        )}
        {openTab === 6 && (
          <div className={openTab === 6 ? "block" : "hidden"}>
            <FooterNotesPage />
          </div>
        )}
      </div>
    </div>
  );
}

export default CompanyPage;
