import DashBoardTable from '../../Tables/DashBoardTable';
import DashboardWidgets from '../dashboards/DashboardWidgets';

function DashboardHome() {


  return (
    <div className="bg-[#E1E8E7] h-full">
      <div className="p-4 text-2xl font-bold text-[#045545] mx-2">
        Dashboard
      </div>
      <div className="flex flex-wrap ">
        <div className="w-full  pr-4  mb-4 sm:mb-1">
          <DashboardWidgets />
          <div className="mt-1">
            <DashBoardTable />
          </div>
          <div className="mt-4">
          </div>
        </div>
        <div className="w-full lg:w-2/6 pl-4 pr-4 sm:pl-4 sm:pr-2">
        </div>
      </div>
    </div>
  )
}

export default DashboardHome;
