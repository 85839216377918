import { useEffect, useState } from 'react'
import Navbar from '../Header/Navbar'
import Sidebar from '../Sidebar/Sidebar'
import Header from '../../pages/components/Header';
import { useLocation } from 'react-router-dom';
import SideBarHeader from '../../pages/components/SideBarHeader';

export default function Dashboard(props: any) {
    const { children } = props
    const location = useLocation();
    const pathname = location.pathname;
    const [isSidebarOpen, setSidebarOpen] = useState(false)
    const [subMenuList, setsubMenuList] = useState(false)
    const userId = sessionStorage.getItem("userInfo");
    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen)
    }
    const closeSideBar = () => {
        setSidebarOpen(true);
    }
    const openSideBar = () => {
        setSidebarOpen(false);
    }
    const toggleDropdown = () => {
        setsubMenuList(!subMenuList)
    }
    const closeMenu = () => {
        if (subMenuList) {
            setsubMenuList(!subMenuList)
        }
    }
    const [matches, setMatches] = useState(
        window.matchMedia("(max-width: 768px)").matches
    );

    useEffect(() => {
        window
            .matchMedia("(max-width: 768px)")
            .addEventListener("change", (e) => setMatches(e.matches));
    }, [matches]);

    return (
        <>
            <div>{(userId) ?
                (<> <Sidebar
                    isOpen={isSidebarOpen}
                    setsubMenuList={setsubMenuList}
                    subMenuList={subMenuList}
                    toggleDropdown={toggleDropdown}
                    toggleSidebar={toggleSidebar}
                    closeSideBar={closeSideBar}
                    openSideBar={openSideBar}
                />
                    <div className={`flex-1  ${isSidebarOpen && !pathname.includes('invoice') ? 'ml-6 lg:ml-12' :
                        isSidebarOpen && pathname.includes('invoice') ? 'ml-2 lg:ml-12' : 'md:ml-12 lg:ml-64 test'
                        }`}>
                        <span onClick={closeMenu}>
                            <SideBarHeader toggleSidebar={toggleSidebar} isOpen={isSidebarOpen} />
                        </span>
                        <span onClick={closeMenu}>{children}</span>
                    </div>

                </>) :
                (<>
                    {location.pathname === '/'
                        || location.pathname === '/free-freelancer-invoice.html'
                        || location.pathname === '/free-hourlyrate-invoice.html'
                        || location.pathname === '/free-photography-invoice.html'
                        || location.pathname === '/free-hourlyrate-invoice.html'
                        || location.pathname === '/free-photography-invoice.html'
                        || location.pathname === '/free-consulting-invoice.html'
                        || location.pathname === '/free-graphicdesign-invoice.html'
                        || location.pathname === '/free-gst-invoice.html'
                        || location.pathname === '/free-proforma-invoice.html'
                        || location.pathname === '/free-quotation-invoice.html'
                        || location.pathname === '/free-medical-invoice.html'
                        ? <Header /> : <Navbar />}
                    <span onClick={closeMenu}>{children}</span>
                </>
                )
            }
            </div>
        </>
    )
}
