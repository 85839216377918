import React from 'react'

const ReceivableSvg = () => {
    return (
        <div>
            <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                viewBox="0 0 412.000000 452.000000"
                preserveAspectRatio="xMidYMid meet"
            >
                <g
                    transform="translate(0.000000,452.000000) scale(0.100000,-0.100000)"
                    fill="#22d3ee"
                    stroke="none"
                >
                    <path
                        d="M1265 3825 l-25 -24 0 -551 0 -551 25 -24 24 -25 1021 0 1021 0 24
                        25 25 24 0 551 0 551 -25 24 -24 25 -1021 0 -1021 0 -24 -25z m225 -119 c0
                        -21 -48 -74 -81 -92 l-29 -15 0 61 0 60 55 0 c41 0 55 -4 55 -14z m1514 -51
                        c34 -79 113 -156 187 -182 l49 -18 0 -206 0 -207 -27 -7 c-92 -22 -190 -118
                        -222 -217 l-13 -38 -668 0 -668 0 -13 38 c-32 99 -130 195 -221 217 l-28 7 0
                        208 0 208 28 7 c82 20 169 99 209 192 l27 63 666 0 666 0 28 -65z m236 5 c0
                        -38 -4 -60 -11 -60 -18 0 -99 85 -99 104 0 13 11 16 55 16 l55 0 0 -60z
                        m-1776 -820 c40 -49 35 -60 -29 -60 l-55 0 0 60 0 61 29 -15 c16 -9 40 -29 55
                        -46z m1776 0 l0 -60 -55 0 c-44 0 -55 3 -55 16 0 19 81 104 99 104 7 0 11 -22
                        11 -60z"
                    />
                    <path
                        d="M2198 3565 c-95 -35 -172 -116 -205 -211 -22 -66 -15 -185 17 -249
                        31 -63 92 -124 155 -156 42 -20 64 -24 145 -24 111 0 167 21 235 90 69 68 90
                        124 90 235 0 81 -4 103 -24 145 -32 63 -93 124 -156 155 -61 30 -197 38 -257
                        15z m216 -149 c101 -59 123 -202 44 -292 -76 -86 -190 -95 -274 -22 -89 78
                        -95 195 -16 280 67 71 161 84 246 34z"
                    />
                    <path
                        d="M1665 3295 c-14 -13 -25 -33 -25 -45 0 -27 43 -70 70 -70 27 0 70 43
                        70 70 0 27 -43 70 -70 70 -11 0 -32 -11 -45 -25z"
                    />
                    <path
                        d="M2865 3295 c-14 -13 -25 -33 -25 -45 0 -11 11 -32 25 -45 13 -14 34
                        -25 45 -25 12 0 32 11 45 25 14 13 25 34 25 45 0 27 -43 70 -70 70 -11 0 -32
                        -11 -45 -25z"
                    />
                    <path
                        d="M335 2495 l-25 -24 0 -591 c0 -577 0 -590 20 -610 19 -19 33 -20 243
                        -20 263 0 267 1 267 81 l0 49 93 0 c105 0 111 -2 437 -152 322 -148 418 -179
                        560 -179 198 -1 309 45 1080 445 410 214 436 231 475 312 62 130 -3 296 -137
                        354 -41 17 -66 21 -119 18 -73 -4 -74 -5 -645 -299 -105 -55 -194 -99 -197
                        -99 -3 0 -11 24 -17 54 -14 70 -61 140 -114 171 -22 13 -169 85 -326 160 -477
                        228 -575 264 -720 265 -61 0 -279 -23 -342 -36 -27 -5 -28 -4 -28 38 0 84 -12
                        88 -270 88 -211 0 -211 0 -235 -25z m375 -610 l0 -495 -130 0 -130 0 0 495 0
                        495 130 0 130 0 0 -495z m653 384 c74 -22 170 -63 370 -158 151 -73 365 -174
                        426 -204 52 -24 81 -68 81 -122 0 -81 -66 -142 -144 -132 -23 3 -117 42 -211
                        87 -379 183 -389 187 -427 153 -21 -19 -24 -75 -5 -91 7 -6 144 -73 303 -147
                        l289 -136 75 4 c74 4 77 5 365 154 594 307 728 373 759 373 38 0 89 -30 112
                        -65 24 -37 22 -105 -4 -142 -16 -22 -89 -65 -269 -158 -551 -284 -872 -444
                        -938 -468 -89 -31 -191 -41 -287 -28 -103 14 -136 27 -418 156 -359 164 -366
                        167 -493 173 l-107 5 2 366 3 366 80 12 c224 35 323 35 438 2z"
                                            />
                </g>
            </svg>
        </div>
    )
}

export default ReceivableSvg
