import api from "../axiosConfig";
import {
   SALES_RETURN_DELETE_FAIL,
   SALES_RETURN_DELETE_REQUEST,
   SALES_RETURN_DELETE_SUCCESS,
   SALES_RETURN_FIND_ONE_FAIL,
   SALES_RETURN_FIND_ONE_REQUEST,
   SALES_RETURN_FIND_ONE_SUCCESS,
   SALES_RETURN_GET_COUNT_FAIL,
   SALES_RETURN_GET_COUNT_REQUEST,
   SALES_RETURN_GET_COUNT_SUCCESS,
   SALES_RETURN_POST_FAIL,
   SALES_RETURN_POST_REQUEST,
   SALES_RETURN_POST_SUCCESS,
   SALES_RETURN_UPDATE_FAIL,
   SALES_RETURN_UPDATE_REQUEST,
   SALES_RETURN_UPDATE_SUCCESS,
} from "../constants/salesReturnConstants";

export const salesReturnPost =
   (Invoice: any) => async (dispatch: any, getState: any) => {
      dispatch({ type: SALES_RETURN_POST_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();

         const { data } = await api.post(`/api/salesreturn/save`, Invoice, {
            headers: { Authorization: `Bearer ${token?.tocken}` },
         });
         return dispatch({
            type: SALES_RETURN_POST_SUCCESS,
            payload: data,
         });
      } catch (error: any) {
         dispatch({
            type: SALES_RETURN_POST_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };

   export const salesReturnGet = () => async (dispatch: any, getState: any) => {
   dispatch({ type: SALES_RETURN_GET_COUNT_REQUEST });
   try {
      const {
         userSignin: {
            userInfo: { token },
         },
      } = getState();
      const { data } = await api.get(`/api/salesreturn/find-all/${token?._id}`, {
         headers: { Authorization: `Bearer ${token?.tocken}` },
      });
      return dispatch({
         type: SALES_RETURN_GET_COUNT_SUCCESS,
         payload: data,
      });
   } catch (error: any) {
      dispatch({
         type: SALES_RETURN_GET_COUNT_FAIL,
         payload:
            error.response && error.response.data.message
               ? error.response.data.message
               : error.message,
      });
   }
};

export const salesReturnFindOne =
  (id: any) => async (dispatch: any, getState: any) => {
    dispatch({ type: SALES_RETURN_FIND_ONE_REQUEST });
    try {
      const {
        userSignin: {
          userInfo: { token },
        },
      } = getState();
      const { data } = await api.get(`/api/salesreturn/salesreturnFindOne-one/${id}`, {
        headers: { Authorization: `Bearer ${token?.tocken}` },
      });
      return dispatch({
        type: SALES_RETURN_FIND_ONE_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      dispatch({
        type: SALES_RETURN_FIND_ONE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const SalesReturnUpdate =
   (id: any, externalInvoice: any) => async (dispatch: any, getState: any) => {
      dispatch({ type: SALES_RETURN_UPDATE_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         const { data } = await api.put(
            `/api/salesreturn/salesreturnNewput/${id}`,
            externalInvoice,
            {
               headers: { Authorization: `Bearer ${token?.tocken}` },
            }
         );
         return dispatch({
            type: SALES_RETURN_UPDATE_SUCCESS,
            payload: data,
         });
      } catch (error: any) {
         dispatch({
            type: SALES_RETURN_UPDATE_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };

   export const SalesReturnDelete =
   (id: any) => async (dispatch: any, getState: any) => {
      dispatch({ type: SALES_RETURN_DELETE_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         const { data } = await api.delete(`/api/salesreturn/delete/${id}`, {
            headers: { Authorization: `Bearer ${token?.tocken}` },
         });
         return dispatch({
            type: SALES_RETURN_DELETE_SUCCESS,
            payload: data,
         });
      } catch (error: any) {
         dispatch({
            type: SALES_RETURN_DELETE_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };

