import api from "../axiosConfig";
import { CURRENCY_DELETE_FAIL, CURRENCY_DELETE_REQUEST, CURRENCY_DELETE_SUCCESS, CURRENCY_GET_FAIL, CURRENCY_GET_REQUEST, CURRENCY_GET_SUCCESS, CURRENCY_POST_FAIL, CURRENCY_POST_REQUEST, CURRENCY_POST_SUCCESS, CURRENCY_UPDATE_FAIL, CURRENCY_UPDATE_REQUEST, CURRENCY_UPDATE_SUCCESS } from "../constants/currencyConstants";


export const currencySave = (currency: any) => async (dispatch: any, getState: any) => {

    dispatch({ type: CURRENCY_POST_REQUEST });
    try {
        const {
            userSignin: {
                userInfo: { token },
            },
        } = getState();
        currency.user_id = token._id;
        const { data } = await api.post(
            `/api/currency/save`,
            currency,
            {
                headers: { Authorization: `Bearer ${token?.tocken}` },
            }
        );
        return dispatch({ type: CURRENCY_POST_SUCCESS, payload: data });
    } catch (error: any) {
        return dispatch({
            type: CURRENCY_POST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const currencyGet = (id: any) => async (dispatch: any, getState: any) => {
    dispatch({ type: CURRENCY_GET_REQUEST });
    try {
        const {
            userSignin: {
                userInfo: { token },
            },
        } = getState();

        const { data } = await api.get(
            `/api/currency/all/${id}`,
            {
                headers: { Authorization: `Bearer ${token?.tocken}` },
            }
        );
        return dispatch({ type: CURRENCY_GET_SUCCESS, payload: data });
    } catch (error: any) {
        dispatch({
            type: CURRENCY_GET_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const currencyUpdate = (id: any, currency: any) => async (dispatch: any, getState: any) => {

    dispatch({ type: CURRENCY_UPDATE_REQUEST });
    try {
        const {
            userSignin: {
                userInfo: { token },
            },
        } = getState();
        currency.user_id = token._id;
        const { data } = await api.put(`/api/currency/update/${id}`, currency, {
            headers: { Authorization: `Bearer ${token?.tocken}` },
        });
        return dispatch({ type: CURRENCY_UPDATE_SUCCESS, payload: data });

    } catch (error: any) {
        dispatch({
            type: CURRENCY_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const currencyDelete = (id: any) => async (dispatch: any, getState: any) => {
    dispatch({ type: CURRENCY_DELETE_REQUEST });
    try {
        const {
            userSignin: {
                userInfo: { token },
            },
        } = getState();

        const { data } = await api.delete(`/api/currency/delete/${id}`, {
            headers: { Authorization: `Bearer ${token?.tocken}` },
        });
        return dispatch({ type: CURRENCY_DELETE_SUCCESS, payload: data });

    } catch (error: any) {
        dispatch({
            type: CURRENCY_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};