export const CURRENCY_POST_REQUEST = "CURRENCY_POST_REQUEST";
export const CURRENCY_POST_SUCCESS = "CURRENCY_POST_SUCCESS";
export const CURRENCY_POST_FAIL = "CURRENCY_POST_FAIL";
export const CURRENCY_POST_RESET = "CURRENCY_POST_RESET";

export const CURRENCY_GET_REQUEST = "CURRENCY_GET_REQUEST";
export const CURRENCY_GET_SUCCESS = "CURRENCY_GET_SUCCESS";
export const CURRENCY_GET_FAIL = "CURRENCY_GET_FAIL";
export const CURRENCY_GET_RESET = "CURRENCY_GET_RESET";

export const CURRENCY_UPDATE_REQUEST = "CURRENCY_UPDATE_REQUEST";
export const CURRENCY_UPDATE_SUCCESS = "CURRENCY_UPDATE_SUCCESS";
export const CURRENCY_UPDATE_FAIL = "CURRENCY_UPDATE_FAIL";
export const CURRENCY_UPDATE_RESET = "CURRENCY_UPDATE_RESET";

export const CURRENCY_DELETE_REQUEST = "CURRENCY_DELETE_REQUEST";
export const CURRENCY_DELETE_SUCCESS = "CURRENCY_DELETE_SUCCESS";
export const CURRENCY_DELETE_FAIL = "CURRENCY_DELETE_FAIL";
export const CURRENCY_DELETE_RESET = "CURRENCY_DELETE_RESET";