import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const datas = [
  { name: `Freelancer Invoice`, vlaue: `freelancer` },
  { name: `Hourly Rate Invoice`, vlaue: `hourlyrate` },
  { name: `Photography Invoice`, vlaue: `photography` },
  { name: `Consulting Invoice`, vlaue: `consulting` },
  { name: `Graphic Design Invoice`, vlaue: `graphicdesign` },
  { name: `GST Invoice`, vlaue: `gst` },
  { name: `Proforma  Invoice`, vlaue: `proforma` },
  { name: `Quotation & Estimate`, vlaue: `quotation` },
  { name: `Medical Invoice`, vlaue: `medical` }
];


interface Props {
  onClick: any
}

const Carousel = ({ onClick }: Props) => {
  function SampleNextArrow(props: any) {
    const { className, style, onClick } = props;
    return <div className={className} style={{ ...style }} onClick={onClick} />;
  }

  function SamplePrevArrow(props: any) {
    const { className, style, onClick } = props;
    return <div className={className} style={{ ...style }} onClick={onClick} />;
  }

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 2560,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const slidesContent = (value: any) => {
    const valueFromChild = value;
    onClick(valueFromChild);
  };
  return (
    <>
      {/* <div className="mt-6"> */}
      <div className="grid grid-cols-6  mt-4">
        <div className="col-start-2 col-span-4 ">
          <Slider {...settings}>
            {datas?.map((d) => (
              <>
                <Link to={`/free-${d.vlaue}-invoice.html`}>
                  <div className="flex justify-around  items-center">
                    <button
                      type="button"
                      className="w-full px-4 whitespace-nowrap text-gray-900 mx-2 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm py-2.5 me-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                      style={{ borderColor: "#008065" }}
                      onClick={() => slidesContent(d.name)}
                    >
                      {d.name}
                    </button>
                  </div>
                </Link>
              </>
            ))}
          </Slider>
        </div>
      </div>
      {/* </div>  */}
    </>
  );
};

export default Carousel;
