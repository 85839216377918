import React from 'react'
import { toast } from 'react-toastify';

const ConfirmationDeactivePopup: React.FC<{
    message: string;
    onConfirm: () => void;
    onCancel: () => void;
  }> = ({ message, onConfirm, onCancel }) => {
    const handleConfirm = () => {
        onConfirm();
        toast.success("Delete Successfully !", {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 800,
        });
      };
    return (
        <div className='justify-center backdrop-blur-sm items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none p-35'>
          <div className='md:text-sm 2xl:text-lg relative lg-[25rem] my-6 mx-auto  md:w-[25rem] w-[16rem]'>
            <div className='md:text-sm 2xl:text-lg border-solid border-2 border-green-700  rounded-md shadow-lg h-[13rem] bg-[#e1e8e7] relative flex flex-col w-full bg-white outline-none focus:outline-none'>
              <div className='items-start justify-between p-2 border-solid border-slate-200 rounded-t bg-[#008065]'>
                <div className='flex p-1 border-solid border-slate-200 rounded-b justify-between  ' >
                  <h2 className="md:text-xl 2xl:text-xl font-semibold text-white text-xl">Confirmation</h2>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" className='cursor-pointer mt-1.5' viewBox="0 0 384 512" fill='#fff' onClick={onCancel}><path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" /></svg>
                </div>
              </div>
              <h6 className='md:text-md text-[18px] text-[#008065] 2xl:text-lg text-1xl mt-1 font-semibold justify-center items-center text-center'>
                DELETE
              </h6>
              <p className='md:text-sm 2xl:text-lg xl:text-lg lg:text-lg md:text-lg xs:text-lg text-black font-medium justify-center text-sm items-center text-center mt-2'>
                <p>{message}</p>
              </p>
              <div className='flex p-2 border-solid border-slate-200 rounded-b md:justify-end sm:justify-center  mt-2'>
                <div className='lg:flex lg:justify-around flex justify-around'>
                  <button
                    className='md:text-sm 2xl:text-lg xl:text-lg lg:text-lg md:text-lg xs:text-lg bg-red-600 text-white font-semibold items-center justify-center mr-2 rounded-lg px-2 py-1 text-xs inline-flex w-[7rem]'
                    type='button'
                    onClick={handleConfirm} // Changed to handleConfirm instead of onConfirm
                  >
                    DELETE
                  </button>
                  <button
                    type='button'
                    className='md:text-sm 2xl:text-lg lg:text-lg xl:text-lg md:text-lg xs:text-lg bg-white-900 font-semibold border-[#008065] text-sm items-center justify-center text-gray-900 mr-3 dark:text-white border-2  rounded-lg font-bold px-2 py-1 inline-flex w-[7rem]'
                    onClick={onCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
    
    
            </div>
          </div>
        </div>
      );
}

export default ConfirmationDeactivePopup
