import { Box } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

const UserTable = ({ userList }: any) => {
  const [vendorListValues, setvendorListValues] = useState([] as any);

  // const StyledTableRow = styled(TableRow)(({ theme }) => ({
  //   "&:nth-of-type(odd)": {
  //     backgroundcolor: "#F1F7F6",
  //   },
  //   "&:nth-of-type(even)": {
  //     backgroundColor: "#F1F1F1",
  //   },
  //   "&:last-child td, &:last-child th": {
  //     border: 0,
  //   },
  // }));

  // const StyledTableCell = styled(TableCell)(({ theme }) => ({
  //   [`&.${tableCellClasses.head}`]: {
  //     backgroundColor: theme.palette.common.black,
  //     color: theme.palette.common.white,
  //   },
  //   [`&.${tableCellClasses.body}`]: {
  //     fontSize: 14,
  //   },
  // }));

  useEffect(() => {
    if (userList) {
      setvendorListValues(userList);
    }
  }, [userList]);

  // ***********************************search******************************
  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    const filteredData = userList.filter((user: any) =>
      user.fname.toLowerCase().includes(query)
    );
    setvendorListValues(filteredData);
  };

  // **************************sort*********************************

  const [sortOrder, setSortOrder] = useState("asc");

  const handleSortChange = (newSortOrder: any) => {
    const sortedData = [...vendorListValues].sort((a, b) => {
      if (newSortOrder === "asc") {
        return a.fname.localeCompare(b.fname);
      } else {
        return b.fname.localeCompare(a.fname);
      }
    });
    setvendorListValues(sortedData);
    setSortOrder(newSortOrder);
  };

  const rows = vendorListValues.map((company: any, index: any) => ({
    id: uuidv4(),
    sno: index + 1,
    ...company,
  }));

  const columns: GridColDef[] = [
    { field: "sno", headerName: "S No", width: 120 },
    { field: "fname", headerName: "First Name", flex: 2, minWidth: 150 },
    { field: "lname", headerName: "Last Name", flex: 2, minWidth: 150 },
    { field: "address", headerName: "Address", flex: 2, minWidth: 150 },
    { field: "city", headerName: "City", flex: 2, minWidth: 150 },
    { field: "state", headerName: "State", flex: 2, minWidth: 150 },
    { field: "postal_code", headerName: "Postal Code", flex: 2, minWidth: 150 },
    { field: "email", headerName: "Email", flex: 2, minWidth: 150 },
    { field: "phone", headerName: "Mobile", flex: 2, minWidth: 150 },
    { field: "userRoleName", headerName: "Role", flex: 2, minWidth: 150 },
  ];

  return (
    <div>
      <div className="bg-[#F1F7F6] rounded-xl px-3 py-3 mx-2">
        <div className="flex justify-between flex-col lg:flex-row xl:flex-row 2xl:flex-row md:flex-row">
          <div className="flex items-center">
            <div className="relative flex mb-2">
              <input
                onChange={(e) => onSearch(e)}
                type="text"
                id="simple-search"
                className="w-44  bg-gray-50 border border-[#008065] text-gray-900 text-sm rounded-lg p-2.5 pl-10 focus:outline-none"
                placeholder="Search"
                required
              />
              <span className="absolute inset-y-0 left-2 flex items-center pl-2 text-[#008065]">
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div className="block mb-2  ">
            <div className="relative flex items-center ">
              <select
                defaultValue={""}
                id="sortDropdown"
                className="block w-[11rem] h-10 p-2 text-sm  border border-[#008065] rounded-lg bg-gray-50  dark:bg-gray-700 dark:border-gray-600 "
                onChange={(e) => handleSortChange(e.target.value)}
              >
                <option value="" disabled selected hidden>
                  Sort By
                </option>
                <option value="asc">User Name A to Z</option>
                <option value="desc">User Name Z to A</option>
              </select>
            </div>
          </div>
        </div>

        <div
          className="bg-[#F1F7F6]"
          style={{ borderRadius: "8px" }}
        >
          <Box
            sx={{
              height: "45vh",
              width: "100%",
              // maxHeight: "45vh",
              overflowX: "auto",
              overflowY: "hidden",
            }}
          >
            <DataGrid
              sx={{
                ".MuiDataGrid-columnHeaderTitle.css-t89xny-MuiDataGrid-columnHeaderTitle":
                  {
                    color: "rgb(0, 128, 101)",
                    fontWeight: 600,
                    fontSize: "14px",
                  },
                ".MuiDataGrid-scrollbar.MuiDataGrid-scrollbar--vertical.css-1b9e9gy, .MuiBox-root.css-1c7wa27":
                  { overflowY: "hidden !important" },
                ".MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
                  fontWeight: 600,
                  fontSize: "14px",
                },
              }}
              rows={rows}
              columns={columns}
              checkboxSelection
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5, 10, 25, 50]}
              disableRowSelectionOnClick
            />
          </Box>
        </div>
      </div>
    </div>
  );
};

export default UserTable;
