/* eslint-disable react-hooks/rules-of-hooks */
import { createBrowserRouter } from "react-router-dom";
import AuthenticatedRoutes from "./Auth/AuthenticatedRoutes";
import PaymentReceiveTable from "./Tables/PaymentReceiveTable";
import SalesTabe from "./Tables/SalesTabe";
import Stock from "./Tables/Stock";
import Container from "./container/Container";
import ChangePassword from "./pages/Authentication/ChangePassword";
import ForgetPassword from "./pages/Authentication/ForgetPassword";
import LoginPage from "./pages/Authentication/LoginPage";
import MailVerification from "./pages/Authentication/MailVerification";
import OtpScreenPage from "./pages/Authentication/OtpScreenPage";
import RegisterPage from "./pages/Authentication/RegisterPage";
import ServiceTab from "./pages/Service/ServiceTab";
import AdminDashboard from "./pages/SuperAdmin/AdminDashboard";
import AdminPayments from "./pages/SuperAdmin/AdminPayments";
import ExternalInvoice from "./pages/SuperAdmin/ExternalInvoice";
import Unit from "./pages/SuperAdmin/Unit";
import User from "./pages/SuperAdmin/User";
import Unauthorized from "./pages/commen/Unauthorized";
import AppSettingPage from "./pages/components/AppSettingPage";
import ClientPage from "./pages/components/ClientPage";
import CompanyPage from "./pages/components/CompanyPage";
import DashboardHome from "./pages/components/DashboardHome";
import FeedbackPage from "./pages/components/FeedbackPage";
import HomePage from "./pages/components/HomePage";
import ProductTab from "./pages/components/InvoiceDetails/ProductTab";
import VendorTab from "./pages/components/InvoiceDetails/VendorTab";
import InvoicePage from "./pages/components/InvoicePage";
import NotificationPage from "./pages/components/NotificationPage";
import ProtectedRoute from "./pages/components/ProtectedRoute";
import PurchasePage from "./pages/components/PurchasePage";
import PaymentReceiveTab from "./Tables/PaymentReceiveTab";
import PaymentPage from "./pages/components/PaymentPage";
import PaymentReceivePage from "./pages/components/PaymentReceivePage";
import ServiceSettingsPage from "./pages/components/ServiceSettingsPage";
import SignaturePage from "./pages/components/SignaturePage";
import UserDetails from "./pages/components/UserDetails";
import UserSettingsTab from "./pages/components/UserSettingsTab";
import VerifyCodePage from "./pages/Authentication/VerifyCodePage";
import SalesReturn from "./pages/components/SalesReturn";
import PurchaseReturn from "./pages/components/PurchaseReturn";
import PurchaseTableNew from "./Tables/PurchaseTableNew";
import PurchaseReturnTab from "./pages/components/PurchaseDetails/PurchaseReturnTab";
import SalesReturnTab from "./Tables/SalesReturnTab";
import PaymentOutTab from "./Tables/PaymentOutTab";
import PaymentOutPage from "./pages/components/PaymentOutPage";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <Container />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/:id",
        element: <HomePage />,
      },
      {
        path: "/login",
        element: <LoginPage />,
      },
      {
        path: "/register",
        element: <RegisterPage />,
      },
      {
        path: "/forgetPassword",
        element: <ForgetPassword />,
      },
      {
        path: "/changePassword",
        element: <ChangePassword />,
      },
      {
        path: "/OtpScreenPage",
        element: <OtpScreenPage />,
      },
      {
        path: "/registration/:id",
        element: <MailVerification />,
      },
      {
        path: '/verify',
        element: <VerifyCodePage />,
      }
    ],
  },
  {
    path: "/app",
    element: <AuthenticatedRoutes />,
    children: [
      {
        path: "home",
        element: <DashboardHome />,
      },
      {
        path: "client",
        element: <ClientPage />,
      },
      {
        path: "vendors",
        element: <VendorTab />,
      },
      {
        path: "invoice",
        element: <InvoicePage />,
      },
      {
        path: "invoice/:id",
        element: <InvoicePage />,
      },
      {
        path: "product",
        element: <ProductTab />,
      },
      {
        path: "company",
        element: <CompanyPage />,
      },
      {
        path: "purchase",
        element: <PurchaseTableNew />,
      },
      {
        path: "purchaseReturn",
        element: <PurchaseReturnTab />,
      },
      {
        path: "purchaseReturnInvoice",
        element: <PurchaseReturn />,
      },
      {
        path: "purchaseReturnInvoice/:id",
        element: <PurchaseReturn />,
      },
      {
        path: "purchasePage",
        element: <PurchasePage />,
      },
      {
        path: "purchasePage/:id",
        element: <PurchasePage />,
      },
      {
        path: "sales",
        element: <SalesTabe />,
      },
      {
        path: "salesReturnTab",
        element: <SalesReturnTab />,
      },
      {
        path: "salesreturn",
        element: <SalesReturn />,
      },
      {
        path: "salesreturn/:id",
        element: <SalesReturn />,
      },
      {
        path: "stock",
        element: <Stock />,
      },
      {
        path: "payments",
        element: <PaymentReceiveTab />,
      },
      {
        path: "paymentsReceive",
        element: <PaymentReceivePage />,
      },
      {
        path: "paymentsReceive/:id",
        element: <PaymentReceivePage />,
      },
      {
        path: "service",
        element: <ServiceTab />,
      },
      {
        path: "serviceSettings",
        element: <ServiceSettingsPage />,
      },
      {
        path: "signature",
        element: <SignaturePage />,
      },
      {
        path: "appSetting",
        element: <AppSettingPage />,
      },
      {
        path: "paymentsOut",
        element: <PaymentOutTab/>,
      },
      {
        path: "paymentOutPage",
        element: <PaymentOutPage/>,
      },
      {
        path: "paymentOutPage/:id",
        element: <PaymentOutPage/>,
      },
      {
        path: "feedback",
        // element: <FeedbackPage />,
        element: (
          <ProtectedRoute
            path="feedback"
            element={<FeedbackPage />}
            requiredRole="Admin"
          />
        ),
      },
      {
        path: "notification",
        element: <NotificationPage />,
      },
      {
        path: "Users",
        element: <User />,
      },
      {
        path: "AdminPayment",
        element: <AdminPayments />,
      },
      {
        path: "ExternalInvoice",
        element: <ExternalInvoice />,
      },
      {
        path: "AdminDashboard",
        element: <AdminDashboard />,
      },
      {
        path: "Units",
        element: <Unit />,
      },
      {
        path: "adduser",
        element: <UserDetails />,
      },
      {
        path: "unauthorized",
        element: <Unauthorized />,
      },
      {
        path: "usersetting",
        element: <UserSettingsTab />,
      },
    ],
  },
]);
export default routes;
