import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { InternalGetCount, paymentPendingBillList } from "../../redux/actions/InternalInvoiceAction";
import {
    paymentReceiveEditFindOne,
    paymentReceivePost,
    paymentReceiveUpdate
} from "../../redux/actions/paymentReceiveAction";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmationDownload from "../commen/ConfirmationDownload";
import { fontFamilyGet } from "../../redux/actions/FontFamilyAction";
import { currencyGet } from "../../redux/actions/currencyAction";
import { useData } from "../../layouts/shared/DataProvider";
import moment from "moment";

const PaymentReceivePage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [pendingInvoiceList, setPendingInvoiceList] = useState([] as any);
    const [clientName, setclientName] = useState(null as any);

    const [invoiceNo, setinvoiceNo] = useState(null as any);
    const [pendinginvoiceNo, setPendingInvoiceNo] = useState(null as any);
    const [selectedOption, setselectedOption] = useState("");
    const [paymentType, setPaymentType] = useState(null as any);
    const [selectedInvoiceNo, setSelectedInvoiceNo] = useState(null as any);
    const [cashAmount, setcashAmount] = useState(0);
    const [chequeAmount, setchequeAmount] = useState(0);
    const { selectedFont, setSelectedFont }: any = useData();
    const [paymentInReceiptNo, setPaymentInReceiptNo] = useState("");
    const usersignin = useSelector((state: any) => state?.userSignin);
    const token = usersignin?.userInfo?.token;


    const { userInfo } = usersignin;
    const userid = userInfo.token._id;
    const params = useParams();
    const { id } = params;

    const {
        register,
        handleSubmit,
        setValue,
        reset,

        formState: { errors } } = useForm({
            defaultValues: {
                billModule: "PRODUCT",
                clientName: "",
                invoiceNO: "",
                balanceAmount: "",
                paymentType: "",
                chequeNum: "",
                receivedAmount: "",
                user_id: userid,

            },
        });


    useEffect(() => {
        dispatch(fontFamilyGet(token?._id) as any).then((res: any) => {
            if (res?.type === "FONTFAMILY_GET_SUCCESS") {
                let data = res?.payload?.find(
                    (value: any) => value?.defaultInfo === true,
                );
                if (data) {
                    setSelectedFont(data?.fontfamily);
                } else {
                    setSelectedFont("poppins");
                }
            }
        });
    }, []);

    const [currencySymbol, setcurrencySymbol] = useState("₹");
    useEffect(() => {

        dispatch(currencyGet(token?._id) as any).then((res: any) => {
            if (res) {
                let data = res.payload.find((value: any) => value.defaultInfo === true);
                if (data?.currencySymbol) {
                    setcurrencySymbol(data.currencySymbol);
                } else {
                    setcurrencySymbol("₹");
                }
            }
        });
    }, []);


    const uniqueClients = Array?.from(
        new Set(pendingInvoiceList?.map((item: any) => item.clientName))
    ).map((name: any) => {
        return pendingInvoiceList?.find((item: any) => item.clientName === name);
    });

    const uniqueInvoiceNo = pendingInvoiceList?.filter(
        (value: any) => value?.invoiceNo === invoiceNo

    );
    const getInvoiceList = (clientName: string) => {
        let filteredInvoices = pendingInvoiceList.filter(
            (invoice: any) => invoice.clientName === clientName
        );
        let invoiceNumbers = filteredInvoices.map(
            (invoice: any) => invoice.invoiceNo
        );

        // Update the state with the fetched invoice numbers
        setPendingInvoiceNo(invoiceNumbers);
    };


    const fetchData = () => {
        dispatch(paymentPendingBillList() as any).then((res: any) => {
            if (res) {
                setPendingInvoiceList(res.payload);
            }
        });
        dispatch(InternalGetCount() as any).then((data: any) => {
            setPaymentInReceiptNo(data?.payload?.paymentInReceiptCount)
        });
    };

    useEffect(() => {
        const fetchDataAsync = async () => {
            try {
                if (id) {
                    const response = await dispatch(paymentReceiveEditFindOne(id) as any);
                    const clientName = response.payload.client_name;
                    setclientName(clientName);
                    setValue('clientName', response.payload.client_name);
                    setSelectedInvoiceNo(response.payload.invoiceNo)
                    setValue('balanceAmount', response.payload.previousBalanceAmount);
                    setValue('receivedAmount', response.payload.receivedAmount);
                    setValue('paymentType', response.payload.paymentType);
                    setValue('paymentType', response.payload.paymentType);
                    setPaymentType(response.payload.paymentType)
                } else {
                    fetchData();
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                // Handle errors as needed
            }
        };

        fetchDataAsync(); // Call the async function
    }, [id, dispatch, setValue]);

    const handleCancelClick = () => {
        let defaultValue = {
            clientName: "",
            invoiceNO: "",
            balanceAmount: "",
            receivedAmount: "",
            paymentType: "",
        }
        reset({ ...defaultValue });
    }

    const handleClientDropdownChange = (value: any) => {
        setclientName(value);
        getInvoiceList(value)
        setinvoiceNo("");
    };
    const handleInivoiceNo = (value: any) => {
        setinvoiceNo(value);

    };
    const handlePaymentType = (value: any) => {
        setselectedOption(value);
    };


    const onSubmit = (data: any) => {
        //const previousBalanceAmount = parseFloat(data.balanceAmount);

        const paymentType = data.paymentType;
        if (paymentType === 'CASH') {
            setcashAmount(data.receivedAmount)
            setchequeAmount(0)
        }
        else {
            setchequeAmount(data.receivedAmount)
            setcashAmount(0)
        }
        if (id) {

            let postupdatedata = {
                //balanceAmount: previousBalanceAmount - data.receivedAmount,
                chequeNum: data.chequeNum,
                paymentType: data.paymentType,
                receivedAmount: data.receivedAmount,
                cashAmount: cashAmount,
                chequeAmount: chequeAmount,
                creditAmount: 0,
                invoiceNO: data.invoiceNO,
                user_id: userid,
            };
            dispatch(paymentReceiveUpdate(id, postupdatedata) as any).then((res: any) => {
                if (id) {
                    const queryParams = new URLSearchParams();

                    queryParams.append("param6", selectedFont);
                    queryParams.append("param7", currencySymbol);

                    const url = `/api/paymentreceipt_pdf/${id}/${userid}?${queryParams.toString()}`;

                    fetch(url, {
                        method: "GET",
                    })
                        .then((response) => {
                            if (!response.ok) {
                                throw new Error("Network response was not ok");
                            }
                            return response.blob();
                        })
                        .then((blob) => {
                            const url = window.URL.createObjectURL(new Blob([blob]));
                            const link: any = document.createElement("a");
                            link.href = url;
                            const formattedDate = moment().format("YYYY-MM-DD");
                            link.setAttribute(
                                "download",
                                `paymentreceipt_${formattedDate}.pdf`,
                            );
                            document.body.appendChild(link);
                            link.click();
                            link.parentNode.removeChild(link);
                            //setProductError(false);
                            //setInvoiceUpdateId(response?.payload?.invoice_id);
                        })
                        .catch((error) => console.error("Error downloading PDF:", error));
                }
                if (res) {
                    fetchData();
                    toast.success("Payment Update Successfully!", {
                        position: toast.POSITION.BOTTOM_CENTER,
                        autoClose: 800,
                    });
                    navigate("/app/payments");
                    // reset()
                    // setselectedOption("")
                    // setPendingInvoiceList("")
                }
            });
        }
        else {
            const balanceAmount = uniqueInvoiceNo?.[0]?.balanceAmount;
            const receivedAmount = parseFloat(data.receivedAmount);
            const balanceAmountNumber = parseFloat(balanceAmount);
            if (receivedAmount > balanceAmountNumber) {
                alert("The Amount Received cannot be Greater than the Balance amount.");
                return;
            }
            let postdata = {
                billModule: data.billModule,
                paymentInReceiptNo: paymentInReceiptNo,
                clientName: data.clientName,
                previousReceivedAmount: uniqueInvoiceNo?.[0]?.receivedAmount,
                previousBalanceAmount: uniqueInvoiceNo?.[0]?.balanceAmount,
                balanceAmount: uniqueInvoiceNo?.[0]?.balanceAmount - data.receivedAmount,
                billAmount: uniqueInvoiceNo?.[0]?.toatalAmount,
                chequeNum: data.chequeNum,
                invoiceNO: data.invoiceNO,
                cashAmount: cashAmount,
                chequeAmount: chequeAmount,
                creditAmount: 0,
                paymentType: data.paymentType,
                receivedAmount: data.receivedAmount,
                user_id: userid,
            };

            dispatch(paymentReceivePost(postdata) as any).then((res: any) => {
                if (res?.payload?.paymentreceive_id) {
                    const queryParams = new URLSearchParams();

                    queryParams.append("param6", selectedFont);
                    queryParams.append("param7", currencySymbol);

                    const url = `/api/paymentreceipt_pdf/${res?.payload?.paymentreceive_id}/${userid}?${queryParams.toString()}`;

                    fetch(url, {
                        method: "GET",
                    })
                        .then((response) => {
                            if (!response.ok) {
                                throw new Error("Network response was not ok");
                            }
                            return response.blob();
                        })
                        .then((blob) => {
                            const url = window.URL.createObjectURL(new Blob([blob]));
                            const link: any = document.createElement("a");
                            link.href = url;
                            const formattedDate = moment().format("YYYY-MM-DD");
                            link.setAttribute(
                                "download",
                                `paymentreceipt_${formattedDate}.pdf`,
                            );
                            document.body.appendChild(link);
                            link.click();
                            link.parentNode.removeChild(link);
                            //setProductError(false);
                            //setInvoiceUpdateId(response?.payload?.invoice_id);
                        })
                        .catch((error) => console.error("Error downloading PDF:", error));
                }
                if (res) {
                    fetchData();
                    toast.success("Payment Saved Successfully!", {
                        position: toast.POSITION.BOTTOM_CENTER,
                        autoClose: 800,
                    });
                    navigate("/app/payments");
                    // reset()
                    // setselectedOption("")
                    // setPendingInvoiceList("")
                }
            });
        }
    };

    return (
        <>
            <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="bg-[#E1E8E7] " style={{ fontFamily: "poppins" }}>
                        <div className="p-4 text-2xl font-bold text-[#045545]  text-lg   ml-2">
                            Payment In
                        </div>
                        <fieldset className="mx-3 rounded-lg">
                            <legend className="p-4  font-bold text-[#008065] ">
                                Payment
                            </legend>
                            <div className="flex flex-col lg:flex-row xl:flex-row gap-2 pl-4  sm:px-3  sm:px-3 md:px-3 lg:px-3 xl:px-3 2xl:px-3 mb-4">
                                <div className="flex flex-col mb-2 w-48 md:w-full lg:w-1/3 xl:w-1/4">

                                    <label
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 700,
                                            color: "#008065",
                                        }}
                                        className="required"
                                    >
                                        <span>Bill Module</span>
                                    </label>
                                    <select
                                        id="billmodule"
                                        className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 lg:w-full"
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            borderColor: errors.billModule
                                                ? "#FF0000"
                                                : "#008065",
                                        }}
                                        {...register("billModule", {
                                            required: "Bill Module is required",
                                        })}
                                    >
                                        <option value="PRODUCT">PRODUCT</option>
                                    </select>
                                    {errors.billModule && <p style={{ fontFamily: "poppins", color: 'red' }}>{errors.billModule.message}</p>}
                                </div>
                                <div className="flex flex-col mb-2 w-48 md:w-full lg:w-1/3 xl:w-1/4">

                                    <label
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 700,
                                            color: "#008065",
                                        }}
                                        className="required"
                                    >
                                        <span>Clients Name</span>
                                    </label>
                                    <select
                                        id="clientsName"
                                        className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 lg:w-full"
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            borderColor: errors.clientName
                                                ? "#FF0000"
                                                : "#008065",
                                        }}
                                        value={clientName} // Control the value of the select
                                        {...register("clientName", {
                                            onChange: (e) =>
                                                handleClientDropdownChange(e.target.value),
                                            required: "Client Name is required",
                                        })}
                                    >
                                        <option value={id ? clientName : ""} >{id ? clientName : "Select Client Name"}</option>
                                        {uniqueClients?.map((item, i) => (
                                            < option
                                                className="w-full"
                                                key={i}
                                                value={item?.clientName}
                                            >
                                                {item?.clientName}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.clientName && <p style={{ fontFamily: "poppins", color: 'red' }}>{errors.clientName.message}</p>}
                                </div>
                                <div className="flex flex-col mb-2 w-48 md:w-full  lg:w-1/3 xl:w-1/4">
                                    <label
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 700,
                                            color: "#008065",
                                        }}
                                        className="required"
                                    >
                                        <span>Invoice No</span>
                                    </label>
                                    <select
                                        id="invoiceNO"
                                        className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 lg:w-full"
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            borderColor: errors.invoiceNO
                                                ? "#FF0000"
                                                : "#008065",
                                        }}
                                        value={invoiceNo}
                                        {...register("invoiceNO", {
                                            onChange: (e) =>
                                                handleInivoiceNo(e.target.value),
                                            required: "Invoice No is required",
                                        })}
                                    >
                                        <option value={id ? selectedInvoiceNo : ""}>{id ? selectedInvoiceNo : "Select Invoice NUmber"}</option>
                                        {pendinginvoiceNo?.map((item: any, i: any) => (
                                            < option className="w-full" key={i} value={item} >
                                                {item}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.invoiceNO && <p style={{ fontFamily: "poppins", color: 'red' }}>{errors.invoiceNO.message}</p>}
                                </div>
                                <div className="flex flex-col mb-2 w-48 md:w-full  lg:w-1/3 xl:w-1/4">
                                    <label
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 700,
                                            color: "#008065",
                                        }}
                                    >
                                        Balance Amount
                                    </label>
                                    <div className="relative">
                                        <input
                                            type="number"
                                            className={
                                                "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                                            }
                                            style={{
                                                fontFamily: "poppins",
                                                fontSize: "14px",
                                                fontWeight: 400,

                                            }}
                                            value={id ? uniqueInvoiceNo?.[0]?.balanceAmount : uniqueInvoiceNo?.[0]?.balanceAmount}
                                            {...register("balanceAmount")}
                                            readOnly
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className="flex flex-col lg:flex-row xl:flex-row gap-2 pl-4  sm:px-3  sm:px-3 md:px-3 lg:px-3 xl:px-3 2xl:px-3 mb-4">
                                <div className="flex flex-col mb-2 w-48 md:w-full  lg:w-1/3 xl:w-1/4">
                                    <label
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 700,
                                            color: "#008065",
                                        }}
                                        className="required"
                                    >
                                        <span> Amount Received</span>
                                    </label>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            className={
                                                "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                                            }
                                            style={{
                                                fontFamily: "poppins",
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                borderColor: errors.receivedAmount
                                                    ? "#FF0000"
                                                    : "#008065",
                                            }}

                                            {...register("receivedAmount", {
                                                onChange: (e) => {
                                                    const value = e.target.value.replace(/[^0-9]/g, "");
                                                    e.target.value = value;
                                                },
                                                required: "Amount is required",
                                            })}

                                        />
                                        {errors.receivedAmount && <p style={{ fontFamily: "poppins", color: 'red' }}>{errors.receivedAmount.message}</p>}
                                    </div>
                                </div>
                                <div className="flex flex-col mb-2 w-48 md:w-full  lg:w-1/3 xl:w-1/4">

                                    <label
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 700,
                                            color: "#008065",
                                        }}
                                        className="required"
                                    >
                                        <span> Payment Type  </span>
                                    </label>
                                    <select
                                        value={selectedOption}
                                        id="paymentType"
                                        className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 lg:w-full"
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "13px",
                                            fontWeight: 400,
                                            borderColor: errors.paymentType
                                                ? "#FF0000"
                                                : "#008065",
                                        }}
                                        {...register("paymentType", {
                                            onChange: (e) =>
                                                handlePaymentType(e.target.value),
                                            required: "Select The Payment Type",
                                        })}

                                    >
                                        <option value={id ? paymentType : ""}>{id ? paymentType : "Please Select Type"}</option>
                                        <option value="CASH">Cash</option>
                                        <option value="CHEQUE">Cheque</option>
                                    </select>
                                    {errors.paymentType && <p style={{ fontFamily: "poppins", color: 'red' }}>{errors.paymentType.message}</p>}
                                </div>
                                {selectedOption === "cheque" && (
                                    <div className="flex flex-col mb-2 w-48 md:w-full  lg:w-1/3 xl:w-1/4">
                                        <label
                                            style={{
                                                fontFamily: "poppins",
                                                fontSize: "14px",
                                                fontWeight: 700,
                                                color: "#008065",
                                            }}
                                        >
                                            Cheque No
                                        </label>
                                        <div className="relative">
                                            <input
                                                type="text"
                                                className={
                                                    "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                                                }
                                                style={{
                                                    fontFamily: "poppins",
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    borderColor: errors.chequeNum
                                                        ? "#FF0000"
                                                        : "#008065",
                                                }}
                                                {...register("chequeNum", {
                                                    required: "Check Number is Required",
                                                })}
                                                {...register("chequeNum", {
                                                    onChange: (e) => {
                                                        const value = e.target.value.replace(/[^0-9]/g, "");
                                                        e.target.value = value;
                                                    },
                                                    required: "Check Number is Required",
                                                })}

                                            />
                                            {errors.chequeNum && <p style={{ fontFamily: "poppins", color: 'red' }}>{errors.chequeNum.message}</p>}
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* <-Submit cancel-> */}
                            <div className="lg:flex md:flex justify-end mb-3">
                                <div className="flex justify-end w-full flex-col sm:flex-row pl-4">
                                    <div className="pr-3">
                                        <button
                                            type="button"
                                            className="rounded-xl mt-3 font-bold text-white  text-sm h-8 lg:w-[10rem] md:w-[10rem] sm:w-[10rem] w-48 bg-[#008065] "
                                            onClick={() => handleCancelClick()}
                                            style={{ fontFamily: "poppins", fontSize: "14px" }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                    <div className="pr-3">
                                        <button
                                            type="submit"
                                            className="rounded-xl mt-3 font-bold text-white  text-sm h-8 lg:w-[10rem] md:w-[10rem] sm:w-[10rem] w-48 bg-[#008065] "
                                            style={{ fontFamily: "poppins", fontSize: "14px" }}
                                        >
                                            {"Submit"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>

                </form >
            </div >

        </>
    );
};

export default PaymentReceivePage;
