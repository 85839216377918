import React, { useCallback, useEffect, useState } from "react";
import Revenue from "../../Svg/Revenue";
import ExpensesSvg from "../../Svg/ExpensesSvg";
import ReceivableSvg from "../../Svg/ReceivableSvg";
import AccountSvg from "../../Svg/AccountSvg";
import Purchase from "../../Svg/Purchase";
import SalesSvg from "../../Svg/SalesSvg";
import ClientsSvg from "../../Svg/ClientsSvg";
import ProductSvg from "../../Svg/ProductSvg";
import ChartsTwo from "../../Charts/ChartsTwo";
import ChartsOne from "../../Charts/ChartsOne";
import { useDispatch, } from "react-redux";
import { InternalDashboardGetCount } from "../../redux/actions/InternalInvoiceAction";
import { SalesgettMethod } from "../../redux/actions/SalesAction";
import { InternalFindNotification } from "../../redux/actions/NotificationAction";



// saleslist
const DashboardWidgets = () => {
  const dispatch = useDispatch();


  const [count, setCount] = useState([] as any);
  const [purchasecount, setpurchasecount] = useState("" as any);
  const [prodcount, setprodcount] = useState("" as any);
  const [clientcount, setclientcount] = useState("" as any);
  const [salesamount, setsalesamount] = useState("" as any);


  const fetchData = useCallback(() => {
    dispatch(InternalDashboardGetCount() as any).then((data: any) => {

      setCount(data?.payload?.counts);
      setpurchasecount(data?.payload?.Purchasecount);
      setprodcount(data?.payload?.Productscount);
      setclientcount(data?.payload?.clientcount);
      // setsalesamount(data?.payload?.salesamount);
    });
  }, []);

  const fetchSalesData = () => {
    dispatch(SalesgettMethod() as any).then((response: any) => {
      if (response?.type === "SALES_GET_SUCCESS") {
        setsalesamount(response.payload.length);
      }
      // setProductList(response?.payload);
    });
  };
  const fetchNotificationcount = () => {

    dispatch(InternalFindNotification() as any)

  };


  useEffect(() => {
    fetchData();
    fetchSalesData();
    fetchNotificationcount();
  }, []);

  return (
    <>
      <div>
        <div className="flex flex-col  pt-2 mx-3 mb-2">
          <div className=" mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4  xl:grid-cols-4 2xl:grid-cols-4">
            <div className="relative flex flex-grow !flex-row flex-col items-center rounded-[10px] rounded-[10px] border-[1px] border-gray-200 bg-white bg-clip-border shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none w-11/12 lg:w-full xl:w-11/12">
              <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
                <div className="rounded-lg bg-[#fee2e2] p-1.5 dark:bg-navy-700">
                  <span className="flex items-center dark:text-white ">
                    <Purchase />
                  </span>
                </div>
              </div>
              <div className="h-50 ml-4 flex w-auto flex-col justify-center">
                <p className="font-medium text-gray-600 text-sm font-bold">
                  Purchase
                </p>
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                  {purchasecount}
                </h4>
              </div>
            </div>
            <div className="relative flex flex-grow !flex-row flex-col items-center rounded-[10px] rounded-[10px] border-[1px] border-gray-200 bg-white bg-clip-border shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none w-11/12 lg:w-full xl:w-11/12">
              <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
                <div className="rounded-lg p-2.5  bg-[#dcfce7] dark:bg-navy-700">
                  <span className="flex items-center  dark:text-white">
                    <SalesSvg />
                  </span>
                </div>
              </div>
              <div className="h-50 ml-4 flex w-auto flex-col justify-center">
                <p className="font-medium text-gray-600 text-sm font-bold">
                  Sales
                </p>
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                  {salesamount}
                </h4>
              </div>
            </div>
            <div className="relative flex flex-grow !flex-row flex-col items-center rounded-[10px] rounded-[10px] border-[1px] border-gray-200 bg-white bg-clip-border shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none w-11/12 lg:w-full xl:w-11/12">
              <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
                <div className="rounded-lg bg-[#f3e8ff]  p-2.5  dark:bg-navy-700">
                  <span className="flex items-center  dark:text-white">
                    <ClientsSvg />
                  </span>
                </div>
              </div>
              <div className="h-50 ml-4 flex w-auto flex-col justify-center">
                <p className="font-medium text-gray-600 text-sm font-bold">
                  Clients
                </p>
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                  {clientcount}
                </h4>
              </div>
            </div>
            <div className="relative flex flex-grow !flex-row flex-col items-center rounded-[10px] rounded-[10px] border-[1px] border-gray-200 bg-white bg-clip-border shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none w-11/12 lg:w-full xl:w-11/12">
              <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
                <div className="rounded-lg bg-[#ffedd5] p-1.5 dark:bg-navy-700">
                  <span className="flex items-center p-1.5 dark:text-white">
                    <ProductSvg />
                  </span>
                </div>
              </div>
              <div className="h-50 ml-4 flex w-auto flex-col justify-center">
                <p className=" font-medium text-gray-600 text-sm font-bold">
                  Products
                </p>
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                  {prodcount}
                </h4>
              </div>
            </div>
            <div className="relative flex flex-grow !flex-row flex-col items-center rounded-[10px] rounded-[10px] border-[1px] border-gray-200 bg-white bg-clip-border shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none w-11/12 lg:w-full xl:w-11/12">
              <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
                <div className="rounded-lg bg-[#fef3c7]  dark:bg-[red]-700">
                  <span className="flex items-center  dark:text-white">
                    <Revenue />
                  </span>
                </div>
              </div>
              <div className="h-50 ml-4 flex w-auto flex-col justify-center">
                <p className=" font-medium text-gray-600 text-sm font-bold">
                  Total Revenue
                </p>
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                  0.00
                </h4>
              </div>
            </div>
            <div className="relative flex flex-grow !flex-row flex-col items-center rounded-[10px] rounded-[10px] border-[1px] border-gray-200 bg-white bg-clip-border shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none w-11/12 lg:w-full xl:w-11/12">
              <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
                <div className="rounded-lg bg-[#ede9fe] dark:bg-navy-700">
                  <span className="flex items-center  dark:text-white">
                    <ExpensesSvg />
                  </span>
                </div>
              </div>
              <div className="h-50 ml-4 flex w-auto flex-col justify-center">
                <p className=" font-medium text-gray-600 text-sm font-bold">
                  Total Expenses
                </p>
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                  0.00
                </h4>
              </div>
            </div>
            <div className="relative flex flex-grow !flex-row flex-col items-center rounded-[10px] rounded-[10px] border-[1px] border-gray-200 bg-white bg-clip-border shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none w-11/12  lg:w-full xl:w-11/12">
              <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
                <div className="rounded-lg bg-[#cffafe] dark:bg-navy-700">
                  <span className="flex items-center dark:text-white">
                    <ReceivableSvg />
                  </span>
                </div>
              </div>
              <div className="h-50 ml-4 flex w-auto flex-col justify-center">
                <p className="font-medium text-gray-600 text-sm font-bold">
                  Accounts Receivable
                </p>
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                  0.00
                </h4>
              </div>
            </div>
            <div className="relative flex flex-grow !flex-row flex-col items-center rounded-[10px] rounded-[10px] border-[1px] border-gray-200 bg-white bg-clip-border shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none w-11/12 lg:w-full xl:w-11/12">
              <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
                <div className="rounded-lg bg-[#fce7f3] p-1.5  dark:bg-navy-700">
                  <span className="flex items-center text-brand-500 text-2xl dark:text-white">
                    <AccountSvg />
                  </span>
                </div>
              </div>
              <div className="h-50 ml-4 flex w-auto flex-col justify-center">
                <p className="font-medium text-gray-600 text-sm font-bold">
                  Accounts payable
                </p>
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                  0.00
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-1 xl:grid-cols-2 mt-4 gap-6">
        <div className="rounded-lg ">
          <ChartsOne count={count} />
        </div>
        <div className="rounded-lg ">
          <ChartsTwo />
        </div>
      </div>
    </>
  );
};

export default DashboardWidgets;