import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { thunk } from "redux-thunk";
import {
  userGetDataReducer,
  userGetReducer,
  userRegisterReducer,
  userSigninReducer,
  userUpdateOne,
} from "./reducers/userReducers";
import { userProductgetReducer } from "./reducers/productReducers";
import {
  companyDeleteReducer,
  companyListsReducer,
  companySaveReducer,
} from "./reducers/companyReducer";
import { userStockGetReducer, userStockPurchaseGetReducer } from "./reducers/stockReducer";
import { getCountsListsReducer, salesListsReducer } from "./reducers/InternalInvoiceReducer";
import { unitGetPageReducer, unitGetReducer } from "./reducers/unitReducer";
import { paymentGetReducer, paymentGetpageReducer } from "./reducers/paymentReducer";
import { SalesGetReducer } from "./reducers/salesReducer";
import { userAppSignGetReducer } from "./reducers/appSettingReducer";
import { GetOneUpiDetailsReducer, upiPaymentDeleteReducer, upiPaymentGetReducer, upiPaymentPostReducer, upiPaymentUpdateReducer } from "./reducers/upiPaymentReducer";
import { GetBankListReducer, GetOneBankDetailsReducer } from "./reducers/bankReducer";
import { GetOneTermDetailsReducer, GetTermListReducer } from "./reducers/termsAndConditionReducer";
import { externalGetAllReducer, externalGetReducer } from "./reducers/externalInvoiceReducer";
import { GetCurrencyDetailsReducer } from "./reducers/currencyReducer";
import { GetFontDetailsReducer } from "./reducers/fontFamilyReducer";
import { NotificationReducer } from "./reducers/notificationReducer";
import { clientgetReducer } from "./reducers/clientReducers";
import { serviceGetReducer } from "./reducers/serviceReducer";
import { paymentReceiveReducer } from "./reducers/paymentReceiveReducer";
import { purchaseNewGetReducer } from "./reducers/purchasepageNewReducer";
const local: any = sessionStorage.getItem("userInfo");

const initialState = {
  userSignin: {
    userInfo: sessionStorage.getItem("userInfo") ? JSON.parse(local) : null,
  },
};

const reducer = combineReducers({
  userRegister: userRegisterReducer,
  userSignin: userSigninReducer,
  userProductget: userProductgetReducer,
  clientget: clientgetReducer,
  companySave: companySaveReducer,
  companyLists: companyListsReducer,
  companyDelete: companyDeleteReducer,
  stockList: userStockGetReducer,
  stockPurchaseList:userStockPurchaseGetReducer,
  salesList: salesListsReducer,
  unitList: unitGetReducer,
  PaymentData: paymentGetReducer,
  SalesList: SalesGetReducer,
  SignatureList: userAppSignGetReducer,
  upiPaymentGetReducer: upiPaymentGetReducer,
  getOneUpiDetailsReducer: GetOneUpiDetailsReducer,
  upiPaymentPostReducer: upiPaymentPostReducer,
  upiPaymentUpdateReducer: upiPaymentUpdateReducer,
  upiPaymentDeleteReducer: upiPaymentDeleteReducer,
  getBankListReducer: GetBankListReducer,
  getOneBankDetailsReducer: GetOneBankDetailsReducer,
  getTermListReducer: GetTermListReducer,
  getOneTermDetailsReducer: GetOneTermDetailsReducer,
  userGetData: userGetDataReducer,
  externalGet: externalGetReducer,
  paymentGetpage: paymentGetpageReducer,
  unitGetPage: unitGetPageReducer,
  getCountsLists: getCountsListsReducer,
  userColorTemplate: userUpdateOne,
  GetCurrencyDetails: GetCurrencyDetailsReducer,
  GetFontDetails: GetFontDetailsReducer,
  NotificationCount: NotificationReducer,
  userGet: userGetReducer,
  externalGetAll: externalGetAllReducer,
  serviceGet: serviceGetReducer,
  paymentReceiveGet: paymentReceiveReducer,
  purchaseNewGet:purchaseNewGetReducer,
});

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducer,
  initialState,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
