import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import ProductPage from "../ProductPage";

import { ProductsGet } from "../../../redux/actions/productAction";
import ProductsBulkUpload from "../ProductsBulkUpload";

const ProductTab = () => {
    const User_id = sessionStorage.getItem("userInfo");
    const userId = JSON.parse(User_id as any);
    const dispatch = useDispatch()
    const [openTab, setOpenTab] = useState(1);
    const [productLists, setProductLists] = useState([] as any);

    const fetchData = () => {
        dispatch(ProductsGet(userId?.token?._id) as any).then((res: any) => {
            if (res) {
                setProductLists(res.payload);
            }
        });
    };

    useEffect(() => {
        if (userId?.token?._id) {
            fetchData();
            
        }
    }, [openTab]);
    return (
        <div>
            <div className="bg-[#E1E8E7] lg:h-[650px] xl:h-[650px] 2xl:h-[650px] h-screen  ">
                <div className=" ">
                    <ul className="flex flex-wrap text-sm font-medium text-center text-[#008065]  dark:text-gray-400 ">
                        <li className="-mb-px mr-1">
                            <button
                                style={{
                                    borderBottom: openTab === 1 ? "2px solid #008065" : "",
                                }}
                                onClick={() => setOpenTab(1)}
                                className={
                                    "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg font-bold"
                                }
                            >
                                Product Details
                            </button>
                        </li>
                        <li className="mr-1">
                            <button
                                onClick={() => setOpenTab(2)}
                                style={{
                                    borderBottom: openTab === 2 ? "2px solid #008065" : "",
                                }}
                                className={
                                    "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg font-bold"
                                }
                            >
                                Products Bulk Upload
                            </button>
                        </li>


                    </ul>
                </div>
                <div className=" mt-6 ">
                    <div className={openTab === 1 ? "block  " : "hidden"}>
                        <ProductPage setProductLists={setProductLists} productLists={productLists}/>
                    </div>
                    {openTab === 2 && (
                        <div className={openTab === 2 ? "block" : "hidden"}>
                            <ProductsBulkUpload />
                        </div>
                    )}

                </div>
            </div>
        </div>
    );
};

export default ProductTab;
