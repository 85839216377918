import { useEffect, useState } from 'react';
import BankModel from '../../models/BankModel';

function BankDetails({ formData, downloadClicked,
    setDownloadClicked, handleChange, setFormData, handleOpen, setIsBankDetailsOpen, editValue, bankList }: any) {
    const [errors, setErrors] = useState({
        bankNme: "",
        accounHolder: "",
        accounNumber: "",
        ifscCode: "",
    } as any);
    const test = () => {
        if ((formData.bankNme || formData.accounHolder || formData.accounNumber || formData.ifscCode) && downloadClicked) {
            return true;
        }
        setDownloadClicked(false);
        return false;
    }

    const [isModalBank, setIsModalBank] = useState(false);
    const handleBanking = () => {
        setIsModalBank(true);
    };
    const closeModal = () => {
        setIsModalBank(false);
    }
    const handleSelect = (data: any) => {
        setErrors((prevValue: any) => {
            return {
                ...prevValue,
                bankNme: data.bank_name ? data.bank_name : '',
                accounHolder: data.holder_name ? data.holder_name : '',
                accounNumber: data.account_number ? data.account_number : '',
                ifscCode: data.ifsc_code ? data.ifsc_code : '',
            }
        })
        setFormData({
            ...formData,
            bankNme: data.bank_name,
            accounHolder: data.holder_name,
            accounNumber: data.account_number,
            ifscCode: data.ifsc_code,

        });
        setIsModalBank(false);
    };

    useEffect(() => {
        if (editValue || bankList) {

            setErrors((prevValue: any) => {
                return {
                    ...prevValue,
                    bankNme: formData?.bankNme ? formData?.bankNme : '',
                    accounHolder: formData?.accounHolder ? formData?.accounHolder : '',
                    accounNumber: formData?.accounNumber ? formData?.accounNumber : '',
                    ifscCode: formData?.ifscCode ? formData?.ifscCode : '',
                }
            })
        }

    }, [editValue, bankList])
    return (
        <div className='sm:pl-5'>
            <div className={`felx-col`}
            >

                <div className="flex justify-between">
                    <label className="block my-2 text-sm font-semibold text-black">
                        Bank Details:
                    </label>
                    <div className="block mb-2 mt-1 ">
                        <button
                            type="button"
                            className="mr-6 mt-1 text-sm font-semibold text-[#008065]"
                            onClick={handleBanking}
                        >
                            Add Existing Banks
                        </button>
                    </div>
                </div>
                {isModalBank && <BankModel closeModal={closeModal} handleSelect={handleSelect} />}
                <div className="flex flex-row">
                    <div className="w-full  border-2 border-[#008065] border-solid  rounded">
                        <div className="text-gray-700 md:flex md:items-center mx-6 p-2">
                            <div className="mb-1 md:mb-0 md:w-1/3">
                                <label
                                    style={{
                                        fontFamily: "poppins",
                                        fontSize: "14px",
                                        fontWeight: 600,
                                    }}
                                >
                                    BankName:
                                </label>
                            </div>
                            <div className="md:w-2/3 md:flex-grow">
                                <input
                                    className="w-full h-10 px-3 text-base placeholder-gray-600 border  rounded-lg focus:outline-none "
                                    type="text"
                                    placeholder="Bank name..."
                                    name="bankNme"
                                    style={{
                                        fontFamily: "poppins",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        borderColor: !errors.bankNme && test() ? "red" : "#008065",
                                    }}
                                    value={formData.bankNme}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setErrors((prevValue: any) => {
                                            return { ...prevValue, bankNme: e.target.value }
                                        })
                                    }}
                                />
                                {!errors.bankNme && test() && (
                                    <p
                                        style={{
                                            fontFamily: "poppins",
                                            fontWeight: 700,
                                            color: "#FF0000",
                                        }}
                                        className="text-red-400 text-xs"
                                    >
                                        {"BankName is required"}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="text-gray-700 md:flex md:items-center mx-6 p-2">
                            <div className="mb-1 md:mb-0 md:w-1/3">
                                <label
                                    style={{
                                        fontFamily: "poppins",
                                        fontSize: "14px",
                                        fontWeight: 600,
                                    }}
                                >
                                    Account HolderName:
                                </label>
                            </div>
                            <div className="md:w-2/3 md:flex-grow">
                                <input
                                    className="w-full h-10 px-3 text-base border placeholder-gray-600 rounded-lg focus:outline-none "
                                    type="text"
                                    placeholder="Holder name..."
                                    name="accounHolder"
                                    value={formData.accounHolder}
                                    style={{
                                        fontFamily: "poppins",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        borderColor: !errors.accounHolder && test() ? "red" : "#008065",
                                    }}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setErrors((prevValue: any) => {
                                            return { ...prevValue, accounHolder: e.target.value }
                                        })
                                    }}
                                />
                                {!errors.accounHolder && test() && (
                                    <p
                                        style={{
                                            fontFamily: "poppins",
                                            fontWeight: 700,
                                            color: "#FF0000",
                                        }}
                                        className="text-red-400 text-xs"
                                    >
                                        {"Account HolderName is required"}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="text-gray-700 md:flex md:items-center mx-6 p-2">
                            <div className="mb-1 md:mb-0 md:w-1/3">
                                <label
                                    style={{
                                        fontFamily: "poppins",
                                        fontSize: "14px",
                                        fontWeight: 600,
                                    }}
                                >
                                    Account Number:
                                </label>
                            </div>
                            <div className="md:w-2/3 md:flex-grow">
                                <input
                                    className="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:outline-none "
                                    type="text"
                                    placeholder=" Account Number..."
                                    name="accounNumber"
                                    value={formData.accounNumber}
                                    style={{
                                        fontFamily: "poppins",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        borderColor: !errors.accounNumber && test() ? "red" : "#008065",
                                    }}
                                    onChange={(e) => {
                                        handleChange(e)
                                        setErrors((prevValue: any) => {
                                            return { ...prevValue, accounNumber: e.target.value }
                                        })
                                    }}
                                />
                                {!errors.accounNumber && test() && (
                                    <p
                                        style={{
                                            fontFamily: "poppins",
                                            fontWeight: 700,
                                            color: "#FF0000",
                                        }}
                                        className="text-red-400 text-xs"
                                    >
                                        {"Account Number is required"}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="text-gray-700 md:flex md:items-center mx-6 p-2">
                            <div className="mb-1 md:mb-0 md:w-1/3">
                                <label
                                    style={{
                                        fontFamily: "poppins",
                                        fontSize: "14px",
                                        fontWeight: 600,
                                    }}
                                >
                                    IFSC code:
                                </label>
                            </div>
                            <div className="md:w-2/3 md:flex-grow">
                                <input
                                    className="w-full h-10 px-3 text-base  placeholder-gray-600 border rounded-lg focus:outline-none "
                                    type="text"
                                    placeholder=" IFSC Number..."
                                    name="ifscCode"
                                    value={formData.ifscCode}
                                    style={{
                                        fontFamily: "poppins",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        borderColor: !errors.ifscCode && test() ? "red" : "#008065",
                                    }}
                                    onChange={(e) => {
                                        handleChange(e)
                                        setErrors((prevValue: any) => {
                                            return { ...prevValue, ifscCode: e.target.value }
                                        })
                                    }}
                                />
                                {!errors.ifscCode && test() && (
                                    <p
                                        style={{
                                            fontFamily: "poppins",
                                            fontWeight: 700,
                                            color: "#FF0000",
                                        }}
                                        className="text-red-400 text-xs"
                                    >
                                        {"IFSC code is required"}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div>
                        <button
                            onClick={() => handleOpen("addBank")}
                            type="button"
                        >
                            <svg
                                className="h-6  text-grey"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1"
                                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default BankDetails
