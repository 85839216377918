import api from "../axiosConfig";
import {
   INVOICESETTING_DELETE_FAIL,
   INVOICESETTING_DELETE_REQUEST,
   INVOICESETTING_DELETE_SUCCESS,
   INVOICESETTING_POST_FAIL,
   INVOICESETTING_POST_REQUEST,
   INVOICESETTING_POST_SUCCESS,
   INVOICESETTING_UPDATE_FAIL,
   INVOICESETTING_UPDATE_REQUEST,
   INVOICESETTING_UPDATE_SUCCESS,
   INVOICESETTING__GET_FAIL,
   INVOICESETTING__GET_REQUEST,
   INVOICESETTING__GET_SUCCESS,
} from "../constants/appSettingConstants";

export const AppInvoiceSettingSave =
   (appSetting: any) => async (dispatch: any, getState: any) => {
      dispatch({ type: INVOICESETTING_POST_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         const { data } = await api.post(
            `/api/invoiceSettings/tittle`,
            appSetting,
            {
               headers: { Authorization: `Bearer ${token?.tocken}` },
            }
         );
         return dispatch({ type: INVOICESETTING_POST_SUCCESS, payload: data });
      } catch (error: any) {
         dispatch({
            type: INVOICESETTING_POST_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };

export const invoiceSettingsGet =
   (id: any) => async (dispatch: any, getState: any) => {
      dispatch({ type: INVOICESETTING__GET_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();

         const { data } = await api.get(
            `/api/invoiceSettings/getTittle/${id}`,
            {
               headers: { Authorization: `Bearer ${token?.tocken}` },
            }
         );
         return dispatch({ type: INVOICESETTING__GET_SUCCESS, payload: data });
      } catch (error: any) {
         dispatch({
            type: INVOICESETTING__GET_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };

export const invoiceSettingsUpdate =
   (id: any, currency: any) => async (dispatch: any, getState: any) => {
      dispatch({ type: INVOICESETTING_UPDATE_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         currency.user_id = token._id;
         const { data } = await api.put(
            `/api/invoiceSettings/update/${id}`,
            currency,
            {
               headers: { Authorization: `Bearer ${token?.tocken}` },
            }
         );
         return dispatch({
            type: INVOICESETTING_UPDATE_SUCCESS,
            payload: data,
         });
      } catch (error: any) {
         dispatch({
            type: INVOICESETTING_UPDATE_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };

export const InvoiceSettingDelete =
   (id: any) => async (dispatch: any, getState: any) => {
      dispatch({ type: INVOICESETTING_DELETE_REQUEST });
      try {
         const {
            userSignin: {
               userInfo: { token },
            },
         } = getState();
         const { data } = await api.delete(
            `/api/invoiceSettings/delete/${id}`,
            {
               headers: { Authorization: `Bearer ${token?.tocken}` },
            }
         );
         return dispatch({
            type: INVOICESETTING_DELETE_SUCCESS,
            payload: data,
         });
      } catch (error: any) {
         dispatch({
            type: INVOICESETTING_DELETE_FAIL,
            payload:
               error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message,
         });
      }
   };
