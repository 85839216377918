import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ServiceDetails, ServiceDetailsGet, ServicesDeactive, serviceUpdate } from '../../redux/actions/serviceActions';
import ServiceTable from '../../Tables/ServiceTable';
import ConfirmationDeactivePopup from '../commen/ConfirmationDeactivePopup';
import { serviceUnitsAll } from '../../redux/actions/serviceUnitsActions';

const ServiceSettings = () => {
  const usersignin = useSelector((state: any) => state.userSignin);
  const token = usersignin?.userInfo?.token;

  const dispatch = useDispatch()
  const [serviceId, setServiceId] = useState("serv-0001" as any);

  const [formData, setFormData] = useState({
    service_id: null,
    service_name: "",
    rate: "",
    units: "",
    description: "",
    status: true,
    user_id: token?._id,
  } as any);

  const [serviceList, setServiceList] = useState([] as any);
  const [serv_id, setServid] = useState(null as any);
  const [unitEdit, setUnitEdit] = useState(null as any);

  const [selectedUnit, setSelectedUnit] = useState("");
  const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedUnit(event.target.value);
    setFormData({ ...formData, units: event.target.value });
    setServicePageErrors({ ...servicePageErrors, units: "" })
  };
  const [serviceUnits, setServiceUnits] = useState([] as any);


  const handleChange = (e: any) => {
    const { name, value } = e.target;
    let newvalue = value.trimStart();
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: newvalue,
    }));
    setServicePageErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };
  const [servicePageErrors, setServicePageErrors] = useState<{
    service_id?: string;
    service_name?: string;
    rate?: string;
    units?: string
  }>({});

  const validateProdForm = () => {
    let isValid = true;
    const errors: any = {};

    // if (formData?.service_id.trim() === "") {
    //   console.log("Service ID Before Validation:", formData.service_id);
    //   console.log("Trimmed Service ID Length:", formData.service_id.trim().length);
    //   console.log("service ID========>", formData.service_id)
    //   errors.service_id = "ID  is Required.";
    //   isValid = false;
    // }
    if (formData?.service_name.trim() === "") {
      errors.service_name = "Service Name is Required.";
      isValid = false;
    }
    if (formData?.rate.trim() === "") {
      errors.rate = "Rate is Required.";
      isValid = false;
    }
    if (formData?.units.trim() === "") {
      errors.units = "Units is Required.";
      isValid = false;
    }

    setServicePageErrors(errors);
    return isValid;
  };


  const fetchData = () => {
    dispatch(ServiceDetailsGet(token?._id) as any).then((res: any) => {
      if (res) {
        if (res.payload.length > 0) {
          const [, num] = res.payload[0].service_id.split("-");
          let numeric_part = Number(num);
          let next_numeric_part = numeric_part + 1;
          const paddedIndex = String(next_numeric_part).padStart(4, "0");
          setServiceId(`serv-${paddedIndex}`);
        } else {
          const paddedIndex = String(1).padStart(4, "0");
          setServiceId(`serv-${paddedIndex}`);
        }
        setServiceList(res.payload);
      }
    });
  };
  const fetchDataForUnits = () => {
    dispatch(serviceUnitsAll(token?._id) as any).then((res: any) => {
      if (res) {
        setServiceUnits(res.payload);
      }
    });
  }
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [servieNew_id, setServiceNewId] = useState(null as any);
  const handleConfirm = () => {

    dispatch(ServicesDeactive(servieNew_id, formData) as any).then((res: any) => {
      if (res) {
        fetchData();
        setServiceNewId(null);
        setFormData({
          service_id: "",
          service_name: "",
          rate: "",
          units: "",
          qty: "",
          description: "",
          status: "",
          user_id: token?._id,
        } as any);
        setShowConfirmation(false);
      }
    });
  }

  const handleDeactiveSection = (data: any) => {

    setShowConfirmation(true);
    setServiceNewId(data?._id?.$oid);
    setFormData({
      service_id: data.service_id,
      service_name: data.service_name,
      rate: data.rate,
      units: data.units,
      qty: data.qty,
      description: data.description,
      status: data.status,
      user_id: token?._id,
    } as any);
  }

  const handleEditSection = (data: any) => {
    setFormData({
      service_id: data.service_id,
      service_name: data.service_name,
      rate: data.rate,
      units: data.units,
      qty: data.qty,
      description: data.description,
      status: data.status,
      user_id: token?._id,
    });

    setServid(data?._id?.$oid)
    setUnitEdit(data.units)

  }
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const validationErrors: Partial<any> = {};

    const Duplicate = serviceList.some((service: any) => {
      return (
        service.service_name.trim().toLowerCase() ===
        formData.service_name.trim().toLowerCase() &&
        service._id.$oid !== serv_id
      );
    });

    if (Duplicate) {
      validationErrors.product_name = "Service Name already exists.";
    }


    if (validateProdForm()) {
      if (serv_id) {
        dispatch(serviceUpdate(serv_id, formData) as any).then((res: any) => {
          if (res) {
            toast.success("Update SuccessFully !", {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 800,
            });
            fetchData()
            resetData();
            setUnitEdit("")
            setSelectedUnit("")
          }
        });
      } else {
        dispatch(ServiceDetails(formData) as any).then((res: any) => {
          if (res) {
            toast.success("Saved SuccessFully !", {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 800,
            });
            fetchData()
            resetData();
            setUnitEdit("")
            setSelectedUnit("")
          }
        });

      }

    }

  };
  useEffect(() => {
    fetchData()
    fetchDataForUnits()
  }, [])

  const resetData = () => {
    setServicePageErrors({
      ...servicePageErrors,
      service_id: "",
      service_name: "",
      rate: "",
      units: "",
    })
    setFormData({
      service_id: "",
      service_name: "",
      rate: "",
      units: "",
      description: "",
      status: true,
      user_id: token?._id,
    })
    setUnitEdit("")
    setSelectedUnit("")

  }

  const handleCancel = () => {
    setShowConfirmation(false);
  };


  return (
    <div>
      <>
        <fieldset className="mx-3 mb-3 rounded-lg">
          <legend className="p-4  font-bold text-[#045545]  text-lg    ml-2">
            Service Details
          </legend>

          <form>
            <div className="rounded-xl bg-[#E1E8E7]">
              <div className="flex w-full flex-col sm:flex-row px-4">
                <div className=" sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2  ">
                  <div className="flex flex-col mb-6 ">
                    <label
                      style={{ fontFamily: "poppins" }}
                      className=" text-[#008065]  text-sm font-semibold required"
                    >
                      <span>Service ID</span>
                    </label>

                    <div className="relative">
                      <input
                        type='text'
                        style={{
                          fontFamily: "poppins",
                          borderColor: servicePageErrors.service_id
                            ? "red"
                            : "#008065",
                        }}
                        autoComplete="nope"
                        className={
                          "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-48 sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full"
                        }
                        placeholder="Service ID"
                        name="service_id"

                        value={
                          formData.service_id ? formData.service_id : serviceId
                        }

                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    {servicePageErrors.service_id && (
                      <span
                        style={{
                          fontFamily: "poppins",
                          fontWeight: 700,
                          color: "#ff0000",
                        }}
                        className="text-red-400 text-xs"
                      >
                        {servicePageErrors.service_id}
                      </span>
                    )}
                  </div>
                </div>

                <div className=" sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                  <div className="flex flex-col mb-6 ">
                    <label
                      style={{ fontFamily: "poppins" }}
                      className=" text-[#008065]  text-sm font-semibold required"
                    >
                      <span>Service Name</span>
                    </label>
                    <div className="relative">
                      <input
                        style={{
                          fontFamily: "poppins",
                          borderColor: servicePageErrors.service_name
                            ? "red"
                            : "#008065",
                        }}
                        autoComplete="off"
                        className={`border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-48 sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full `}
                        placeholder="Service Name"
                        name="service_name"
                        onChange={(e) => handleChange(e)}
                        value={formData.service_name}

                      />
                    </div>
                    {servicePageErrors.service_name && (
                      <span
                        style={{
                          fontFamily: "poppins",
                          fontWeight: 700,
                          color: "#ff0000",
                        }}
                        className="text-red-400 text-xs"
                      >
                        {servicePageErrors.service_name}
                      </span>
                    )}
                  </div>
                </div>

                <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                  <div className="flex flex-col mb-4 ">
                    <label
                      style={{ fontFamily: "poppins" }}
                      className=" text-[#008065]  text-sm font-semibold required"
                    >
                      <span>Rate</span>
                    </label>

                    <div className="relative">
                      <input
                        style={{
                          fontFamily: "poppins",
                          borderColor: servicePageErrors.rate
                            ? "red"
                            : "#008065",
                        }}
                        autoComplete="off"
                        type="text"
                        className={
                          "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-48 sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-full"
                        }
                        placeholder="Rate"
                        name="rate"
                        // onChange={(e) => handleChange(e)}
                        onChange={(e) => {
                          const { value } = e.target;
                          const regex = /^\d*\.?\d*$/;
                          if (value === "" || regex.test(value)) {
                            handleChange(e);
                            setFormData({ ...formData, rate: value });
                          }
                        }}
                        value={formData.rate}
                      />
                    </div>
                    {servicePageErrors.rate && (
                      <span
                        style={{
                          fontFamily: "poppins",
                          fontWeight: 700,
                          color: "#ff0000",
                        }}
                        className="text-red-400 text-xs"
                      >
                        {servicePageErrors.rate}
                      </span>
                    )}
                  </div>
                </div>

                <div className=" sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                  <div className="flex flex-col mb-6 ">
                    <label
                      style={{ fontFamily: "poppins" }}
                      className=" text-[#008065]  text-sm font-semibold required"
                    >
                      <span>Units</span>
                    </label>
                    <div className="relative">
                      <select
                        value={selectedUnit ? selectedUnit : unitEdit}
                        onChange={handleDropdownChange}
                        style={{
                          fontFamily: "poppins",
                          borderColor: servicePageErrors.units
                            ? "red"
                            : "#008065",
                        }}

                        className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-48 sm:w-full md:w-full lg:w-44 xl:w-56 2xl:w-56"
                        name="units"
                      >
                        <option value="" disabled selected hidden>
                          Select Unit
                        </option>
                        <option value="None">None</option>
                        {serviceUnits?.map((unit: any, index: number) => (
                          <option key={index} value={unit.service_units}>
                            {unit.service_units}
                          </option>
                        ))}
                      </select>
                    </div>
                    {servicePageErrors.units && (
                      <span
                        style={{
                          fontFamily: "poppins",
                          fontWeight: 700,
                          color: "#ff0000",
                        }}
                        className="text-red-400 text-xs"
                      >
                        {servicePageErrors.units}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>


            <div className="flex w-full flex-col sm:flex-row pl-4 mb-2">
              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                <div className="flex flex-col mb-4 w-[78%]">
                  <label
                    style={{ fontFamily: "poppins" }}
                    className=" text-[#008065]  text-sm font-semibold "
                  >
                    <span>Description</span>
                  </label>
                  <div className="relative">
                    <textarea
                      style={{ fontFamily: "poppins" }}
                      autoComplete="off"
                      className={`border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-[100px] w-48 sm:w-full`}
                      placeholder="Description (Optional)"
                      name="description"
                      onChange={(e) => handleChange(e)}
                      value={formData.description}
                    />
                  </div>
                </div>
              </div>
              <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2  emptydiv">
                <div className="flex flex-col mb-4 "></div>
              </div>
            </div>
            <div
              className="sm:flex pl-[10px] sm:pr-4"
              style={{ justifyContent: "end", marginBottom: "1rem" }}
            >
              <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-2 px-2 mt-5">
                <div className="flex flex-col w-48 2xl:w-40 xl:w-40 lg:w-40 md:w-40 sm:w-40 goods rounded">
                  <button
                    type="button"
                    className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065] "
                    onClick={resetData}
                    style={{ fontFamily: "poppins", fontSize: "14px" }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <div className="mb-2 sm:mb-0 sm:text-left text-default-color font-title px-2  mt-5">
                <div className="flex flex-col  w-48 2xl:w-40 xl:w-40 lg:w-40 md:w-40 sm:w-40  goods  rounded">
                  <button
                    type="submit"
                    className="rounded-xl font-bold text-white items-center bg-[#008065]   text-sm h-8  w-full "
                    style={{ fontFamily: "poppins", fontSize: "14px" }}
                    onClick={(e: any) => handleSubmit(e)}
                  >
                    {/* {service_id ? "Update" : "Submit"} */}
                    {formData.service_id ? "Update" : "Submit"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </fieldset>
        <ServiceTable serviceList={serviceList} handleEditSection={handleEditSection} handleDeactiveSection={handleDeactiveSection} />
      </>
      {showConfirmation && (
        <ConfirmationDeactivePopup
          message="Are you sure you want to proceed?"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
}

export default ServiceSettings;
