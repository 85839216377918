import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { toast } from 'react-toastify';
import { registerAll, updateUser } from '../../redux/actions/userAction';




const GstSettings = () => {
    const dispatch = useDispatch();
    const [selectedTaxType, setSelectedTaxType] = useState("GST")


    const handleTaxTypeChange = (event: any) => {
        setSelectedTaxType(event.target.value)
    }


    const User_id = sessionStorage.getItem("userInfo");
    const userId = JSON.parse(User_id as any);


    const fetchData = () => {
        dispatch(registerAll(userId?.token?._id) as any).then((res: any) => {
            if (res && res.type === "REGISTER_GET_SUCCESS") {
                const gstvalues = res?.payload?.gstValue ? res?.payload?.gstValue : "GST";
                setSelectedTaxType(gstvalues);
                //     setInitialChecked(hsnNo);
            }
        });
    };


    const handleSubmit = (e: any) => {
        e.preventDefault();
        let obj = {
            gstValue: selectedTaxType,
        }

        dispatch(updateUser(userId?.token?._id, obj) as any).then((res: any) => {
            if (res) {
                fetchData();
                toast.success("Saved Successfully!", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    autoClose: 800,
                });
            }
        });
    };
    useEffect(() => {
        if (userId?.token?._id) {
            fetchData();
        }
    }, [])
    const handleCancel = () => {
        setSelectedTaxType("")
    };

    return (
        <div>
            <form>
                <fieldset className="mx-2 mb-4 rounded-lg p-3">
                    <legend className="p-4  font-bold text-[#045545]  text-lg   ml-2">
                        GST Settings
                    </legend>
                    <div className="flex  gap-2 mb-3">
                        <div>
                        </div>
                        <div className="text-[#008065] font-semibold">
                        </div>
                    </div>

                    <div className="grid lg:grid-cols-3 xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-1">
                        <div>
                            <label
                                htmlFor="tax-type-select"
                                className="block mb-2 text-sm text-[#008065] font-semibold"
                            >
                                Select Tax Type
                            </label>
                            <select
                                id="tax-type-select"
                                value={selectedTaxType}
                                onChange={handleTaxTypeChange}
                                className="block w-full p-2 bg-white border-2 rounded-md shadow-sm focus:outline-none sm:text-sm border-[#008065]"
                            >
                                <option value="" disabled hidden>
                                    Please select
                                </option>
                                <option value="GST">GST</option>
                                <option value="IGST">IGST</option>
                            </select>
                            {/* {error && (
                        <p style={{ color: "red", fontSize: '13px', marginLeft: '3px' }}>
                            Tax Type is required
                        </p>
                    )} */}
                        </div>
                    </div>
                    <div className="flex w-full flex-col sm:flex-row px-4">
                        <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2  ">
                            <div className="flex flex-col mb-6 "></div>
                        </div>

                        <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                            <div className="flex flex-col  ">
                                <div className="relative"></div>
                            </div>
                        </div>

                        <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-2 px-2 mt-5">
                            <div className="flex flex-col w-48 sm:w-40 md:w-40 lg:w-40 xl:w-40 2xl:w-40  rounded">
                                <button
                                    type="button"
                                    className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065] "
                                    style={{
                                        backgroundColor: "#008065",
                                        fontSize: "14px",
                                        fontFamily: "poppins",
                                    }}
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                        <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2 mt-5 ">
                            <div className="flex flex-col w-48 sm:w-40 md:w-40 lg:w-40 xl:w-40 2xl:w-40 ">
                                <button
                                    type="submit"
                                    className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065] "
                                    style={{
                                        backgroundColor: "#008065",
                                        fontSize: "14px",
                                        fontFamily: "poppins",
                                    }}
                                    onClick={(e: any) => handleSubmit(e)}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    )
}

export default GstSettings
