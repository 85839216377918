import React from 'react'

const ClientsSvg = () => {
    return (
        <div>
            <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="#a855f7"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g opacity="0.2">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.81564 11.3119C5.24306 12.2836 4.93753 13.4253 4.93754 14.5931V15.4254C4.93754 15.742 4.82634 16.0458 4.62842 16.2697C4.43049 16.4936 4.16204 16.6194 3.88213 16.6194C3.60222 16.6194 3.33377 16.4936 3.13584 16.2697C2.93792 16.0458 2.82672 15.742 2.82672 15.4254V14.5931C2.82672 12.9537 3.25522 11.3513 4.05944 9.98656L5.81564 11.3119Z"
                        fill=""
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.89481 10.0522C7.05892 10.0522 6.27897 10.5251 5.81565 11.3119L4.05945 9.98656C4.48047 9.27231 5.0508 8.68664 5.71984 8.28152C6.38888 7.8764 7.13596 7.66435 7.89481 7.66418H8.10378C8.38369 7.66418 8.65214 7.78998 8.85006 8.0139C9.04799 8.23782 9.15918 8.54153 9.15918 8.85821C9.15918 9.17488 9.04799 9.47859 8.85006 9.70251C8.65214 9.92644 8.38369 10.0522 8.10378 10.0522H7.89481ZM10.2294 11.3119C10.8014 12.2827 11.1075 13.4254 11.1075 14.5931V15.4254C11.1075 15.742 11.2187 16.0458 11.4166 16.2697C11.6145 16.4936 11.883 16.6194 12.1629 16.6194C12.4428 16.6194 12.7112 16.4936 12.9092 16.2697C13.1071 16.0458 13.2183 15.742 13.2183 15.4254V14.5931C13.2183 12.9536 12.7894 11.3508 11.9856 9.98656L10.2294 11.3119Z"
                        fill=""
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.14915 10.0522C8.98504 10.0522 9.76498 10.5251 10.2283 11.3119L11.9856 9.98656C11.5645 9.27206 10.994 8.68624 10.3248 8.2811C9.65552 7.87596 8.90819 7.66405 8.14915 7.66418H7.94124C7.66133 7.66418 7.39288 7.78998 7.19495 8.0139C6.99702 8.23782 6.88583 8.54153 6.88583 8.85821C6.88583 9.17488 6.99702 9.47859 7.19495 9.70251C7.39288 9.92644 7.66133 10.0522 7.94124 10.0522H8.14915ZM3.45575 16.0224C3.45575 15.864 3.51135 15.7122 3.61031 15.6002C3.70927 15.4883 3.8435 15.4254 3.98345 15.4254H11.3713C11.5113 15.4254 11.6455 15.4883 11.7445 15.6002C11.8434 15.7122 11.899 15.864 11.899 16.0224C11.899 16.1807 11.8434 16.3326 11.7445 16.4445C11.6455 16.5565 11.5113 16.6194 11.3713 16.6194H3.98345C3.8435 16.6194 3.70927 16.5565 3.61031 16.4445C3.51135 16.3326 3.45575 16.1807 3.45575 16.0224Z"
                        fill=""
                    />
                    <path
                        d="M11.3713 5.27612C11.3713 6.30532 11.0099 7.29236 10.3667 8.02012C9.72339 8.74787 8.85093 9.15672 7.94122 9.15672C7.0315 9.15672 6.15905 8.74787 5.51579 8.02012C4.87252 7.29236 4.51114 6.30532 4.51114 5.27612C4.51114 4.24692 4.87252 3.25988 5.51579 2.53212C6.15905 1.80437 7.0315 1.39552 7.94122 1.39552C8.85093 1.39552 9.72339 1.80437 10.3667 2.53212C11.0099 3.25988 11.3713 4.24692 11.3713 5.27612Z"
                        fill=""
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.94122 6.76866C8.29111 6.76866 8.62667 6.61141 8.87408 6.3315C9.12149 6.0516 9.26048 5.67197 9.26048 5.27612C9.26048 4.88027 9.12149 4.50064 8.87408 4.22074C8.62667 3.94083 8.29111 3.78358 7.94122 3.78358C7.59133 3.78358 7.25577 3.94083 7.00836 4.22074C6.76095 4.50064 6.62196 4.88027 6.62196 5.27612C6.62196 5.67197 6.76095 6.0516 7.00836 6.3315C7.25577 6.61141 7.59133 6.76866 7.94122 6.76866ZM7.94122 9.15672C8.85093 9.15672 9.72339 8.74787 10.3667 8.02012C11.0099 7.29236 11.3713 6.30532 11.3713 5.27612C11.3713 4.24692 11.0099 3.25988 10.3667 2.53212C9.72339 1.80437 8.85093 1.39552 7.94122 1.39552C7.0315 1.39552 6.15905 1.80437 5.51579 2.53212C4.87252 3.25988 4.51114 4.24692 4.51114 5.27612C4.51114 6.30532 4.87252 7.29236 5.51579 8.02012C6.15905 8.74787 7.0315 9.15672 7.94122 9.15672Z"
                        fill=""
                    />
                    <path
                        d="M4.51114 10.0522H11.3713V16.0224H4.51114V10.0522Z"
                        fill=""
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.4674 15.1925C12.8948 16.1642 12.5893 17.3059 12.5893 18.4737V19.306C12.5893 19.6226 12.4781 19.9264 12.2802 20.1503C12.0822 20.3742 11.8138 20.5 11.5339 20.5C11.254 20.5 10.9855 20.3742 10.7876 20.1503C10.5896 19.9264 10.4785 19.6226 10.4785 19.306V18.4737C10.4785 16.8343 10.9069 15.2319 11.7112 13.8672L13.4674 15.1925ZM15.5465 13.9328C14.7117 13.9328 13.9307 14.4057 13.4674 15.1925L11.7112 13.8672C12.1322 13.1529 12.7025 12.5672 13.3716 12.1621C14.0406 11.757 14.7877 11.545 15.5465 11.5448H15.7555C16.0354 11.5448 16.3039 11.6706 16.5018 11.8945C16.6997 12.1184 16.8109 12.4221 16.8109 12.7388C16.8109 13.0555 16.6997 13.3592 16.5018 13.5831C16.3039 13.807 16.0354 13.9328 15.7555 13.9328H15.5465ZM17.8811 15.1925C18.4531 16.1633 18.7592 17.306 18.7592 18.4737V19.306C18.7592 19.6226 18.8704 19.9264 19.0683 20.1503C19.2662 20.3742 19.5347 20.5 19.8146 20.5C20.0945 20.5 20.363 20.3742 20.5609 20.1503C20.7588 19.9264 20.87 19.6226 20.87 19.306V18.4737C20.8701 16.8342 20.4411 15.2314 19.6373 13.8672L17.8811 15.1925Z"
                        fill=""
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15.8009 13.9328C16.6367 13.9328 17.4177 14.4057 17.88 15.1925L19.6373 13.8672C19.2161 13.1527 18.6457 12.567 17.9764 12.1618C17.3072 11.7567 16.5599 11.5448 15.8009 11.5448H15.5929C15.313 11.5448 15.0446 11.6706 14.8467 11.8945C14.6487 12.1184 14.5375 12.4221 14.5375 12.7388C14.5375 13.0555 14.6487 13.3592 14.8467 13.5831C15.0446 13.807 15.313 13.9328 15.5929 13.9328H15.8009ZM10.8436 19.903C10.8436 19.7446 10.8992 19.5928 10.9982 19.4808C11.0971 19.3689 11.2313 19.306 11.3713 19.306H19.8146C19.9545 19.306 20.0888 19.3689 20.1877 19.4808C20.2867 19.5928 20.3423 19.7446 20.3423 19.903C20.3423 20.0613 20.2867 20.2132 20.1877 20.3251C20.0888 20.4371 19.9545 20.5 19.8146 20.5H11.3713C11.2313 20.5 11.0971 20.4371 10.9982 20.3251C10.8992 20.2132 10.8436 20.0613 10.8436 19.903Z"
                        fill=""
                    />
                    <path
                        d="M19.023 9.15672C19.023 10.1859 18.6616 11.173 18.0184 11.9007C17.3751 12.6285 16.5027 13.0373 15.593 13.0373C14.6832 13.0373 13.8108 12.6285 13.1675 11.9007C12.5243 11.173 12.1629 10.1859 12.1629 9.15672C12.1629 8.12752 12.5243 7.14048 13.1675 6.41272C13.8108 5.68497 14.6832 5.27612 15.593 5.27612C16.5027 5.27612 17.3751 5.68497 18.0184 6.41272C18.6616 7.14048 19.023 8.12752 19.023 9.15672Z"
                        fill=""
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15.593 10.6493C15.9428 10.6493 16.2784 10.492 16.5258 10.2121C16.7732 9.9322 16.9122 9.55257 16.9122 9.15672C16.9122 8.76087 16.7732 8.38124 16.5258 8.10134C16.2784 7.82143 15.9428 7.66418 15.593 7.66418C15.2431 7.66418 14.9075 7.82143 14.6601 8.10134C14.4127 8.38124 14.2737 8.76087 14.2737 9.15672C14.2737 9.55257 14.4127 9.9322 14.6601 10.2121C14.9075 10.492 15.2431 10.6493 15.593 10.6493ZM15.593 13.0373C16.5027 13.0373 17.3751 12.6285 18.0184 11.9007C18.6616 11.173 19.023 10.1859 19.023 9.15672C19.023 8.12752 18.6616 7.14048 18.0184 6.41272C17.3751 5.68497 16.5027 5.27612 15.593 5.27612C14.6832 5.27612 13.8108 5.68497 13.1675 6.41272C12.5243 7.14048 12.1629 8.12752 12.1629 9.15672C12.1629 10.1859 12.5243 11.173 13.1675 11.9007C13.8108 12.6285 14.6832 13.0373 15.593 13.0373Z"
                        fill=""
                    />
                    <path
                        d="M11.899 13.6343H18.7592V19.6045H11.899V13.6343Z"
                        fill=""
                    />
                </g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.89955 10.3233C2.2643 11.4019 1.92536 12.669 1.9254 13.9651V15.1269C1.9254 15.2852 1.86981 15.4371 1.77084 15.549C1.67188 15.661 1.53766 15.7239 1.3977 15.7239C1.25774 15.7239 1.12352 15.661 1.02456 15.549C0.925592 15.4371 0.869995 15.2852 0.869995 15.1269V13.9663C0.870316 12.4337 1.27171 10.9355 2.02356 9.6606L2.13754 9.46716C2.176 9.40193 2.22544 9.34591 2.28304 9.3023C2.34064 9.25869 2.40527 9.22833 2.47323 9.21298C2.5412 9.19762 2.61117 9.19756 2.67916 9.2128C2.74714 9.22804 2.81181 9.25828 2.86947 9.30179C2.92712 9.3453 2.97664 9.40124 3.01519 9.4664C3.05374 9.53156 3.08057 9.60468 3.09414 9.68157C3.10772 9.75846 3.10777 9.83763 3.0943 9.91454C3.08083 9.99146 3.0541 10.0646 3.01564 10.1298L2.89955 10.3233Z"
                    fill=""
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.87579 8.39851C4.72539 8.39851 3.65204 9.04806 3.01458 10.1298L2.13647 9.46836C2.54678 8.7721 3.10265 8.20118 3.75476 7.80626C4.40688 7.41134 5.13507 7.20464 5.87473 7.20448H6.10692C6.24688 7.20448 6.3811 7.26738 6.48007 7.37934C6.57903 7.4913 6.63463 7.64315 6.63463 7.80149C6.63463 7.95983 6.57903 8.11168 6.48007 8.22364C6.3811 8.33561 6.24688 8.39851 6.10692 8.39851H5.87579ZM9.12434 10.309C9.62038 11.1519 9.88529 12.1406 9.88529 13.1531H10.9407C10.9409 11.9054 10.6144 10.6855 10.0024 9.64746L9.8969 9.46836C9.85951 9.4006 9.81048 9.34203 9.75272 9.29609C9.69496 9.25016 9.62964 9.21781 9.56063 9.20096C9.49161 9.1841 9.42031 9.18309 9.35095 9.19797C9.28158 9.21285 9.21556 9.24333 9.1568 9.2876C9.09803 9.33187 9.04771 9.38903 9.00883 9.4557C8.96994 9.52236 8.94327 9.59718 8.9304 9.67572C8.91754 9.75425 8.91873 9.83491 8.93391 9.91292C8.9491 9.99093 8.97796 10.0647 9.0188 10.1298L9.12434 10.309Z"
                    fill=""
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.15864 8.39851C7.30798 8.39851 8.38133 9.04806 9.0188 10.1298L9.8969 9.46836C9.48659 8.7721 8.93072 8.20118 8.27861 7.80626C7.62649 7.41134 6.8983 7.20464 6.15864 7.20448H5.92645C5.85715 7.20448 5.78853 7.21992 5.7245 7.24992C5.66048 7.27992 5.60231 7.3239 5.5533 7.37934C5.5043 7.43478 5.46543 7.50059 5.43891 7.57302C5.41239 7.64546 5.39874 7.72309 5.39874 7.80149C5.39874 7.87989 5.41239 7.95753 5.43891 8.02996C5.46543 8.10239 5.5043 8.16821 5.5533 8.22364C5.60231 8.27908 5.66048 8.32306 5.7245 8.35306C5.78853 8.38306 5.85715 8.39851 5.92645 8.39851H6.15864Z"
                    fill=""
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.94967 7.06716C6.26152 7.06716 6.57031 6.99767 6.85842 6.86266C7.14653 6.72765 7.40831 6.52976 7.62882 6.28029C7.84933 6.03082 8.02424 5.73465 8.14358 5.4087C8.26292 5.08275 8.32434 4.7334 8.32434 4.3806C8.32434 4.02779 8.26292 3.67844 8.14358 3.35249C8.02424 3.02654 7.84933 2.73038 7.62882 2.48091C7.40831 2.23144 7.14653 2.03355 6.85842 1.89853C6.57031 1.76352 6.26152 1.69403 5.94967 1.69403C5.31987 1.69403 4.71586 1.97708 4.27053 2.48091C3.82519 2.98474 3.575 3.66808 3.575 4.3806C3.575 5.09312 3.82519 5.77646 4.27053 6.28029C4.71586 6.78412 5.31987 7.06716 5.94967 7.06716ZM5.94967 8.26119C6.85939 8.26119 7.73184 7.85235 8.3751 7.12459C9.01837 6.39684 9.37975 5.4098 9.37975 4.3806C9.37975 3.3514 9.01837 2.36435 8.3751 1.6366C7.73184 0.908847 6.85939 0.5 5.94967 0.5C5.03996 0.5 4.1675 0.908847 3.52424 1.6366C2.88097 2.36435 2.51959 3.3514 2.51959 4.3806C2.51959 5.4098 2.88097 6.39684 3.52424 7.12459C4.1675 7.85235 5.03996 8.26119 5.94967 8.26119ZM10.4447 14.1549C9.80884 15.2337 9.46952 16.5013 9.46946 17.7979V19.0325C9.46946 19.1909 9.41386 19.3427 9.3149 19.4547C9.21594 19.5667 9.08171 19.6296 8.94176 19.6296C8.8018 19.6296 8.66758 19.5667 8.56861 19.4547C8.46965 19.3427 8.41405 19.1909 8.41405 19.0325V17.7979C8.41396 16.266 8.8146 14.7682 9.5655 13.4934L9.70587 13.2546C9.78369 13.1229 9.90457 13.0315 10.0419 13.0006C10.1793 12.9697 10.3219 13.0018 10.4383 13.0899C10.5548 13.1779 10.6355 13.3147 10.6628 13.4701C10.6902 13.6255 10.6618 13.7868 10.584 13.9185L10.4447 14.1549Z"
                    fill=""
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.517 12.1418C12.9364 12.1417 12.3648 12.3038 11.8529 12.6138C11.3411 12.9237 10.9048 13.3719 10.5829 13.9185L9.70587 13.2558C10.1241 12.5459 10.6908 11.9638 11.3556 11.5612C12.0204 11.1585 12.7629 10.9478 13.517 10.9478H13.7534C13.8933 10.9478 14.0275 11.0107 14.1265 11.1226C14.2255 11.2346 14.2811 11.3864 14.2811 11.5448C14.2811 11.7031 14.2255 11.855 14.1265 11.9669C14.0275 12.0789 13.8933 12.1418 13.7534 12.1418H13.517ZM16.8805 14.1549C17.5164 15.2337 17.8557 16.5013 17.8557 17.7979V19.0325C17.8557 19.1909 17.9113 19.3427 18.0103 19.4547C18.1093 19.5667 18.2435 19.6296 18.3834 19.6296C18.5234 19.6296 18.6576 19.5667 18.7566 19.4547C18.8556 19.3427 18.9111 19.1909 18.9111 19.0325V17.7979C18.9109 16.2658 18.5099 14.7681 17.7586 13.4934L17.6183 13.2546C17.5405 13.1229 17.4196 13.0315 17.2822 13.0006C17.1448 12.9697 17.0023 13.0018 16.8858 13.0899C16.7694 13.1779 16.6886 13.3146 16.6613 13.4701C16.634 13.6255 16.6624 13.7868 16.7402 13.9185L16.8805 14.1549Z"
                    fill=""
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.8072 12.1418C14.3878 12.1417 14.9594 12.3038 15.4712 12.6138C15.9831 12.9237 16.4193 13.3719 16.7412 13.9185L17.6193 13.2558C17.201 12.5457 16.6342 11.9635 15.9691 11.5609C15.3041 11.1582 14.5615 10.9476 13.8072 10.9478H13.5697C13.4298 10.9478 13.2955 11.0107 13.1966 11.1226C13.0976 11.2346 13.042 11.3864 13.042 11.5448C13.042 11.7031 13.0976 11.855 13.1966 11.9669C13.2955 12.0789 13.4298 12.1418 13.5697 12.1418H13.8072Z"
                    fill=""
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.6014 10.9478C13.9133 10.9478 14.222 10.8783 14.5102 10.7433C14.7983 10.6082 15.06 10.4104 15.2806 10.1609C15.5011 9.91142 15.676 9.61525 15.7953 9.2893C15.9147 8.96335 15.9761 8.614 15.9761 8.2612C15.9761 7.90839 15.9147 7.55904 15.7953 7.23309C15.676 6.90714 15.5011 6.61098 15.2806 6.36151C15.06 6.11204 14.7983 5.91415 14.5102 5.77913C14.222 5.64412 13.9133 5.57463 13.6014 5.57463C12.9716 5.57463 12.3676 5.85768 11.9223 6.36151C11.4769 6.86534 11.2267 7.54868 11.2267 8.2612C11.2267 8.97372 11.4769 9.65706 11.9223 10.1609C12.3676 10.6647 12.9716 10.9478 13.6014 10.9478ZM13.6014 12.1418C14.5111 12.1418 15.3836 11.7329 16.0268 11.0052C16.6701 10.2774 17.0315 9.2904 17.0315 8.2612C17.0315 7.232 16.6701 6.24495 16.0268 5.5172C15.3836 4.78945 14.5111 4.3806 13.6014 4.3806C12.6917 4.3806 11.8192 4.78945 11.176 5.5172C10.5327 6.24495 10.1713 7.232 10.1713 8.2612C10.1713 9.2904 10.5327 10.2774 11.176 11.0052C11.8192 11.7329 12.6917 12.1418 13.6014 12.1418Z"
                    fill=""
                />
            </svg>
        </div>
    )
}

export default ClientsSvg
